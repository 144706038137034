import React, { memo } from "react";
import usePreAlertRequest from "../../../../../../sources/hooks/requests/usePreAlertRequest";
const UserFloatButtons = memo(({ url = "/logout", type = 'logout', message = 'You are not Logged in Out!' }) => {
  const data = { apiKey: process.env.REACT_APP_LOGOUT_APIKEY }
  const { handlePreAlertRequest } = usePreAlertRequest();
  return (
    <>
      <button
        type="button"
        className=" mr-4 mt-4 bg-cyan-600/70 hover:bg-cyan-600 px-3 py-2 rounded-md text-white">
        Download
      </button>
      <button
        type="button"
        className=" mr-4 mt-4 bg-cyan-600/70 hover:bg-cyan-600 px-3 py-2 rounded-md text-red-600 font-bold">
        Deactivate
      </button>
      <button
        type="button"
        onClick={(e) => handlePreAlertRequest(e, url, data, type, message,)}
        className=" mr-4 mt-4 bg-cyan-600/70 hover:bg-cyan-600 px-3 py-2 rounded-md text-white">
        Logout
      </button>
    </>
  );
});

export default UserFloatButtons;
