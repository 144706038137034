import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import resources from "../../../../sources/data/resources";
import usePreAlertRequest from "../../../../sources/hooks/requests/usePreAlertRequest";
const ProfileMenuBar = memo(({ url = "/logout", type = 'logout', message = 'You are not Logged in Out!' }) => {
  const { profileMenus } = resources();
  const data = { apiKey: process.env.REACT_APP_LOGOUT_APIKEY }
  const { handlePreAlertRequest } = usePreAlertRequest();
  return (
    <>
      <div className="border grid grid-flow-col justify-around px-1 text-xs md:text-sm md:justify-center md:gap-6 lg:text-base lg:gap-10 items-center bg-profilebar py-3 text-white">
        {profileMenus.map((menu, index) => (
          <NavLink
            key={index}
            to={menu.url}
            className={({ isActive }) =>
              isActive
                ? "font-bold hover:text-white/50 duration-300"
                : "hover:text-white/50 duration-300"
            }>
            <div>{menu.name}</div>
          </NavLink>
        ))}
        <button type="button" onClick={(e) => handlePreAlertRequest(e, url, data, type, message)}>
          <span className="hover:text-white/50 duration-300">Logout</span>
        </button>
      </div>
    </>
  );
});

export default ProfileMenuBar;
