import React, { useEffect } from "react";
import { BiDotsHorizontal } from "react-icons/bi";
import { useSelector } from "react-redux";
import useGetRequests from "../../../../../sources/hooks/requests/useGetRequests";
import usePostRequests from "../../../../../sources/hooks/requests/usePostRequests";
import EmailPasswordUpdateSkeleton from "../../../../common/skeleton/EmailPasswordUpdateSkeleton";
import useHandleFormData from "../../../../../sources/functions/useHandleFormData";

export default function Email({ url = "/user", type = "user", postUrl = "/user/email/update", postType = "emailUpdate" }) {
  const { updateEmail, errors } = useSelector((store) => store.data);

  const { handleGetRequest, user, message, loading } = useGetRequests();
  const { handleInputFieldData } = useHandleFormData();
  const postData = {
    new_email: updateEmail.new_email,
    verify_email: updateEmail.verify_email,
    id: user.id,
    apiKey: process.env.REACT_APP_COMMON_APIKEY,
  };
  const { handlePostRequest, update } = usePostRequests();
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_COMMON_APIKEY,
    };
    handleGetRequest(url, data, type);
  }, [handleGetRequest, type, update, url]);

  return (
    <>
      <div className="col-span-12 md:col-span-9 md:py-2 lg:mx-10">
        <div className="border-x-2 bg-white shadow-lg mb-4">
          <div className="border-y-2 py-1 ">
            <div className="flex justify-between items-center mx-4">
              <span className="text-lg py-2">Email</span>
              <BiDotsHorizontal className=" cursor-pointer" />
            </div>
          </div>
          {loading ? (
            <EmailPasswordUpdateSkeleton />
          ) : (
            <>
              {message ? (
                <div className="h-[50vh] w-full flex justify-center items-center">
                  {message}
                </div>
              ) : (
                <form onSubmit={(e) => handlePostRequest(e, postUrl, postData, postType)} className="text-sm">
                  <div className=" flex flex-col gap-2 m-12">
                    <div className="p-2 flex flex-col lg:grid lg:grid-cols-12 ">
                      <div className="col-span-2">Current Email:</div>
                      <span className="col-span-10 outline-none bg-gray-100 py-1 pl-2 border border-cyan-300 w-full h-9">
                        {user.email}
                      </span>
                    </div>
                    <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                      <div className="col-span-2">
                        New Email:
                        <span className="text-red-500 font-bold"> *</span>
                      </div>
                      <input
                        type="email"
                        name="new_email"
                        value={updateEmail.new_email}
                        onChange={(e) => handleInputFieldData(e, postType)}
                        placeholder="Enter your new email?"
                        className="col-span-10 outline-none bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                      />
                    </div>
                    {errors && (
                      <div className="lg:grid lg:grid-cols-12 ">
                        <div className="col-span-2"></div>
                        <div className="text-red-700 w-full col-span-10">
                          {errors.new_email}
                        </div>
                      </div>
                    )}
                    <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                      <div className="col-span-2">
                        Verify Email:
                        <span className="text-red-500 font-bold"> *</span>
                      </div>
                      <input
                        type="email"
                        name="verify_email"
                        value={updateEmail.verify_email}
                        onChange={(e) => handleInputFieldData(e, postType)}
                        placeholder="Re-Enter your new email?"
                        className="col-span-10 outline-none bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                      />
                    </div>
                    {errors && (
                      <div className="md:grid md:grid-cols-12">
                        <div className="col-span-2"></div>
                        <div className="text-red-700 w-full col-span-10">
                          {errors.verify_email}
                        </div>
                      </div>
                    )}
                    <div className="col-span-12 text-end mb-4 mx-3">
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-600 text-gray-50 rounded-full px-4 py-1.5 cursor-pointer text-md ">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
