import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  newsShareModal: false,
  shareNewsId: "",
  newsId: "",
  userNewsEditModal: false,
};
const modalSlice = createSlice({
  name: "modal",
  initialState: {
    newsShareModal: initialState.newsShareModal,
    shareNewsId: initialState.shareNewsId,
    newsId: initialState.newsId,
    userNewsEditModal: initialState.userNewsEditModal,
  },
  reducers: {
    storeNewsShareModal(state, action) {
      state.newsShareModal = action.payload;
    },
    storeShareNewsId(state, action) {
      state.shareNewsId = action.payload;
    },
    storeNewsId(state, action) {
      state.newsId = action.payload;
    },
    storeUserNewsEditModal(state, action) {
      state.userNewsEditModal = action.payload;
    },
  },
});

export const { storeNewsShareModal, storeShareNewsId, storeNewsId, storeUserNewsEditModal } = modalSlice.actions;
export default modalSlice.reducer;
