import React, { memo } from "react";
import SkeletonBar from "./SkeletonBar";
import SkeletonImage from "./SkeletonImage";

const NewsCardSkeleton = memo(() => {
  return (
    <>
      <div className="px-2 py-5 bg-gray-50 shadow-md rounded-md">
        <div className="h-11 mb-2 flex justify-between items-center  px-2">
          <SkeletonBar
            parentClass={"h-6 w-[90%] bg-gray-300/60"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={"h-6 w-[5%] bg-gray-300/60"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className="mb-2 px-2 h-5">
          <SkeletonBar
            parentClass={"h-5 w-[20%] bg-gray-300/60 float-right"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className="h-[380px]">
          <SkeletonBar
            parentClass={"h-full w-full bg-gray-300/60"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className="p-2 space-y-2">
          <SkeletonBar
            parentClass={"h-6 w-full bg-gray-300/60"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={"h-6 w-full bg-gray-300/60"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={"h-6 w-full bg-gray-300/60"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className="h-14">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <SkeletonImage className="h-14 w-14 bg-gray-300/60 rounded-full" />
              <SkeletonBar
                parentClass={"h-6 w-60 bg-gray-300/60"}
                childClass={"h-full w-full bg-white/20 animate-slide"}
              />
            </div>
            <SkeletonBar
              parentClass={"h-6 w-40 bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default NewsCardSkeleton;
