import React, { memo } from "react";
import { FaShare } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { ImEye } from "react-icons/im";
import moment from "moment";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import usePreAlertRequest from "../../../sources/hooks/requests/usePreAlertRequest";
const HistoryCard = memo(({ histories, url = "/user/delete/history", type = "userHistoryDelete", message = "Your history is not deleted!" }) => {
  const { handlePreAlertRequest } = usePreAlertRequest()
  return (
    <>
      {histories &&
        histories.map((history, key) => (
          <div
            key={key}
            className="group space-y-2 bg-gray-100 p-3 rounded-md shadow-lg"
          >
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-3 flex items-center justify-center">
                <img
                  src={
                    history.news.image
                      ? history.news.image
                      : "/storage/default/news.png"
                  }
                  alt={history.news.image ? history.news.image : "news.png"}
                  className="h-auto w-full"
                />
              </div>
              <div className="col-span-9 space-y-2">
                <div className="flex justify-between">
                  {history.news.title && (
                    <Link
                      to={history.news.url}
                      target="_blank"
                      className="text-justify"
                    >
                      {history.news.title.substr(0, 55) + " . . . "}
                      <div className="font-semibold text-sm">
                        -- {history.news.author}
                      </div>
                    </Link>
                  )}
                  <div className="col-span-1 hidden group-hover:inline-block group-duration-300">
                    <button
                      type="button"
                      title="Remove history?"
                      onClick={(e) => handlePreAlertRequest(e, url, { news_id: history.id }, type, message)}
                      className="bg-gray-200 p-2 rounded-full hover:bg-gray-400 hover:text-white duration-300"
                    >
                      <FaXmark />
                    </button>
                  </div>
                </div>
                <div className="text-sm font-semibold flex justify-between items-center">
                  <Link to="/profile" className="flex gap-2 items-center">
                    <div className="">
                      <img
                        src={
                          history.user.profile
                            ? process.env.REACT_APP_STORAGE_PATH +
                            "/profile/" +
                            history.user.profile.profile_photo
                            : "/storage/default/profile.png"
                        }
                        alt={
                          history.user.profile
                            ? history.user.profile.profile_photo
                            : "profile.png"
                        }
                        className="h-10 w-10 rounded-full"
                      />
                    </div>
                    <div className="">
                      <div className="">{history.user.name}</div>
                      <div className="">{history.message}</div>
                    </div>
                  </Link>
                  <div className="">
                    <div className="">
                      {moment(history.updated_at).format("Do, MMM YYYY")}
                    </div>
                    <small className="flex items-center gap-1 ">
                      <span>{history.news.views}</span>
                      <ImEye />
                      <span className="font-bold">.</span>
                      <span>{history.news.share}</span>
                      <FaShare />
                    </small>
                  </div>
                </div>
                <Link
                  to={history.news.url}
                  target="_blank"
                  className="description"
                >
                  {history.news.description && (
                    <div
                      className="text-sm font-light font-roboto text-justify "
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          history.news.description.substr(0, 140)
                        ),
                      }}
                    />
                  )}
                  <small className=""> . . .</small>
                </Link>
              </div>
            </div>
          </div>
        ))}
    </>
  );
});

export default HistoryCard;
