import React, { useEffect } from "react";
import Title from "../../../common/title/Title";
import NewsCardSkeleton from "../../../common/skeleton/NewsCardSkeleton";
import NewsCard from "../../../common/postCards/NewsCard";
import useGetRequests from "../../../../sources/hooks/requests/useGetRequests";
import useSocket from "../../../../sources/socket/useSocket";
import NotFoundMessage from "../../../common/errors/NotFoundMessage";
export default function Save({ url = "/user/saves/news", type = "saveNews" }) {
  const { socket, update, setUpdate } = useSocket('updateSaves')
  const { handleGetRequest, loading, message, saveNews, buttonData, setButtonData } = useGetRequests();
  useEffect(() => {
    socket?.on('updateSaves', (data) => {
      setUpdate(data)
    })
  }, [setUpdate, socket]);
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_COMMON_APIKEY,
    };
    handleGetRequest(url, data, type);
    return () => {
      setUpdate(null)
    }
  }, [handleGetRequest, url, type, update, setUpdate]);

  return (
    <>
      <div className="">
        <Title title="Your Saved News" />
        <div className="w-full lg:w-[650px] mx-auto flex flex-col gap-3">
          {loading ? (
            <div className=" w-full 2xl:w-[650px] mx-auto space-y-4 mb-4 mt-2">
              {[1, 2, 3].map((index) => (
                <NewsCardSkeleton key={index} />
              ))}
            </div>
          ) : (
            <>
              {message ? (
                <NotFoundMessage message={message} />
              ) : (
                <NewsCard news={saveNews} buttonData={buttonData} setButtonData={setButtonData} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
