import React, { memo, useEffect, useMemo } from "react";
import Title from "../../common/title/Title";
import NewsCardSkeleton from "../../common/skeleton/NewsCardSkeleton";
import UserNewsCard from "../../common/postCards/UserNewsCard";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import useSocket from "../../../sources/socket/useSocket";
import NotFoundMessage from "../../common/errors/NotFoundMessage";
const UserNewsPosts = memo(({ title = "Your Published News", url = "/user/news", type = "userNews" }) => {
  const { handleGetRequest, loading, message, userNews } = useGetRequests();
  const { socket, update, setUpdate } = useSocket('userNewsPost')
  useEffect(() => {
    socket?.on('userNewsPost', (data) => {
      setUpdate(data)
    })
  }, [setUpdate, socket])

  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_USER_NEWS_APIKEY,
    };
    handleGetRequest(url, data, type);
    return () => {
      setUpdate(null)
    }
  }, [handleGetRequest, type, url, update, setUpdate]);
  const news = useMemo(() => { return userNews }, [userNews])
  return (
    <>
      <div className="col-span-12 md:col-span-7">
        <div className="mb-4 mx-2 ">
          <div className="w-full 2xl:w-[650px] mx-auto space-y-4">
            <Title title={title} />
            {message !== "" ? (
              <NotFoundMessage message={message}/>
            ) : (
              <UserNewsCard news={news} />
            )}
          </div>
        </div>
        {loading && (
          <div className="w-full 2xl:w-[650px] mx-auto space-y-4 mb-4 mt-2">
            {[1, 2, 3].map((index) => (
              <NewsCardSkeleton key={index} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
);

export default UserNewsPosts;
