import React, { memo } from "react";
import { TbWorld } from "react-icons/tb";
import { AiFillGithub } from "react-icons/ai";
import { FiTwitter, FiFacebook } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
const UserContactDetails = memo(() => {
  return (
    <>
      <div className="shadow-lg border px-2 py-4 flex flex-col gap-2 rounded ">
        <div className="border-b pb-2 px-2 cursor-pointer">
          <div className="flex items-center gap-1 py-1">
            <TbWorld className="text-2xl text-green-400" />
            <span>Website</span>
          </div>
          <span>https://oriansoft.com</span>
        </div>
        <div className="border-b flex justify-between pb-2 px-2 pt-1 cursor-pointer">
          <div className="flex items-center gap-1">
            <AiFillGithub className="text-2xl " />
            <span>Github</span>
          </div>
          <span>ComusBala96</span>
        </div>
        <div className="border-b flex justify-between pb-2 px-2 pt-1 cursor-pointer">
          <div className="flex items-center gap-1">
            <FiTwitter className="text-2xl text-cyan-400" />
            <span>Twitter</span>
          </div>
          <span>@ComusBala96</span>
        </div>
        <div className="border-b flex justify-between pb-2 px-2 pt-1 cursor-pointer">
          <div className="flex items-center gap-1">
            <BsInstagram className="text-2xl text-red-400" />
            <span>Instagram</span>
          </div>
          <span>ComusBala96</span>
        </div>
        <div className="flex justify-between pb-2 px-2 pt-1 cursor-pointer">
          <div className="flex items-center gap-1">
            <FiFacebook className="text-2xl text-blue-400" />
            <span>Facebook</span>
          </div>
          <span>ComusBala96</span>
        </div>
      </div>
    </>
  );
});

export default UserContactDetails;
