import { BsFillPersonFill } from "react-icons/bs";
import { GrUserSettings } from "react-icons/gr";
import { RxActivityLog } from "react-icons/rx";
export default function resources() {
  const token = localStorage.getItem("auth_token");
  const userName = localStorage.getItem("auth_name");
  const email = localStorage.getItem("auth_email");
  const remember = localStorage.getItem("remember");
  const navigator = localStorage.getItem("navigator");
  const localImages = JSON.parse(localStorage.getItem('images'))
  const localVideo = JSON.parse(localStorage.getItem('video'))
  const currentNewsRoutes = [
    { path: "/", category: "null", type: "null" },
    { path: "/latest", category: "null", type: "latest" },
    { path: "/culture", category: "culture", type: "null" },
    { path: "/lifestyle", category: "lifestyle", type: "null" },
    { path: "/business", category: "business", type: "null" },
    { path: "/entertainment", category: "entertainment", type: "null" },
    { path: "/health", category: "health", type: "null" },
    { path: "/science", category: "science", type: "null" },
    { path: "/sports", category: "sports", type: "null" },
    { path: "/technology", category: "technology", type: "null" },
  ];
  const topNewsRoutes = [
    { path: "/topnews", category: "null", type: "latest" },
    { path: "/latest-topnews", category: "null", type: "latest" },
    { path: "/culture-topnews", category: "culture", type: "latest" },
    { path: "/lifestyle-topnews", category: "lifestyle", type: "latest" },
    { path: "/business-topnews", category: "business", type: "latest" },
    {
      path: "/entertainment-topnews",
      category: "entertainment",
      type: "latest",
    },
    { path: "/health-topnews", category: "health", type: "latest" },
    { path: "/science-topnews", category: "science", type: "latest" },
    { path: "/sports-topnews", category: "sports", type: "latest" },
    { path: "/technology-topnews", category: "technology", type: "latest" },
  ];
  const menus = [
    { name: "News", currentUrl: "/", topUrl: "/topnews" },
    { name: "Culture", currentUrl: "/culture", topUrl: "/culture-topnews" },
    {
      name: "Lifestyle",
      currentUrl: "/lifestyle",
      topUrl: "/lifestyle-topnews",
    },
    { name: "Business", currentUrl: "/business", topUrl: "/business-topnews" },
    {
      name: "Entertainment",
      currentUrl: "/entertainment",
      topUrl: "/entertainment-topnews",
    },
    { name: "Health", currentUrl: "/health", topUrl: "/health-topnews" },
    { name: "Science", currentUrl: "/science", topUrl: "/science-topnews" },
    { name: "Sports", currentUrl: "/sports", topUrl: "/sports-topnews" },
    {
      name: "Technology",
      currentUrl: "/technology",
      topUrl: "/technology-topnews",
    },
  ];
  const treandingLatestMenus = [
    { name: "Treanding", currentUrl: "/", topUrl: "/topnews" },
    { name: "Latest", currentUrl: "/latest", topUrl: "/latest-topnews" },
  ];
  const currentLatestMenu = [
    { name: "Current News", url: "" },
    { name: "Top News", url: "" },
  ];
  const logMenus = [
    { name: "Login", url: "/login" },
    { name: "Register", url: "/register" },
  ];
  const loggedMenus = [
    { name: "Profile", icon: <BsFillPersonFill />, url: "/profile" },
    { name: "Settings", icon: <GrUserSettings />, url: "/settings" },
    { name: "Activity Log", icon: <RxActivityLog />, url: "/activity" },
  ];
  const profileMenus = [
    { name: "Profile", url: "/profile" },
    { name: "Favourite", url: "/favourite" },
    { name: "Save", url: "/save" },
    { name: "History", url: "/history" },
    { name: "Activity", url: "/activity" },
    { name: "Settings", url: "/settings" },
  ];

  const select = [
    { name: "type", options: [] },
    { name: "source", options: [] },
    { name: "category", options: [] },
    { name: "language", options: [] },
  ];
  const filterData = {
    type: "type",
    source: "source",
    category: "category",
    language: "language",
  };

  const filters = [
    {
      name: "type",
      options: [
        { name: "Type", value: "" },
        { name: "Article", value: "article" },
        { name: "Headline", value: "headline" },
        { name: "Latest", value: "latest" },
        { name: "Popular", value: "popular" },
      ],
    },
    {
      name: "source",
      options: [
        { name: "Source", value: "" },
        { name: "News Api", value: "news" },
        { name: "G-News Api", value: "gnews" },
      ],
    },
    {
      name: "category",
      options: [
        { name: "Category", value: "" },
        { name: "Culture", value: "culture" },
        { name: "Lifestyle", value: "lifestyle" },
        { name: "Business", value: "business" },
        { name: "Health", value: "health" },
        { name: "Science", value: "science" },
        { name: "Sports", value: "sports" },
        { name: "Technology", value: "technology" },
      ],
    },
  ];
  const settingsMenus = [
    {
      name: "Account",
      url: "/settings",
    },
    {
      name: "Email",
      url: "/email/settings",
    },
    {
      name: "Password",
      url: "/password/settings",
    },
    {
      name: "Privacy and safety",
      url: "/privacy/settings",
    },
    {
      name: "Email notifications",
      url: "/email-notificaiton/settings",
    },
    {
      name: "Users",
      url: "/user-information/settings",
    },
  ];
  return {
    token,
    userName,
    email,
    remember,
    navigator,
    menus,
    treandingLatestMenus,
    currentLatestMenu,
    logMenus,
    loggedMenus,
    profileMenus,
    select,
    filterData,
    filters,
    currentNewsRoutes,
    topNewsRoutes,
    localImages,
    localVideo,
    settingsMenus
  };
}
