import React, { memo } from "react";
import { useSelector } from "react-redux";
import ProfilePhoto from "../../../../../common/profile/ProfilePhoto";
const ProfileSection = memo(() => {
  const { errors } = useSelector((store) => store.data);
  return (
    <>
      {errors && (
        <div className="text-red-500 font-semibold w-full md:w-[80%] mx-auto">
          {errors.profile_photo}
        </div>
      )}
      <div className="flex justify-center ">
        <div className="relative w-full">
          <div className="absolute left-[50%] -translate-x-16 -top-20 md:-top-24 lg:-top-32">
            <ProfilePhoto />
            <div className="flex flex-col items-center">
              <div className="md:space-x-1 flex flex-col md:flex-row items-center">
                <span className="xl:text-lg">Comus Bala</span>
                <span className="text-xs md:text-sm xl:text-base">
                  ( Nick Name )
                </span>
              </div>
              <small>Ocupation</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default ProfileSection;
