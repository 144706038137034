import React, { useState, useRef, useEffect } from "react";
import { GrUserSettings } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import resources from "../../../../sources/data/resources";
export default function SettingsSidebar() {
  const [settingsMenu, setSettingsMenu] = useState(false);
  const settingsMenuRef = useRef(null);

  const hideSetrtingsMenu = (e) => {
    if (!settingsMenuRef?.current?.contains(e.target)) {
      setSettingsMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", hideSetrtingsMenu, true);
  }, []);
  const { settingsMenus } = resources()
  return (
    <>
      <div className="col-span-12 md:col-span-4 lg:col-span-3 sticky top-[162px] md:top-[180px] lg:top-[225px] md:h-[65vh] lg:h-[57vh] pt-2 overflow-y-auto">
        <div className=" bg-white shadow-lg border-x-2" ref={settingsMenuRef}>
          <div className="border-y-2  cursor-pointer">
            <div
              className="flex justify-around items-center mx-4"
              onClick={() => setSettingsMenu(!settingsMenu)}>
              <span className="text-lg py-2">Profile Settings</span>
              <GrUserSettings className=" cursor-pointer" />
            </div>
          </div>
          <div
            className={`${settingsMenu ? "inline-block " : "hidden md:inline-block"
              } w-full `}>
            {settingsMenus &&
              settingsMenus.map((menu, index) => (
                <NavLink
                  key={index}
                  to={menu.url}
                  className={({ isActive }) =>
                    isActive ? " font-bold" : "font-thin"
                  }
                  onClick={() => setSettingsMenu(false)}>
                  <div className="text-base px-4 py-2 border-b hover:bg-blue-400 hover:text-white">
                    {menu.name}
                  </div>
                </NavLink>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
