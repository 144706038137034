import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import NewsCard from "../../common/postCards/NewsCard";
import NewsCardSkeleton from "../../common/skeleton/NewsCardSkeleton";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import { useDispatch, useSelector } from "react-redux";
import { storeNewsSearchNavigation } from "../../../sources/store/slices/dataSlice";
import { useNavigate } from "react-router-dom";
import NotFoundMessage from "../../common/errors/NotFoundMessage";
const SearchNewsPost = memo(
  ({ url = "/news/search", type = "searchNews" }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { newsSearchNavigation, searchInputData } = useSelector((store) => store.data);
    const { handleGetRequest, loading, setLoading, message, searchNews, handleInfinityScroll, buttonData, setButtonData } = useGetRequests();
    const observer = useRef(null)
    const searchQueryData = useMemo(() => {
      return {
        type: searchInputData.type ? searchInputData.type : "null",
        source: searchInputData.source ? searchInputData.source : "null",
        category: searchInputData.category ? searchInputData.category : "null",
        from: searchInputData.from ? searchInputData.from : "null",
        to: searchInputData.to ? searchInputData.to : "null",
        search: searchInputData.search ? searchInputData.search : "null",
        apiKey: process.env.REACT_APP_SEARCH_NEWS_APIKEY,
      }
    }, [searchInputData.category, searchInputData.from, searchInputData.search, searchInputData.source, searchInputData.to, searchInputData.type]);
    const lastNewsElementRef = useCallback((node) => {
      handleInfinityScroll(observer, node, url, searchQueryData, 'searchNewsScroll')
    }, [handleInfinityScroll, searchQueryData, url])
    useEffect(() => {
      if (searchInputData.search === "" && searchInputData.type === "" && searchInputData.source === "" && searchInputData.category === "" && searchInputData.from === "" && searchInputData.to === "") {
        navigate("/");
      } else {
        setLoading(true)
        handleGetRequest(url, searchQueryData, type);
      }
      return () => dispatch(storeNewsSearchNavigation(0));
    }, [dispatch, handleGetRequest, navigate, newsSearchNavigation, searchInputData.category, searchInputData.from, searchInputData.search, searchInputData.source, searchInputData.to, searchInputData.type, searchQueryData, setLoading, type, url]);
    const news = useMemo(() => { return searchNews }, [searchNews])
    return (
      <>
        <div className="col-span-12 md:col-span-7">
          <div className="mb-4 mt-2 mx-2 ">
            <div className="w-full 2xl:w-[650px] mx-auto space-y-4">
              {message !== "" ? (
                <NotFoundMessage message={message} />
              ) : (
                <NewsCard lastNewsElementRef={lastNewsElementRef} news={news} buttonData={buttonData} setButtonData={setButtonData} />
              )}
            </div>
          </div>
          {loading && (
            <div className=" w-full 2xl:w-[650px] mx-auto space-y-4 mb-4 mt-2">
              {[1, 2, 3].map((index) => (
                <NewsCardSkeleton key={index} />
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
);

export default SearchNewsPost;
