import React, { memo } from "react";

const AppLogo = memo(() => {
  return (
    <>
      <div className=" font-roboto  flex flex-col ml-20">
        <h1 className=" font-extrabold font-lusitana text-2xl md:text-4xl lg:text-6xl flex flex-col">
          <span className="ml-4">The</span>
          <span>News App</span>
        </h1>
        <small className="ml-10 md:ml-20 lg:ml-48 lg:text-base">
          All About News
        </small>
      </div>
    </>
  );
});

export default AppLogo;
