import React, { memo, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import CopyToClipboard from "react-copy-to-clipboard";
import Loading from "../../loading/Loading";
import ProgressBar from "../../progress/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { storeIsImagesErrors } from "../../../../sources/store/slices/stateSlice";
import { storeErrors } from "../../../../sources/store/slices/dataSlice";
import useDeleteRequests from "../../../../sources/hooks/requests/useDeleteRequests";
const ImagesViewCard = memo(({ imagesRejected, setImagesRejected, cancelPost, cancelUpload, url = '/delete/image', type = 'deleteImage' }) => {
  const dispatch = useDispatch();
  const { isImages, isImagesErrors } = useSelector((store) => store.state);
  const { images, imageProgress } = useSelector((store) => store.data);
  const [copy, setCopy] = useState(false);
  const { handleDeleteRequest } = useDeleteRequests()
  const handleRejectedImagesDelete = (rejectedImagesName) => {
    setImagesRejected((imagesRejected) =>
      imagesRejected.filter(({ file }) => file.name !== rejectedImagesName)
    );
    if (imagesRejected?.length <= 1) {
      dispatch(storeIsImagesErrors(false));
      dispatch(storeErrors(''));
    }
  };
  return (
    <>
      <div className="grid grid-cols-12 gap-2">
        {isImages && (
          <div className="col-span-12 ">
            <div className="h-full w-full  grid items-center rounded-md">
              <div className="py-2 rounded-md  border border-cyan-300">
                <div className="h-full w-full flex items-center justify-around text-gray-300">
                  {imageProgress > 0 && imageProgress <= 100 ? (
                    <div className="w-full">
                      <div className="flex justify-center items-center">
                        <Loading />
                      </div>
                      <ProgressBar progress={imageProgress} cancelPost={cancelPost} cancelUpload={cancelUpload} />
                    </div>
                  ) : (
                    <div className="flex  ">
                      {images.map((images, index) => (
                        <div
                          className="relative flex flex-col justify-around items-center "
                          key={images.name}
                        >
                          <img
                            src={images.image_url}
                            alt={images.name}
                            className="w-40 h-40 rounded-md py-1 px-1"
                          />
                          <CopyToClipboard
                            text={images.image_url}
                            onCopy={() => {
                              setCopy({ index: index, value: true });
                              setTimeout(() => {
                                setCopy(false);
                              }, 1000);
                            }}
                          >
                            <button
                              type="button"
                              className={`${copy.index === index && copy.value
                                ? "text-green-500"
                                : "text-red-300 hover:text-red-600 duration-200"
                                }`}
                            >
                              {copy.index === index && copy.value
                                ? "Copied"
                                : "Copy Link"}
                            </button>
                          </CopyToClipboard>
                          <span
                            title="close"
                            className="absolute top-1 right-0 text-white hover:text-gray-300  bg-red-600/80 p-0.5 hover:bg-red-600  rounded-full font-bold cursor-pointer"
                            onClick={() => handleDeleteRequest(url, { image: images.name }, type)}
                          >
                            <FaXmark />
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isImagesErrors && (
          <div className="col-span-12 ">
            <div className="h-full w-full  grid items-center rounded-md">
              <div className="h-40  rounded-md  border border-cyan-300">
                <div className=" h-full grid items-center justify-center text-gray-300">
                  <div className="flex flex-col  mx-2 gap-1">
                    {imagesRejected.map(({ file, errors }) => (
                      <div className="relative  mx-2" key={file.name}>
                        {errors.map((imagesError) => (
                          <div key={imagesError.code} className=" space-x-1">
                            <span className="text-red-600/90 lowercase">
                              {file.name}
                            </span>
                            <span className="text-red-600/90 lowercase">
                              {imagesError.message}
                            </span>
                          </div>
                        ))}
                        <span
                          title="close"
                          className=" absolute top-0 right-0 text-white hover:text-gray-300  bg-red-600/80 p-0.5 hover:bg-red-600  rounded-full font-bold cursor-pointer"
                          onClick={() =>
                            handleRejectedImagesDelete(file.name)
                          }
                        >
                          <FaXmark />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
);

export default ImagesViewCard;
