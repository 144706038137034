import React, { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { storeLogInfo } from "../../../sources/store/slices/stateSlice";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import Spining from "../../common/loading/Spining";
import usePreAlertRequest from "../../../sources/hooks/requests/usePreAlertRequest";
import useSocket from "../../../sources/socket/useSocket";
const UserLogInfo = memo(({ logInfo, loggedMenus, userName, profileUrl = "/show/profile", profileType = "profilePhoto", url = "/logout", logoutType = 'logout', message = "You are not Logged in Out!" }) => {
  const dispatch = useDispatch();
  const logoutData = { apiKey: process.env.REACT_APP_LOGOUT_APIKEY }
  const { handleGetRequest, profile, loading } = useGetRequests();
  const { update, setUpdate } = useSocket('profilePhoto')
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_USER_PROFILE_PHOTO_APIKEY,
    };
    handleGetRequest(profileUrl, data, profileType);
    return () => {
      setUpdate(null);
    }
  }, [handleGetRequest, profileType, profileUrl, setUpdate, update]);
  const { handlePreAlertRequest } = usePreAlertRequest()
  return (
    <>
      <div className="flex flex-col items-center">
        <div onClick={() => dispatch(storeLogInfo(!logInfo))} className="relative h-12 w-12 cursor-pointer bg-gray-300 rounded-md hover:bg-gray-600 duration-300">
          {loading ? (
            <div className="h-12 w-12 p-1 rounded-full flex justify-center items-center">
              <Spining className="h-12 w-12 " />
            </div>
          ) : (
            <img
              src={profile}
              alt={profile}
              loading="lazy"
              title="User"
              className=" h-full w-full rounded-full"
            />
          )}
          {logInfo && (
            <div className="absolute z-50 top-16 right-0 sm:right-5 gap-2 flex flex-col text-sm md:text-base  w-72 p-4 border bg-white shadow-md rounded-md">
              {loggedMenus.map((loggedMenu, index) => (
                <Link
                  key={index}
                  to={loggedMenu.url}
                  className="no-underline hover:font-medium">
                  <div className="flex space-x-3 items-center">
                    <span>{loggedMenu.icon}</span>
                    <span>{loggedMenu.name}</span>
                  </div>
                </Link>
              ))}
              <hr className="" />
              <button
                type="button"
                onClick={(e) => { handlePreAlertRequest(e, url, logoutData, logoutType, message) }}
                className="no-underline hover:font-medium">
                <div className="flex space-x-3 items-center">
                  <FiLogOut />
                  <span>Logout ( {userName} )</span>
                </div>
              </button>
            </div>
          )}
        </div>
        <div className="text-xs md:text-sm">{userName}</div>{" "}
      </div>
    </>
  );
}
);

export default UserLogInfo;
