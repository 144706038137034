import React, { memo } from "react";
import { NavLink } from "react-router-dom";

import resources from "../../../sources/data/resources";
import { useSelector } from "react-redux";
const Menus = memo(() => {
  const { navigator } = useSelector((store) => store.data);
  const { menus } = resources();
  return (
    <>
      {menus.map((menu, index) => (
        <NavLink
          key={index}
          to={navigator === "true" ? menu.topUrl : menu.currentUrl}
          className={({ isActive }) =>
            isActive
              ? "hover:-translate-y-0.5 border-b border-yellow-300 hover:border-b hover:border-white font-extrabold hover:font-semibold text-white duration-200"
              : "hover:-translate-y-0.5 hover:border-b hover:border-white font-normal text-white hover:font-semibold duration-200"
          }>
          {menu.name}
        </NavLink>
      ))}
    </>
  );
});

export default Menus;
