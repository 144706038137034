import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import TopHeaderBar from "../../components/home/navbar/TopHeaderBar";
import BreakingNewsBar from "../../components/home/navbar/BreakingNewsBar";
import SupportTitleLogbar from "../../components/home/navbar/SupportTitleLogbar";
import MenuBar from "../../components/home/navbar/MenuBar";

const AuthLayout = memo(() => {
  return (
    <>
      <TopHeaderBar />
      <BreakingNewsBar />
      <SupportTitleLogbar />
      <MenuBar />
      <Outlet />
    </>
  );
});

export default AuthLayout;
