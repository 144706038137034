import { useMemo } from "react";

export default function JoditEditorConfig() {
    const descriptionConfig = useMemo(
        () => ({
            readonly: false,
            height: 280,
            style: {
                background: "rgba(209, 213, 219, 0.2)",
                border: "1px solid rgba(103, 232, 249, 1)",
            },
            placeholder:
                "Start typing news description e.g. Lorem ipsum dolor sit amet consectetur adipisicing elit . . ." +
                " <span class='h-36 flex justify-center items-center text-red-400'>This field description will be displayed as news post. *</span>",
            removeButtons: [
                "brush",
                "paragraph",
                "speechRecognize",
                "file",
                "image",
                "video",
                "table",
                "hr",
                "copyformat",
                "symbol",
                "about",
            ],
            buttons: ["link", "preview", "print", "fullsize"],
        }),

        []
    );
    const contentsConfig = useMemo(
        () => ({
            readonly: false,
            height: 400,
            style: {
                background: "rgba(209, 213, 219, 0.2)",
                border: "1px solid rgba(103, 232, 249, 1)",
            },
            placeholder:
                "Start typing full news contents e.g. Title, Images, Video, Description, Contents . . ." +
                " <span class='h-52 flex justify-center items-center text-red-400'>This field contents will be displayed as full news. *</span>",
            removeButtons: ["speechRecognize"],
        }),

        []
    );
    return { descriptionConfig, contentsConfig }
}
