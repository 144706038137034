import React from "react";
import { Link } from "react-router-dom";
import usePostRequests from "../../sources/hooks/requests/usePostRequests";
import { useSelector } from "react-redux";
import useHandleFormData from "../../sources/functions/useHandleFormData";
export default function Register({ url = "/register", type = "register" }) {
  const { registerData, errors } = useSelector((store) => store.data);
  const { handleInputFieldData, handleCheckBoxFieldData } = useHandleFormData()
  const { handlePostRequest } = usePostRequests();

  return (
    <>
      <div className="lg:h-authHeight lg:overflow-y-auto flex justify-center items-center">
        <div className="w-full lg:w-[650px] mx-auto border rounded-md bg-gray-50 shadow-sm px-2">
          <div className="text-lg mx-4 py-2">Registration</div>
          <hr />
          <form onSubmit={(e) => handlePostRequest(e, url, registerData, type)}>
            <div className="mx-6 mt-6 flex flex-col gap-2">
              <div className="md:grid md:grid-cols-12 gap-1 justify-between mx-4">
                <div className="md:col-span-4">
                  Name <span className="text-red-700 font-bold">*</span>
                </div>
                <input
                  type="text"
                  name="name"
                  value={registerData.name}
                  onChange={(e) => handleInputFieldData(e, 'registerInput')}
                  className="ring-1 pl-2 md:py-1 w-full ring-gray-300 rounded-md outline-none md:col-span-8"
                  placeholder="Enter your name?"
                />
              </div>
              <div className="md:grid md:grid-cols-12 gap-1  mx-4">
                <div className="md:col-span-4"></div>
                <div className="text-red-700 w-full md:col-span-8">
                  {errors.name}
                </div>
              </div>
              <div className="md:grid md:grid-cols-12 gap-1 justify-between mx-4">
                <div className="md:col-span-4">
                  Email <span className="text-red-700 font-bold">*</span>
                </div>
                <input
                  type="email"
                  name="email"
                  value={registerData.email}
                  onChange={(e) => handleInputFieldData(e, 'registerInput')}
                  className="ring-1 pl-2 md:py-1 w-full ring-gray-300 rounded-md outline-none md:col-span-8"
                  placeholder="Enter your email?"
                />
              </div>
              <div className="md:grid md:grid-cols-12 gap-1  mx-4">
                <div className="md:col-span-4"></div>
                <div className="text-red-700 w-full md:col-span-8">
                  {errors.email}
                </div>
              </div>
              <div className="md:grid md:grid-cols-12 gap-1 justify-between mx-4">
                <div className="md:col-span-4">
                  Password <span className="text-red-700 font-bold">*</span>
                </div>
                <input
                  type="password"
                  name="password"
                  value={registerData.password}
                  onChange={(e) => handleInputFieldData(e, 'registerInput')}
                  autoComplete="off"
                  className="ring-1 pl-2 md:py-1 w-full ring-gray-300 rounded-md outline-none md:col-span-8"
                  placeholder="Enter your password?"
                />
              </div>
              <div className="md:grid md:grid-cols-12 gap-1  mx-4">
                <div className="md:col-span-4"></div>
                <div className="text-red-700 w-full md:col-span-8">
                  {errors.password}
                </div>
              </div>
              <div className="md:grid md:grid-cols-12 gap-1 justify-between mx-4">
                <div className="md:col-span-4">
                  Re-Password <span className="text-red-700 font-bold">*</span>
                </div>
                <input
                  type="password"
                  name="password_confirmation"
                  value={registerData.password_confirmation}
                  onChange={(e) => handleInputFieldData(e, 'registerInput')}
                  autoComplete="off"
                  className="ring-1 pl-2 md:py-1 w-full ring-gray-300 rounded-md outline-none md:col-span-8"
                  placeholder="Confirm your password?"
                />
              </div>
              <div className="md:grid md:grid-cols-12 gap-1  mx-4">
                <div className="md:col-span-4"></div>
                <div className="text-red-700 w-full md:col-span-8">
                  {errors.password_confirmation}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 space-x-1 mx-4">
              <div className="md:col-span-4"></div>
              <div className="md:col-span-8 py-1 flex items-center">
                <div className="flex gap-1">
                  <input
                    type="checkbox"
                    className="ml-2 mr-1"
                    name="agree"
                    value={registerData.agree}
                    onChange={(e) => handleCheckBoxFieldData(e, 'registerCheckbox')}
                    defaultChecked={false}
                  />
                  <div className="space-x-2">
                    <span>I agree with</span>
                    <Link
                      to="/terms"
                      className="space-x-1 text-blue-700 hover:text-blue-700/80">
                      <span>terms & conditions</span>
                      <span className="text-red-700 font-bold">*</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-12 gap-1  mx-4">
              <div className="md:col-span-4"></div>
              <div className="ml-3 text-red-700 w-full md:col-span-8">
                {errors.agree}
              </div>
            </div>
            <div className="grid grid-cols-3 space-x-1">
              <div className="col-span-1"></div>
              <div className="col-span-2 py-1">
                <Link
                  to="/forget-password"
                  className="ml-3 text-blue-700 hover:text-blue-700/80">
                  Forgot Your Password?
                </Link>
              </div>
            </div>
            <div className="m-6 mr-12 flex gap-6 justify-end">
              <div className="text-blue-700 hover:text-blue-700/80 hidden md:inline-block">
                Already have an account? Please
              </div>
              <Link
                to="/login"
                className=" ring-1 ring-gray-300 rounded-md py-1 px-2 text-sm md:text-base text-white bg-blue-600 hover:bg-blue-400">
                Login
              </Link>
              <button
                className=" ring-1 ring-gray-300 rounded-md py-1 px-2 text-sm md:text-base text-white bg-blue-600 hover:bg-blue-400"
                type="submit">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
