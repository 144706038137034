import React from "react";
import { Link, useParams } from "react-router-dom";
import usePostRequests from "../../sources/hooks/requests/usePostRequests";
import {  useSelector } from "react-redux";
import useHandleFormData from "../../sources/functions/useHandleFormData";
export default function ResetPassword({ url = "/reset/password", type = "resetPassword" }) {
  const { resetEmail, resetToken } = useParams();
  const { resetPasswordData, errors } = useSelector((store) => store.data);
  const { handleInputFieldData } = useHandleFormData()
  const data = {
    email: resetEmail,
    token: resetToken,
    password: resetPasswordData.password,
    password_confirmation: resetPasswordData.confirm_password,
  };
  const { handlePostRequest } = usePostRequests();
  return (
    <>
      <div className="lg:h-authHeight lg:overflow-y-auto flex justify-center items-center">
        <div className="w-full lg:w-[650px] mx-auto border rounded-md bg-gray-50 shadow-sm px-2">
          <div className="text-lg mx-4 py-2">Recover Password</div>
          <hr />
          <form onSubmit={(e) => handlePostRequest(e, url, data, type)}>
            <div className="m-6 space-y-4">
              <div className="flex gap-10">
                <div className="">
                  <span>New Password</span>
                  <span className="text-red-700 font-bold"> *</span>
                </div>
                <div className="">
                  <input
                    type="password"
                    name="password"
                    value={resetPasswordData.password}
                    onChange={(e) => handleInputFieldData(e, type)}
                    className="col-span-2 ring-1 ring-gray-300 rounded-md outline-none py-1 pl-2 md:w-96"
                    placeholder="Enter your new password?"
                  />
                </div>
              </div>
              <div className="ml-36 text-red-500 font-semibold">
                {errors.password}
              </div>
              <div className="flex gap-3">
                <div className="">
                  <span>Confirm Password</span>
                  <span className="text-red-700 font-bold"> *</span>
                </div>
                <div className="">
                  <input
                    type="password"
                    name="confirm_password"
                    value={resetPasswordData.confirm_password}
                    onChange={(e) => handleInputFieldData(e, type)}
                    className="col-span-2 ring-1 ring-gray-300 rounded-md outline-none py-1 pl-2 md:w-96"
                    placeholder="Confirm your new password?"
                  />
                </div>
              </div>
              <div className="ml-36 text-red-500 font-semibold">
                {errors.password_confirmation}
              </div>
              <div className="flex gap-6 justify-end items-center mr-24">
                <Link
                  to="/forget-password"
                  className=" text-blue-700 hover:text-blue-700/80 "
                >
                  Forgot Your Password?
                </Link>
                <Link
                  to="/login"
                  className="ring-1 ring-gray-300 rounded-md py-1 px-3 text-white bg-blue-600 hover:bg-blue-400"
                >
                  Login
                </Link>
                <button
                  type="submit"
                  className="ring-1 ring-gray-300 rounded-md py-1 px-3 text-white bg-blue-600 hover:bg-blue-400"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
