import React, { memo, useEffect } from "react";
import useGetRequests from "../../../../../../sources/hooks/requests/useGetRequests";
import { useSelector } from "react-redux";
import usePostRequests from "../../../../../../sources/hooks/requests/usePostRequests";
import PublicInfoFormSkeleton from "../../../../../common/skeleton/PublicInfoFormSkeleton";
import useHandleFormData from "../../../../../../sources/functions/useHandleFormData";
import useSocket from "../../../../../../sources/socket/useSocket";

const PublicInfoForm = memo(({ url = "/user", type = "user", postUrl = "/user/public/info/create", postType = "publicInfo", }) => {
  const { userPublicInfo, errors } = useSelector((store) => store.data);
  const { handleGetRequest, user, message, loading } = useGetRequests();
  const { handleInputFieldData } = useHandleFormData();
  const { socket, update, setUpdate } = useSocket('publicInfo')
  useEffect(() => {
    socket?.on('publicInfo', (data) => {
      setUpdate(data)
    })
  }, [setUpdate, socket])
  const postData = {
    nick_name: userPublicInfo.nick_name,
    occupation: userPublicInfo.occupation,
    biography: userPublicInfo.biography,
    description: userPublicInfo.description,
    id: user.id,
    apiKey: process.env.REACT_APP_COMMON_APIKEY,
  };
  const { handlePostRequest } = usePostRequests();

  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_COMMON_APIKEY,
    };
    handleGetRequest(url, data, type);
    return () => {
      setUpdate(null)
    }
  }, [handleGetRequest, type, url, update, setUpdate]);
  return (
    <>
      {loading ? (
        <PublicInfoFormSkeleton />
      ) : (
        <>
          {message ? (
            <div className="w-full h-[50vh] flex justify-center items-center">
              {message}
            </div>
          ) : (
            <form onSubmit={(e) => handlePostRequest(e, postUrl, postData, postType)} className="text-sm">
              <div className="ml-10">
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                  <div className="col-span-3">Full Name:</div>
                  <div className="col-span-9 outline-none bg-gray-100 pl-2 py-1 border border-cyan-300 w-full h-9">
                    {user.name}
                  </div>
                </div>
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">Email:</div>
                  <div className="col-span-9 outline-none bg-gray-100 pl-2 py-1 border border-cyan-300 w-full h-9">
                    {user.email}
                  </div>
                </div>
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">Username:</div>
                  <div className="col-span-9 outline-none bg-gray-100 pl-2 py-1 border border-cyan-300 w-full h-9">
                    {user.user_name}
                  </div>
                </div>
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    Nick Name:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="text"
                    name="nick_name"
                    value={userPublicInfo.nick_name}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    placeholder="Enter your nick name?"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                {errors && (
                  <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                    <div className="col-span-3"></div>
                    <div className="text-red-500 col-span-9">
                      {errors.nick_name}
                    </div>
                  </div>
                )}
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    Occupation:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="text"
                    name="occupation"
                    value={userPublicInfo.occupation}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    placeholder="Enter your occupation?"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                {errors && (
                  <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                    <div className="col-span-3"></div>
                    <div className="text-red-500 col-span-9">
                      {errors.occupation}
                    </div>
                  </div>
                )}
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12 ">
                  <label htmlFor="email" className="col-span-3">
                    Biography:
                  </label>
                  <textarea
                    type="text"
                    name="biography"
                    value={userPublicInfo.biography}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    rows="4"
                    placeholder="Tell something about yourself?"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full"></textarea>
                </div>
                {errors && (
                  <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                    <div className="col-span-3"></div>
                    <div className="text-red-500 col-span-9">
                      {errors.biography}
                    </div>
                  </div>
                )}
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">Description:</div>
                  <textarea
                    type="text"
                    name="description"
                    value={userPublicInfo.description}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    rows="6"
                    placeholder="Write your description?"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full"></textarea>
                </div>
                {errors && (
                  <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                    <label htmlFor="" className="col-span-3"></label>
                    <div className="text-red-500 col-span-9">
                      {errors.description}
                    </div>
                  </div>
                )}
                <div className="col-span-12 text-end mb-4 mx-3">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-gray-50 rounded-md px-2 py-1 cursor-pointer text-md ">
                    {userPublicInfo.user_id ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
}
);

export default PublicInfoForm;
