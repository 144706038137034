import React, { memo } from "react";

import SidebarUserInfo from "./SidebarUserInfo";
import SidebarUserMenus from "./SidebarUserMenus";

const SidebarMenus = memo(() => {
  return (
    <>
      <div className="">
        <SidebarUserInfo />
        <SidebarUserMenus />
      </div>
    </>
  );
});

export default SidebarMenus;
