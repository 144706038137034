import React from "react";
import SkeletonBar from "./SkeletonBar";

export default function UserInfoSkeleton() {
  return (
    <>
      <div className="flex flex-col">
        <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
          <SkeletonBar
            parentClass={"h-7 w-[80%]  bg-gray-300/60 col-span-2 rounded"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={
              "h-7 w-full bg-gray-300/60 border col-span-10  mr-6 pl-2 py-1 rounded"
            }
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
          <SkeletonBar
            parentClass={"h-7 w-[80%]  bg-gray-300/60 col-span-2 rounded"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={
              "h-7 w-full bg-gray-300/60 border col-span-10  mr-6 pl-2 py-1 rounded"
            }
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
          <SkeletonBar
            parentClass={"h-7 w-[80%]  bg-gray-300/60 col-span-2 rounded"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={
              "h-7 w-full bg-gray-300/60 border col-span-10  mr-6 pl-2 py-1 rounded"
            }
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
          <SkeletonBar
            parentClass={"h-7 w-[80%]  bg-gray-300/60 col-span-2 rounded"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={
              "h-7 w-full bg-gray-300/60 border col-span-10  mr-6 pl-2 py-1 rounded"
            }
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>

        <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
          <SkeletonBar
            parentClass={"h-7 w-[80%]  bg-gray-300/60 col-span-2 rounded"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={
              "h-7 w-full bg-gray-300/60 border col-span-10  mr-6 pl-2 py-1 rounded"
            }
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
        <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
          <SkeletonBar
            parentClass={"h-7 w-[80%]  bg-gray-300/60 col-span-2 rounded"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
          <SkeletonBar
            parentClass={
              "h-7 w-full bg-gray-300/60 border col-span-10  mr-6 pl-2 py-1 rounded"
            }
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
      </div>
    </>
  );
}
