import React from "react";
import { Link } from 'react-router-dom';
export default function Ads() {
  return (
    <>
      <div className="lg:col-span-5 hidden lg:inline-block ">
        <div className="sticky md:top-[220px] lg:top-[250px]  bg-white m-1">
          <Link to="/" target="_blank">
            <div className=" relative">
            <div className="bg-gray-200 rounded-md p-1">
              <img
                src="/storage/default/ad.jpg"
                alt="ad.jpg"
                className="rounded-md w-full cursor-pointer"
              />
            </div>
            <div className="absolute top-3 left-2 w-10">
              <span
                className=" bg-gray-200/60 py-1 px-3 rounded-full cursor-pointer hover:bg-gray-200 border"
                title="My Ad Center"
              >
                i
              </span>
            </div>
            <div className="absolute bottom-0 w-full cursor-pointer">
              <div className="bg-gray-200/60 flex items-center p-2 space-x-1 w-full">
                <img
                  src={"/storage/default/profile.png"}
                  alt="profile.png"
                  className="h-10 w-10 rounded-full border"
                />
                <div className="flex items-center space-x-1 w-full lg:justify-between text-xs">
                  <div className="">
                    <h1 className="font-semibold">New Web Series</h1>
                    <small className="space-x-1">
                      <span>Ad</span>
                      <span>.</span>
                      <span>www.amazon.de/minitv/</span>
                    </small>
                  </div>
                  <button className="text-[9px] px-2 py-1  bg-blue-700/60 hover:bg-blue-700 text-white rounded-full ">
                    Watch free
                  </button>
                </div>
              </div>
            </div>
          </div></Link>

        </div>
      </div>
    </>
  );
}
