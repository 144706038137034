import { useDispatch, useSelector } from "react-redux";
import { useCallback } from 'react'
import { storeErrors, storeForgetPassEmail, storeNewsSearchNavigation, storeResetPasswordData, storeSearchInputData, storeUpdateEmail, storeUpdatePassword, storeUserLoginData, storeUserNewsFormData, storeUserPrivateInfo, storeUserPublicInfo, storeUserRegisterData } from "../store/slices/dataSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function useHandleFormData() {
    const dispatch = useDispatch()
    const navigate = useNavigate(null);
    const { registerData, loginData, forgetPassEmail, resetPasswordData, userNewsFormData, searchInputData, newsSearchNavigation, userPublicInfo, userPrivateInfo,updateEmail,updatePassword, errors } = useSelector((store) => store.data)
    const handleConditionalDispachEvent = useCallback((e, type = '') => {
        switch (type) {
            case 'registerInput':
                dispatch(storeUserRegisterData({ ...registerData, [e.target.name]: e.target.value }));
                break;
            case 'registerCheckbox':
                dispatch(storeUserRegisterData({ ...registerData, [e.target.name]: e.target.value }));
                break;
            case 'loginInput':
                dispatch(storeUserLoginData({ ...loginData, [e.target.name]: e.target.value }));
                break;
            case 'loginCheckbox':
                dispatch(storeUserLoginData({ ...loginData, [e.target.name]: e.target.checked }));
                break;
            case 'forgetPassword':
                dispatch(storeForgetPassEmail({ ...forgetPassEmail, [e.target.name]: e.target.value, }));
                break;
            case 'resetPassword':
                dispatch(storeResetPasswordData({ ...resetPasswordData, [e.target.name]: e.target.value }));
                break;
            case 'userNewsInput':
                dispatch(storeUserNewsFormData({ ...userNewsFormData, [e.target.name]: e.target.value, }));
                break;
            case 'userNewsCheckbox':
                dispatch(storeUserNewsFormData({ ...userNewsFormData, [e.target.name]: e.target.checked, }));
                break;
            case 'newsSearchInput':
                dispatch(storeSearchInputData({ ...searchInputData, [e.target.name]: e.target.value }));
                break;
            case 'privateInfo':
                dispatch(storeUserPrivateInfo({ ...userPrivateInfo, [e.target.name]: e.target.value }));
                break;
            case 'publicInfo':
                dispatch(storeUserPublicInfo({ ...userPublicInfo, [e.target.name]: e.target.value }));
                break;
            case 'emailUpdate':
                dispatch(storeUpdateEmail({...updateEmail,[e.target.name]: e.target.value}));
                break;
            case 'passwordUpdate':
                dispatch(storeUpdatePassword({...updatePassword,[e.target.name]: e.target.value,}));
                break;
            default:
                console.log(type);
                break;
        }
    }, [dispatch, forgetPassEmail, loginData, registerData, resetPasswordData, searchInputData, updateEmail, updatePassword, userNewsFormData, userPrivateInfo, userPublicInfo])
    const handleInputFieldData = useCallback((e, type) => {
        handleConditionalDispachEvent(e, type)
    }, [handleConditionalDispachEvent]);
    const handleCheckBoxFieldData = useCallback((e, type) => {
        handleConditionalDispachEvent(e, type)
    }, [handleConditionalDispachEvent]);
    const handleFiltersFromDate = useCallback(
        (value) => {
            if (value !== null) {
                let input_date = moment(value).format("YYYY-MM-DD");
                const dateTime = [input_date, "00:00:00"].join(" ");
                dispatch(storeSearchInputData({ ...searchInputData, from: moment(dateTime).format("YYYY-MM-DD HH:mm:ss") }));
            } else {
                dispatch(storeSearchInputData({ ...searchInputData, from: "" }));
            }
        },
        [dispatch, searchInputData]
    );
    const handleFiltersToDate = useCallback(
        (value) => {
            if (value !== null) {
                let input_date = moment(value).format("YYYY-MM-DD");
                const dateTime = [input_date, "23:59:59.999"].join(" ");
                dispatch(storeSearchInputData({ ...searchInputData, to: moment(dateTime).format("YYYY-MM-DD HH:mm:ss") }));
            } else {
                dispatch(storeSearchInputData({ ...searchInputData, to: "" }));
            }
        },
        [dispatch, searchInputData]
    );
    const handleNewsSearch = useCallback(
        (e) => {
            if (searchInputData.search !== "" || searchInputData.type !== "" || searchInputData.source !== "" || searchInputData.category !== "" || searchInputData.from !== "" || searchInputData.to !== "") {
                if (e.type === "click" || e.key === "Enter") {
                    dispatch(storeNewsSearchNavigation(newsSearchNavigation + 1));
                    navigate("/search");
                }
            } else {
                if (e.type === "click" || e.key === "Enter") {
                    if (errors !== "") {
                        dispatch(storeErrors(""));
                    } else {
                        dispatch(storeErrors("Please fill at least one field?"));
                    }
                }
            }
        },
        [dispatch, errors, navigate, newsSearchNavigation, searchInputData.category, searchInputData.from, searchInputData.search, searchInputData.source, searchInputData.to, searchInputData.type]
    );
    return { handleInputFieldData, handleCheckBoxFieldData, handleFiltersFromDate, handleFiltersToDate, handleNewsSearch }
}
