import { useNavigate, useParams } from "react-router-dom";
import usePostRequests from "../../sources/hooks/requests/usePostRequests";
import { useEffect } from "react";
import SweetAlert from "../../sources/functions/SweetAlert";
export default function EmailVerified({ url = "/email/verified", type = "verified" }) {
  const navigate = useNavigate();
  const { token } = useParams();
  const { handlePostRequest } = usePostRequests();
  useEffect(() => {
    const data = { token: "Bearer " + token };
    if (localStorage.getItem("auth_token") === token) {
      window.addEventListener("load", (e) => handlePostRequest(e, url, data, type));
    } else {
      SweetAlert("Denied", "Something went wrong? Please provied correct credentials?", "warning", null, "errors-text-color",)
      navigate("/verify");
    }
    return () => window.removeEventListener("load", (e) => handlePostRequest(e, url, data, type));
  }, [handlePostRequest, navigate, token, type, url]);
}
