import React, { memo } from 'react'
import SkeletonBar from './SkeletonBar'
import SkeletonImage from './SkeletonImage'

const HistoryActivityCardSkeleton = memo(() => {
    return (
        <>
            <div className="h-[164px] px-2 py-5 bg-gray-50 shadow-md rounded-md grid grid-flow-row grid-cols-12 gap-2">
                <div className="flex items-center justify-center col-span-3">
                    <SkeletonBar
                        parentClass={"h-[82px] w-[95%] bg-gray-300/60"}
                        childClass={"h-full w-full bg-white/20 animate-slide"}
                    />
                </div>
                <div className="col-span-9">
                    <div className="grid grid-flow-row grid-cols-12 items-center gap-2">
                        <div className="col-span-1">
                            <SkeletonImage className="h-10 w-10 bg-gray-300/60 rounded-full" />
                        </div>
                        <div className="col-span-7 flex flex-col gap-1 ml-2">
                            <SkeletonBar
                                parentClass={"h-4 w-[25%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            /><SkeletonBar
                                parentClass={"h-4 w-[100%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            />
                        </div>
                        <div className="col-span-3 flex flex-col gap-1 ml-2">
                            <SkeletonBar
                                parentClass={"h-4 w-[90%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            /><SkeletonBar
                                parentClass={"h-4 w-[90%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            />
                        </div>
                        <div className="col-span-1">
                            <SkeletonImage className="h-8 w-8 bg-gray-300/60 rounded-full" />
                        </div>
                    </div>
                    <div className="py-2">
                        <div className="flex flex-col gap-1">
                            <SkeletonBar
                                parentClass={"h-4 w-[100%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            />
                            <SkeletonBar
                                parentClass={"h-4 w-[25%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            />
                        </div>
                    </div>
                    <div className="pb-2">
                        <div className="flex flex-col gap-1">
                            <SkeletonBar
                                parentClass={"h-4 w-[100%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            /><SkeletonBar
                                parentClass={"h-4 w-[100%] bg-gray-300/60"}
                                childClass={"h-full w-full bg-white/20 animate-slide"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default HistoryActivityCardSkeleton