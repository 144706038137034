import React, { memo, useCallback, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import Loading from "../common/loading/Loading";
import { useDispatch } from "react-redux";
import { storeNewsShareModal } from "../../sources/store/slices/modalSlice";
import usePutRequests from "../../sources/hooks/requests/usePutRequests";
import usePatchRequests from "../../sources/hooks/requests/usePatchRequests";
const NewsShareModal = memo(({ newsId, message = "You has been shared a published news." }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");
  const [copyAlert, setCopyAlert] = useState(false);
  useEffect(() => {
    setLoading(false);
    setUrl(`${process.env.REACT_APP_DOMAIN_BASE_URL}api/news/${newsId}`);
  }, [newsId]);
  const { handlePutRequest } = usePutRequests()
  const { handlePatchRequest } = usePatchRequests()
  const handleCopyUrl = useCallback(
    (e, newsId, message) => {
      if (newsId) {
        setCopyAlert(true);
        setTimeout(() => {
          setCopyAlert(false);
        }, 500);
        handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
        handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
      }
    },
    [handlePatchRequest, handlePutRequest]
  );
  return (
    <>
      <div className="flex justify-center items-center h-screen w-screen  bg-background/95 fixed top-0 left-0 z-50 ">
        <div className="flex h-modalHeight w-full lg:w-modalWidth mx-1 rounded-xl bg-gray-700/40">
          <div className="flex flex-col w-full h-full ">
            <div className="flex justify-end p-3">
              <MdClose
                title="Close"
                className="cursor-pointer text-red-600 bg-gray-300/60 hover:bg-gray-300 rounded-full p-0.5 text-2xl"
                onClick={() => {
                  dispatch(storeNewsShareModal(false))
                }}
              />
            </div>
            {loading ? (
              <div className="flex h-modalHeight w-full lg:w-modalWidth justify-center items-center">
                <Loading />
              </div>
            ) : (
              <div className="flex items-center justify-center h-modalHeight p-2 overflow-y-auto scrollbar-thin scrollbar-track-blue-600 scrollbar-thumb-cyan-200">
                <div className="w-full lg:w-[796px] mx-auto">
                  <div className="p-4 bg-white shadow-md rounded-md border border-cyan-700">
                    <div className="py-4 px-2  bg-gray-100">
                      <div className="flex items-center justify-around h-20 border-b border-gray-300/40">
                        <FacebookShareButton url={url}>
                          <div
                            onClick={(e) => {
                              handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
                              handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
                            }}
                            className="hover:scale-110 duration-300"
                          >
                            <FacebookIcon size={32} round={true} />
                          </div>
                        </FacebookShareButton>
                        <FacebookMessengerShareButton url={url}>
                          <div
                            onClick={(e) => {
                              handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
                              handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
                            }}
                            className="hover:scale-110 duration-300"
                          >
                            <FacebookMessengerIcon size={32} round={true} />
                          </div>
                        </FacebookMessengerShareButton>
                        <WhatsappShareButton url={url}>
                          <div
                            onClick={(e) => {
                              handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
                              handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
                            }}
                            className="hover:scale-110 duration-300"
                          >
                            <WhatsappIcon size={32} round={true} />
                          </div>
                        </WhatsappShareButton>
                        <TwitterShareButton url={url}>
                          <div
                            onClick={(e) => {
                              handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
                              handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
                            }}
                            className="hover:scale-110 duration-300"
                          >
                            <XIcon size={32} round={true} />
                          </div>
                        </TwitterShareButton>
                        <LinkedinShareButton url={url}>
                          <div
                            onClick={(e) => {
                              handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
                              handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
                            }}
                            className="hover:scale-110 duration-300"
                          >
                            <LinkedinIcon size={32} round={true} />
                          </div>
                        </LinkedinShareButton>
                        <TelegramShareButton url={url}>
                          <div
                            onClick={(e) => {
                              handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
                              handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
                            }}
                            className="hover:scale-110 duration-300"
                          >
                            <TelegramIcon size={32} round={true} />
                          </div>
                        </TelegramShareButton>
                        <EmailShareButton url={url}>
                          <div
                            onClick={(e) => {
                              handlePutRequest("/update/news/share", { news_id: newsId }, "newsShareUpdate");
                              handlePatchRequest(e, "/user/store/activity", { news_id: newsId, message: message }, "userStoreActivity", "");
                            }}
                            className="hover:scale-110 duration-300"
                          >
                            <EmailIcon size={32} round={true} />
                          </div>
                        </EmailShareButton>
                      </div>
                      <div className="flex gap-2 items-center justify-around h-20">
                        <div className="truncate bg-gray-300/40 px-2 py-1 rounded-">
                          {url}
                        </div>
                        <div className="text-white">
                          {copyAlert ? (
                            <span className="bg-cyan-700 px-2 py-1 hover:bg-cyan-600  rounded-md">
                              Copied
                            </span>
                          ) : (
                            <CopyToClipboard text={url}>
                              <button
                                onClick={(e) => handleCopyUrl(e, newsId, message)}
                                className=" bg-cyan-700 px-2 py-1 hover:bg-cyan-600  rounded-md"
                              >
                                Copy
                              </button>
                            </CopyToClipboard>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default NewsShareModal;
