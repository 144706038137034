import React, { memo } from 'react'

const NotFoundMessage = memo(({ message }) => {
    return (
        <>
            <div className="h-[660px] w-full 2xl:w-[650px] flex flex-col items-center justify-center px-2 py-5 bg-gray-50 shadow-md rounded-md">
                <img src="/storage/default/no_data.jpg" alt="no_data.jpg" />
                <div className="">{message}</div>
            </div>
        </>
    )
})

export default NotFoundMessage