import React, { memo } from "react";

const UserContributeStatus = memo(() => {
  return (
    <>
      <div className="shadow-lg border p-4 flex flex-col gap-1 rounded w-1/2">
        <span className="pb-4">assignment Project Status</span>
        <div className="flex flex-col gap-2">
          <div className="py-1">
            <small className="m-0 text-xs">Web Design</small>
            <div className="h-[6px] bg-gray-100 m-0">
              <div className="h-[6px] bg-blue-500 w-2/3 m-0"></div>
            </div>
          </div>
          <div className="py-1">
            <small className="m-0 text-xs">Website Markup</small>
            <div className="h-[6px] bg-gray-100 m-0">
              <div className="h-[6px] bg-blue-500 w-1/2 m-0"></div>
            </div>
          </div>
          <div className="py-1">
            <small className="m-0 text-xs">EveryThings Page</small>
            <div className="h-[6px] bg-gray-100 m-0">
              <div className="h-[6px] bg-blue-500 w-3/4 m-0"></div>
            </div>
          </div>
          <div className="py-1">
            <small className="m-0 text-xs">Mobile Template</small>
            <div className="h-[6px] bg-gray-100 m-0">
              <div className="h-[6px] bg-blue-500 w-1/3 m-0"></div>
            </div>
          </div>
          <div className="py-1">
            <small className="m-0 text-xs">Backend API</small>
            <div className="h-[6px] bg-gray-100 m-0">
              <div className="h-[6px] bg-blue-500 w-2/5 m-0"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default UserContributeStatus;
