import React, { useEffect } from "react";
import Title from "../../../common/title/Title";
import HistoryCard from "../../../common/postCards/HistoryCard";
import useGetRequests from "../../../../sources/hooks/requests/useGetRequests";
import HistoryActivityCardSkeleton from "../../../common/skeleton/HistoryActivityCardSkeleton";
import useSocket from "../../../../sources/socket/useSocket";
import NotFoundMessage from "../../../common/errors/NotFoundMessage";
export default function History({ url = "/user/history/news", type = "historyNews", }) {
  const { socket, update, setUpdate } = useSocket('userHistoryDelete')
  const { handleGetRequest, loading, message, historyNews } = useGetRequests();
  useEffect(() => {
    socket?.on('userHistoryDelete', (data) => {
      setUpdate(data)
    })
  }, [setUpdate, socket])
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_COMMON_APIKEY,
    };
    handleGetRequest(url, data, type); return () => {
      setUpdate(null)
    }
  }, [handleGetRequest, url, type, update, setUpdate]);

  return (
    <>
      <div className="">
        <Title title="Your History" />
        <div className="w-full lg:w-[650px] mx-auto flex flex-col gap-3 py-6">
          {loading ? (
            <div className=" w-full 2xl:w-[650px] mx-auto space-y-4 mb-4 mt-2">
              {[1, 2, 3].map((index) => (
                <HistoryActivityCardSkeleton key={index} />
              ))}
            </div>
          ) : (
            <>
              {message ? (
                <NotFoundMessage message={message} />
              ) : (
                <HistoryCard histories={historyNews} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
