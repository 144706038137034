import React from "react";
import { Outlet } from "react-router-dom";
import NewsShareModal from "./../components/modal/NewsShareModal";
import { useSelector } from "react-redux";
import { Toaster } from 'react-hot-toast';
export default function Home() {
  const { newsShareModal, shareNewsId } = useSelector((store) => store.modal);
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 2000,
        }}
      />
      <div className="w-full 3xl:max-w-screen-3xl mx-auto border-x shadow-md">
        {newsShareModal && <NewsShareModal newsId={shareNewsId} />}
        <Outlet />
      </div>
    </>
  );
}
