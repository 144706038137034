import React from "react";
import { Link } from "react-router-dom";
import usePostRequests from "../../sources/hooks/requests/usePostRequests";
import { useSelector } from "react-redux";
import useHandleFormData from "../../sources/functions/useHandleFormData";
export default function ForgetPassword({ url = "/forget/password", type = "forgetPassword" }) {
  const { forgetPassEmail, errors } = useSelector((store) => store.data);
  const { handleInputFieldData } = useHandleFormData()
  const { handlePostRequest } = usePostRequests();
  return (
    <>
      <div className="lg:h-authHeight lg:overflow-y-auto flex justify-center items-center ">
        <div className="w-full lg:w-[650px] border rounded-md bg-gray-50 pb-10 shadow-sm">
          <div className="text-lg p-3">Reset Password</div>
          <hr />
          <form onSubmit={(e) => handlePostRequest(e, url, { email: forgetPassEmail.email }, type)}>
            <div className="ml-4">
              <div className="grid grid-cols-12 items-center gap-2 mb-2 mt-6">
                <div className="col-span-3 flex gap-1">
                  <div> Email Address</div>
                  <div className="text-red-500 font-bold"> *</div>
                </div>
                <input
                  type="email"
                  name="email"
                  value={forgetPassEmail.email}
                  onChange={(e) => handleInputFieldData(e, type)}
                  className="col-span-8 ring-1 pl-2 py-1 ring-gray-300 rounded-md outline-none"
                  placeholder="Enter your registered email?"
                />
              </div>
              <div className="grid grid-cols-12 items-center">
                <div className="col-span-3"></div>
                <div className="col-span-9 text-red-500 font-semibold">
                  {errors.email}
                </div>
              </div>
              <div className="flex gap-4 justify-end  m-6 mr-24 mb-3">
                <Link
                  to="/login"
                  className="no-underline ring-1 ring-gray-300 rounded-md py-1 px-4 text-white bg-blue-600 hover:bg-blue-400"
                >
                  Login
                </Link>
                <button
                  className="no-underline ring-1 ring-gray-300 rounded-md py-1 px-4 text-white bg-blue-600 hover:bg-blue-400"
                  type="submit"
                >
                  Send Password Reset Link
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
