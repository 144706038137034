import React from "react";
import { Outlet } from "react-router-dom";
import SettingsSidebar from "./SettingsSidebar";
export default function Settings() {
  return (
    <>
      <div className="grid grid-cols-12 grid-flow-row md:grid-flow-col gap-4 md:mx-4">
        <SettingsSidebar />
        <Outlet />
      </div>
    </>
  );
}
