import Axios, { CancelToken, isCancel } from "axios";
import resources from "../data/resources";
const { token } = resources();
const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});
export {CancelToken, isCancel}
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.headers.common["Authorization"] = token;
axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (err) => {
    throw err;
  }
);

export default axios;
