import React, { memo } from "react";
import { useDropzone } from "react-dropzone";
import { BsImageFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { storeIsImagesErrors } from "../../../sources/store/slices/stateSlice";
import useUserPostRequest from "../../../sources/hooks/requests/useUserPostRequest";
const ImagesDropzone = memo(({ setImagesRejected, cancelUpload }) => {
  const { handleUserPostRequest } = useUserPostRequest(cancelUpload)
  const dispatch = useDispatch();
  const {
    getRootProps: getRootImagesProps,
    getInputProps: getInputImagesProps,
    isDragActive: isDragImagesActive,
  } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles, e) => {
      if (acceptedFiles?.length > 0) {
        handleUserPostRequest(e, '/upload/images', { images: acceptedFiles }, 'uploadImages')
      }
      if (rejectedFiles?.length > 0) {
        setImagesRejected((prev) => [...prev, ...rejectedFiles]);
        dispatch(storeIsImagesErrors(true))
      }
    },
    multiple: true,
    accept: {
      "image/png": [".png", ".jpg"],
    },
    maxSize: 1024 * 1000,
  });
  return (
    <>
      <div className="h-full w-full  grid items-center rounded-md">
        <div {...getRootImagesProps()}>
          <input {...getInputImagesProps()} />
          {isDragImagesActive ? (
            <div className="h-40 rounded-md  border border-cyan-300">
              <div className=" h-full grid items-center justify-center text-gray-400">
                <div className="flex flex-col justify-center items-center mx-2 gap-1">
                  <div className="space-x-1">
                    <span>Paste images here</span>
                    <span className="text-red-500">*</span>
                  </div>
                  <div className="flex items-center">
                    <BsImageFill />
                  </div>
                  <span className="text-red-400">
                    Accepts only JPG and PNG images. Max 1MB
                  </span>
                  <span className="text-red-400">
                    First image will be displayed as news post.
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-40 rounded-md  border border-cyan-300">
              <div className=" h-full grid items-center justify-center text-gray-400">
                <div className="flex flex-col justify-center items-center mx-2 gap-1">
                  <div className="space-x-1">
                    <span>Drag and drop images Or click here</span>

                    <span className="text-red-500">*</span>
                  </div>

                  <div className="flex items-center">
                    <BsImageFill />
                  </div>
                  <span className="text-red-400">
                    Accepts only JPG and PNG images. Max 1MB
                  </span>
                  <span className="text-red-400">
                    First image will be displayed as news post.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
);

export default ImagesDropzone;
