import React, { memo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import resources from "../../sources/data/resources";
import usePostRequests from "../../sources/hooks/requests/usePostRequests";
const EmailVerify = memo(({ url = "/email/verify", type = "verify" }) => {
  const navigate = useNavigate()
  const { email } = resources();
  const { handlePostRequest } = usePostRequests();

  useEffect(() => {
    if (email === null) navigate('/')
  }, [email, navigate])
  return (
    <>
      <div className="h-authHeight bg-gray-50 overflow-y-auto">
        <div className=" p-4 ">
          <div className="h-full w-full overflow-y-auto md:w-[75%] lg:w-[65%] xl:w-[50%] mx-auto rounded-md bg-gray-100 ring-2 ring-gray-100 shadow-md">
            <div className=" border-b">
              <div className="text-lg mx-4 py-2">Email Verify</div>
            </div>
            <form onSubmit={(e) => handlePostRequest(e, url, { email: email }, type)}>
              <div className="grid grid-cols-3 space-x-1">
                <div className="col-span-1"></div>
                <div className="col-span-2 py-1 ">
                  <div className="text-red-600">
                    A link has been send to this email address! Please verify?
                  </div>
                  <div className="text-blue-500">
                    If there are no email received, please click Re-Send button
                    again?
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 justify-between mx-4 pt-2 pb-1">
                <div className="col-span-4"></div>
                <input
                  type="email"
                  name="email"
                  value={email}
                  className="ring-1 pl-2 md:py-1 ring-gray-300 rounded-md outline-none col-span-8"
                  disabled
                />
              </div>
              <div className="grid grid-cols-3 space-x-1">
                <div className="col-span-1"></div>
                <div className="col-span-2 py-1 flex gap-2">
                  <Link
                    to="/forget-password"
                    className=" text-blue-600 hover:text-blue-800 "
                  >
                    Forget Your Password?
                  </Link>
                  <Link
                    to="/change-email"
                    className=" text-blue-600 hover:text-blue-800 "
                  >
                    Change Your Email?
                  </Link>
                </div>
              </div>
              <div className="grid grid-cols-3 space-x-1">
                <div className="col-span-1"></div>
                <div className="col-span-2 space-x-4 p-4 flex justify-end">
                  <button
                    className=" ring-1 ring-gray-300 rounded-md py-1 px-2 text-sm md:text-base text-white bg-blue-600 hover:bg-blue-400"
                    type="submit"
                  >
                    Re-Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default EmailVerify;
