import { useCallback, useContext } from "react";
import axios from "../../axios/Axios";
import toast from "react-hot-toast";
import { SocketContext } from "../../context/SocketContext";
export default function usePatchRequests() {
  const socket = useContext(SocketContext)
  const handleUpdateFavouriteResponse = useCallback((res, data, type, setButtonData) => {
    toast.success(res.data.message)
    setButtonData((prev) => prev.map((item) => Object.assign({ news_id: item.news_id, favourite: (item.news_id === data.news_id && item.favourite === false) ? true : (item.news_id === data.news_id && item.favourite === true) ? false : item.favourite, saves: item.saves })))
    if (type === 'updateFavourite') socket?.emit(type, type)
  }, [socket])
  const handleUpdateSavesResponse = useCallback((res, data, type, setButtonData) => {
    toast.success(res.data.message)
    setButtonData((prev) => prev.map((item) => Object.assign({ news_id: item.news_id, favourite: item.favourite, saves: (item.news_id === data.news_id && item.saves === false) ? true : (item.news_id === data.news_id && item.saves === true) ? false : item.saves })))
    if (type === 'updateSaves') socket?.emit(type, type)
  }, [socket])
  const handlePatchRequestResponse = useCallback((res, type, data, setButtonData) => {
    switch (res.data.status + type) {
      case 200 + "updateFavourite":
        handleUpdateFavouriteResponse(res, data, '', setButtonData)
        break;
      case 200 + "updateSaves":
        handleUpdateSavesResponse(res, data, '', setButtonData)
        break;
      case 201 + "updateFavourite":
        handleUpdateFavouriteResponse(res, data, type, setButtonData)
        break;
      case 201 + "updateSaves":
        handleUpdateSavesResponse(res, data, type, setButtonData)
        break;
      case 200 + "storeHistory":
      case 200 + "userActivity":
        console.log(res.data.message);
        break;
      default:
        console.log(res);
        break;
    }
  }, [handleUpdateFavouriteResponse, handleUpdateSavesResponse])
  const handlePatchRequest = useCallback((e, url = "", data = {}, type = "", setButtonData) => {
    e.preventDefault();
    try {
      axios
        .patch(url, data)
        .then((res) => {
          handlePatchRequestResponse(res, type, data, setButtonData)
        })
        .catch((errors) => {
          console.log(errors);
        });
    } catch (error) {
      console.log(error);
    }
  }, [handlePatchRequestResponse]);
  return { handlePatchRequest };
}
