import React from "react";
import { Routes, Route } from "react-router-dom";
import AppRoutes from "./sources/routes/AppRoutes";
export default function App() {
  const { routes } = AppRoutes();
  return (
    <>
      <Routes>
        {routes.map((route, key) => (
          <Route path={route.path} key={key} element={route.component}>
            {route.components.length > 0 &&
              route.components.map((route, key) => (
                <Route key={key} element={route.component}>
                  {route.components.length > 0 &&
                    route.components.map((route, key) => (
                      <Route
                        exact
                        path={route.path}
                        key={key}
                        element={route.component}
                      >
                        {route.components.length > 0 &&
                          route.components.map((route, key) => (
                            <Route
                              exact
                              path={route.path}
                              key={key}
                              element={route.component}
                            ></Route>
                          ))}
                      </Route>
                    ))}
                </Route>
              ))}
          </Route>
        ))}
      </Routes>
    </>
  );
}
