import React, { memo } from "react";
import SupportUs from "./../support/SupportUs";
import AppLogo from "./../logo/AppLogo";
import LogInfo from "./../loginfo/LogInfo";
const SupportTitleLogbar = memo(() => {
  return (
    <>
      <div className="mx-10 my-3 md:py-6 flex justify-between items-center">
        <SupportUs />
        <AppLogo />
        <LogInfo />
      </div>
    </>
  );
});

export default SupportTitleLogbar;
