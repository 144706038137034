import swal from "sweetalert";
export default function SweetAlert(title = "", message = "", icon = "", timer = "", className = "") {
  swal({
    title: title,
    text: message,
    icon: icon,
    timer: timer,
    className: className,
  });
}
