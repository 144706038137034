import React, { memo } from 'react'
import { MdFavorite } from 'react-icons/md';
import usePatchRequests from '../../../sources/hooks/requests/usePatchRequests';

const FavouriteButton = memo(({ item, i, buttonData, setButtonData }) => {
    const { handlePatchRequest } = usePatchRequests()
    return (
        <>
            <button
                type="button"
                onClick={(e) =>
                    handlePatchRequest(e, "/user/update/favourite", { news_id: item.news_id }, "updateFavourite", setButtonData)
                }
                className="w-full flex justify-center items-center"
            >
                <MdFavorite
                    title={`${buttonData[i]?.favourite
                        ? "Remove to favourite list"
                        : "Add to favourite list"
                        }`}
                    className={`${buttonData[i]?.favourite
                        ? "hover:text-black text-red-500"
                        : "hover:text-red-500 text-black"
                        } text-lg`}
                />
            </button>
        </>
    )
})

export default FavouriteButton