import React, { memo, useEffect } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  storeErrors,
  storeSearchInputData,
} from "../../../sources/store/slices/dataSlice";

import { MdClose } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import { FaSearch } from "react-icons/fa";
import useHandleFormData from "../../../sources/functions/useHandleFormData";
const SearchForm = memo(({ selectType, selectSource, selectCategory, loading, type = 'newsSearchInput' }) => {
  const dispatch = useDispatch();
  const { searchInputData, errors } = useSelector((store) => store.data);
  const selects = [
    { name: "type", options: selectType },
    { name: "source", options: selectSource },
    {
      name: "category",
      options: selectCategory,
    },
  ];
  const { handleInputFieldData, handleFiltersFromDate, handleFiltersToDate, handleNewsSearch } = useHandleFormData()

  useEffect(() => {
    var input = document.getElementById("myInput");
    input.addEventListener("keypress", handleNewsSearch);
    return () => {
      input.removeEventListener("keypress", handleNewsSearch);
    };
  }, [handleNewsSearch]);
  return (
    <>
      <form>
        <div className="flex items-center justify-center gap-2">
          <div className="text-black mr-1 font-semibold hidden lg:flex">
            Filter:
          </div>

          {selects.length > 0 &&
            selects.map((select, key) => (
              <div key={key} className="w-32 hidden lg:flex">
                <select
                  name={select.name}
                  onChange={(e) => handleInputFieldData(e, type)}
                  value={
                    select.name === 'type' ? searchInputData.type :
                      select.name === 'source' ? searchInputData.source :
                        select.name === 'category' && searchInputData.category
                  }
                  className="w-full h-7 text-sm text-black px-1 rounded-md outline-none capitalize"
                >
                  <option value="">{select.name}</option>
                  {loading ? (
                    <option>Loading . . .</option>
                  ) : (
                    <>
                      {select.options.length > 0 &&
                        select.options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </>
                  )}
                </select>
              </div>
            ))}
          <div className="w-52 text-black  items-center gap-2 hidden lg:flex">
            <div className="font-semibold">From:</div>
            <DatePicker
              format="dd/MM/yyyy"
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              maxDate={searchInputData.to === "" ? new Date() : new Date(searchInputData.to)}
              minDate={new Date("01 jan 2000")}
              clearIcon={
                <MdClose
                  title="Clear"
                  className=" text-red-600 bg-gray-300/80 shadow rounded-full hover:scale-90"
                />
              }
              calendarIcon={
                <FcCalendar title="Calendar" className="shadow " />
              }
              onChange={handleFiltersFromDate}
              value={searchInputData.from}
              className="bg-white h-7 w-full pr-1.5 pl-0.5 rounded-md  text-sm"
            />
          </div>
          <div className="w-52 text-black items-center gap-2 hidden lg:flex">
            <div className="font-semibold">To:</div>
            <DatePicker
              format="dd/MM/yyyy"
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              maxDate={new Date()}
              minDate={searchInputData.from === "" ? new Date("01 jan 2000") : new Date(searchInputData.from)}
              clearIcon={
                <MdClose
                  title="Clear"
                  className=" text-red-600 bg-gray-300/80 shadow rounded-full hover:scale-90"
                />
              }
              calendarIcon={
                <FcCalendar title="Calendar" className="shadow " />
              }
              onChange={handleFiltersToDate}
              value={searchInputData.to}
              className="bg-white h-7 w-full pr-1.5 pl-0.5 rounded-md  text-sm"
            />
          </div>

          <div className="w-72">
            <div className="flex items-center gap-2">
              <input
                type="text"
                name="search"
                value={searchInputData.search}
                onChange={(e) => handleInputFieldData(e, type)}
                id="myInput"
                placeholder={errors ? errors : "Search . . ."}
                className="w-full pl-2 h-7 text-sm outline-none rounded-md text-black placeholder:text-red-500"
              />
              <button
                type="button"
                id="myBtn"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewsSearch(e);
                }}
                className="cursor-pointer bg-gray-700/80 p-1 rounded-md text-white"
              >
                <FaSearch />
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              dispatch(
                storeSearchInputData({
                  type: "",
                  source: "",
                  category: "",
                  from: "",
                  to: "",
                  search: "",
                  apiKey: process.env.REACT_APP_SEARCH_NEWS_APIKEY,
                })
              );
              dispatch(storeErrors(""));
            }}
            className=" bg-blue-500 hover:bg-blue-600 cursor-pointer text-white px-2 rounded-md"
          >
            Clear
          </div>
        </div>
      </form>
    </>
  );
}
);

export default SearchForm;
