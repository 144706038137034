import React, { memo, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
const BreakingNewsBar = memo(({ url = "/news/breaking", type = "breakingNews" }) => {
  const { handleGetRequest, loading, message, breakingNews } = useGetRequests();
  const headlineData = useMemo(() => {
    return {
      type: "headline",
      apiKey: process.env.REACT_APP_HEADLINE_NEWS_APIKEY,
    }
  }, [])
  useEffect(() => {
    handleGetRequest(url, headlineData, type);
  }, [handleGetRequest, headlineData, type, url]);
  return (
    <>
      <div className="border shadow relative overflow-hidden bg-black text-white py-2">
        <div className="absolute top-0 bottom-0 z-10 flex items-center px-2 md:px-4 bg-red-600 font-bold">
          Breaking News
        </div>
        <div
          onAnimationIteration={() => {
            handleGetRequest(url, headlineData, type);
          }}
          className="px-4 animate-scroll-breakingNews">
          <div className="flex gap-4 whitespace-nowrap">
            {message !== "" ? (
              <div className="">{message}</div>
            ) : (
              <>
                {loading ? (
                  <div className="py-2.5"></div>
                ) : (
                  <>
                    {breakingNews.length > 0 &&
                      breakingNews.map((news, index) => (
                        <div
                          key={index}
                          className="flex gap-x-10 items-center text-sm headline">
                          <Link
                            to={news.url}
                            target="_blank"
                            className="ml-10">
                            {news.title}
                          </Link>
                          <div
                            className={`${index === breakingNews.length - 1
                              ? "py-2.5 "
                              : "border-r-2 border-white py-2.5 "
                              }`}></div>
                        </div>
                      ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
);

export default BreakingNewsBar;
