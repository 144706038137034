import React, { memo } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const SupportUs = memo(() => {
  return (
    <>
      <div className="font-lusitana italic ">
        <div className="hidden md:inline-block md:pb-2">
          <h1 className="font-semibold md:text-base lg:text-lg">
            Support The News App
          </h1>
          <small className="lg:text-base">A Product of OrianSoft</small>
        </div>
        <Link to={process.env.REACT_APP_MOTHER_BASE_URL} target="_blank" className="flex items-center space-x-1 hover:bg-gray-50 border p-1 rounded-md shadow-md">
          <span className="font-semibold text-xs md:text-base ">
            Support Us
          </span>
          <BsArrowUpRight className="text-xs border rounded-full" />
        </Link>
      </div>
    </>
  );
});

export default SupportUs;
