import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import resources from "../data/resources";
import { useDispatch, useSelector } from "react-redux";
import { storeNavigator } from "../store/slices/dataSlice";
export default function NavBarFunstions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screen, setScreen] = useState(false);
  const { pathname } = useLocation();
  const { currentLatestMenu } = resources();
  const { navigator } = useSelector((store) => store.data);
  const fullScreenMode = () => {
    let myScreen = document.documentElement;
    if (screen === false) {
      if (
        myScreen.requestFullscreen ||
        myScreen.msRequestFullscreen ||
        myScreen.mozRequestFullscreen ||
        myScreen.webkitRequestFullscreen
      ) {
        return (
          myScreen.requestFullscreen() ||
          myScreen.msRequestFullscreen() ||
          myScreen.mozRequestFullscreen() ||
          myScreen.webkitRequestFullscreen()
        );
      }
    } else {
      if (screen === true) {
        if (
          document.exitFullscreen ||
          document.msexitFullscreen ||
          document.mozexitFullscreen ||
          document.webkitexitFullscreen
        ) {
          return (
            document.exitFullscreen() ||
            document.msexitFullscreen() ||
            document.mozexitFullscreen() ||
            document.webkitexitFullscreen()
          );
        }
      }
    }
  };
  const fullScreen = () => {
    fullScreenMode();
    setScreen(!screen);
  };
  const handleWindowsInitialLoad = useCallback(() => {
    localStorage.setItem("navigator", false);
    localStorage.removeItem("images");
    localStorage.removeItem("video");
    dispatch(storeNavigator("false"));
    navigate("/");
  }, [dispatch, navigate])
  const handleNavigation = useCallback((index) => {
    if (index === 0) {
      dispatch(storeNavigator("false"));
      localStorage.setItem("navigator", false);
    } else {
      dispatch(storeNavigator("true"));
      localStorage.setItem("navigator", true);
    }
  }, [dispatch])
  const currentLatestMenus = currentLatestMenu.map((currentLatestMenu, index) =>
    Object.assign({
      name: currentLatestMenu.name,
      url:
        index === 0
          ? (currentLatestMenu.url =
            navigator === "false"
              ? pathname === "/"
                ? pathname
                : pathname.replace("-topnews", "")
              : pathname === "/topnews"
                ? pathname.replace("topnews", "")
                : pathname.replace("-topnews", ""))
          : (currentLatestMenu.url =
            navigator === "false"
              ? pathname === "/"
                ? pathname + "topnews"
                : pathname + "-topnews"
              : pathname),
    })
  );
  return { fullScreen, handleWindowsInitialLoad, handleNavigation, currentLatestMenus };
}
