import { useCallback } from "react"
import swal from 'sweetalert';
import useDeleteRequests from "./useDeleteRequests";
import SweetAlert from "../../functions/SweetAlert";
import usePostRequests from "./usePostRequests";

export default function usePreAlertRequest() {
    const { handleDeleteRequest } = useDeleteRequests()
    const { handlePostRequest } = usePostRequests();
    const handleRequest = useCallback((e, url, data, type) => {
        switch (type) {
            case 'deleteUserNews':
            case 'userActivityDelete':
            case 'userHistoryDelete':
                handleDeleteRequest(url, data, type)
                break;
            case 'logout':
                handlePostRequest(e, url, data, type)
                break;
            default:
                console.log('Nothing happnd?');
                break;
        }
    }, [handleDeleteRequest, handlePostRequest])
    const handlePreAlertRequest = useCallback((e, url = '', data = {}, type = '', message = '') => {
        e.preventDefault();
        try {
            swal({
                title: "Are you sure?",
                text: "If Change in Mind? Please Click Cancel Button!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((res) => {
                if (res) {
                    handleRequest(e, url, data, type)
                } else {
                    SweetAlert("Warning", message, "success", null, 'errors-text-color')
                }
            });
        } catch (error) {
            console.log(error);
        }

    }, [handleRequest])

    return { handlePreAlertRequest }
}
