import React from "react";
import Ads from "../../common/ads/Ads";
import TopNewsPost from "./TopNewsPost";
export default function TopNews({ category, type }) {
  return (
    <>
      <div className="grid grid-cols-12 ">
        <div className="lg:col-span-3">
          {[1, 2, 3, 4, 5].map((data, i) => (
            <Ads key={i} />
          ))}
        </div>
        <div className="col-span-12 lg:col-span-6 bg-gray-100 shadow-lg rounded-md mt-1 mx-1">
          <TopNewsPost category={category} type={type} />
        </div>
        <div className="lg:col-span-3">
          {[1, 2, 3, 4, 5].map((data, i) => (
            <Ads key={i} />
          ))}
        </div>
      </div>
    </>
  );
}
