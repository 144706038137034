import React from "react";
import Home from "../../pages/Home";
import NewsLayout from "../../pages/news/NewsLayout";
import CurrentNews from "../../components/news/currentNews/CurrentNews";
import TopNews from "../../components/news/topNews/TopNews";
import SearchNews from "../../components/news/searchNews/SearchNews";
import NewsCardFullView from "./../../components/common/postCards/NewsCardFullView";
import ProfileLayout from "../../pages/profile/ProfileLayout";
import Profile from "../../components/user/personalize/profile/Profile";
import Favourite from "../../components/user/personalize/favourite/Favourite";
import Save from "../../components/user/personalize/save/Save";
import History from "../../components/user/personalize/history/History";
import Activity from "../../components/user/personalize/activity/Activity";
import Settings from "../../components/user/personalize/settings/Settings";
import Account from "../../components/user/personalize/settings/outlet/Account";
import Email from "../../components/user/personalize/settings/outlet/Email";
import Password from "../../components/user/personalize/settings/outlet/Password";
import Privacy from "../../components/user/personalize/settings/outlet/Privacy";
import EmailNotification from "../../components/user/personalize/settings/outlet/EmailNotification";
import UserDetails from "../../components/user/personalize/settings/outlet/UserDetails";
import Register from "../../components/auth/Register";
import Login from "../../components/auth/Login";
import EmailVerify from "../../components/auth/EmailVerify";
import EmailVerified from "../../components/auth/EmailVerified";
import ForgetPassword from "../../components/auth/ForgetPassword";
import ResetPassword from "../../components/auth/ResetPassword";
import Error404 from "../../components/common/errors/Error404";
import AuthLayout from "../../pages/auth/AuthLayout";
import resources from "../data/resources";
import ApiNewsLayout from "../../pages/apiNews/ApiNewsLayout";
import ApiNews from "../../components/news/apiNews/ApiNews";

export default function AppRoutes() {
  const { currentNewsRoutes, topNewsRoutes } = resources();
  const currentNewsComponents = currentNewsRoutes.map((currentNews) =>
    Object.assign({
      path: currentNews.path,
      component: (
        <CurrentNews category={currentNews.category} type={currentNews.type} />
      ),
      components: [],
    })
  );
  const topNewsComponents = topNewsRoutes.map((topNews) =>
    Object.assign({
      path: topNews.path,
      component: <TopNews category={topNews.category} type={topNews.type} />,
      components: [],
    })
  );
  const routes = [
    {
      path: "/",
      component: <Home />,
      components: [
        {
          component: <AuthLayout />,
          components: [
            {
              path: "/register",
              component: <Register />,
              components: [],
            },
            {
              path: "/login",
              component: <Login />,
              components: [],
            },
            {
              path: "/verify",
              component: <EmailVerify />,
              components: [],
            },
            {
              path: "/verified/:token",
              component: <EmailVerified />,
              components: [],
            },
            {
              path: "/forget-password",
              component: <ForgetPassword />,
              components: [],
            },
            {
              path: "/reset/password/:resetEmail/:resetToken",
              component: <ResetPassword />,
              components: [],
            },
          ],
        },
        {
          component: <NewsLayout />,
          components: [
            ...currentNewsComponents,
            ...topNewsComponents,
            {
              path: "/search",
              component: <SearchNews />,
              components: [],
            },
          ],
        },
        {
          component: <ProfileLayout />,
          components: [
            {
              path: "/profile",
              component: <Profile />,
              components: [],
            },
            {
              path: "/favourite",
              component: <Favourite />,
              components: [],
            },
            {
              path: "/save",
              component: <Save />,
              components: [],
            },
            {
              path: "/history",
              component: <History />,
              components: [],
            },
            {
              path: "/activity",
              component: <Activity />,
              components: [],
            },
            {
              path: "",
              component: <Settings />,
              components: [
                {
                  path: "/settings",
                  component: <Account />,
                  components: [],
                },
                {
                  path: "/email/settings",
                  component: <Email />,
                  components: [],
                },
                {
                  path: "/password/settings",
                  component: <Password />,
                  components: [],
                },
                {
                  path: "/privacy/settings",
                  component: <Privacy />,
                  components: [],
                },
                {
                  path: "/email-notificaiton/settings",
                  component: <EmailNotification />,
                  components: [],
                },
                {
                  path: "/user-information/settings",
                  component: <UserDetails />,
                  components: [],
                },
              ],
            },
            {
              path: "/news/:news_id",
              component: <NewsCardFullView />,
              components: [],
            },
            
          ],
        },
        {
          component:<ApiNewsLayout/>,
          components:[
            {
              path: "/api/news/:news_id",
              component: <ApiNews />,
              components: [],
            },
          ]
        }
      ],
    },
    {
      path: "*",
      component: <Error404 />,
      components: [],
    },
  ];
  return { routes };
}
