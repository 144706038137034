import React, { memo, useEffect, useRef, useState } from "react";
import { BsCamera } from "react-icons/bs";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import Image from "./Image";
import SkeletonBar from "../skeleton/SkeletonBar";
import usePostRequests from './../../../sources/hooks/requests/usePostRequests';
import useSocket from "../../../sources/socket/useSocket";

const ProfilePhoto = memo(
  ({ getUrl = "/show/profile", type = "profilePhoto", postUrl = "/update/profile" }) => {
    const [profilePhoto, setProfilePhoto] = useState("");
    const [event, setEvent] = useState("");
    const profileRef = useRef(null);
    const { handleGetRequest, profile, loading } = useGetRequests();
    const { handlePostRequest } = usePostRequests();
    const { socket, update, setUpdate } = useSocket('profilePhoto')
    useEffect(() => {
      socket?.on('profilePhoto', (data) => {
        setUpdate(data)
      })
    }, [setUpdate, socket])
    useEffect(() => {
      const getData = {
        apiKey: process.env.REACT_APP_USER_PROFILE_PHOTO_APIKEY,
      };
      handleGetRequest(getUrl, getData, type);
      return () => {
        setUpdate(null);
      }
    }, [handleGetRequest, getUrl, type, update, setUpdate]);
    const handleUpdateProfile = (e) => {
      setProfilePhoto(e.target.files[0])
      setEvent(e);
    }
    useEffect(() => {
      if (profilePhoto !== "" && event !== "") {
        const postData = {
          profile_photo: profilePhoto,
          apiKey: process.env.REACT_APP_USER_PROFILE_PHOTO_APIKEY,
        };
        handlePostRequest(event, postUrl, postData, type)
      }
      return () => {
        setProfilePhoto("");
        setEvent("");
      }
    }, [event, handlePostRequest, postUrl, profilePhoto, type])
    return (
      <>
        <div className="relative border bg-white border-blue-400 text-center rounded-full overflow-hidden w-36 md:w-40 lg:w-48 h-36 md:h-40 lg:h-48 items-center shadow-md">
          {loading ? (
            <SkeletonBar
              parentClass={"h-full w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          ) : (
            <>
              <Image profile={profile} />
              <div
                onClick={() => profileRef.current.click()}
                className="absolute bottom-0 items-center h-16 w-full opacity-80 bg-gray-400 hover:bg-gray-400 hover:opacity-90 duration-300">
                <label className="cursor-pointer ">
                  <div className="flex flex-col items-center absolute bottom-4 w-full text-white">
                    <BsCamera />
                    <span className="text-sm">Update Profile</span>
                  </div>
                </label>
              </div>
              <input
                type="file"
                name="profile"
                onChange={(e) => handleUpdateProfile(e)}
                ref={profileRef}
                accept=".jpg,.png"
                className="hidden"
              />
            </>
          )}
        </div>
      </>
    );
  }
);

export default ProfilePhoto;
