import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import useGetRequests from "../../../../../../sources/hooks/requests/useGetRequests";
import usePostRequests from "../../../../../../sources/hooks/requests/usePostRequests";
import PrivateInfoFormSkeleton from "../../../../../common/skeleton/PrivateInfoFormSkeleton";
import useHandleFormData from "../../../../../../sources/functions/useHandleFormData";
import useSocket from "../../../../../../sources/socket/useSocket";

const PrivateInfoForm = memo(({ url = "/user", type = "user", postUrl = "/user/private/info/create", postType = "privateInfo" }) => {
  const { userPrivateInfo, errors } = useSelector((store) => store.data);
  const { handleGetRequest, user, message, loading } = useGetRequests();
  const { handleInputFieldData } = useHandleFormData()
  const { socket, update, setUpdate } = useSocket('privateInfo')
  useEffect(() => {
    socket?.on('privateInfo', (data) => {
      setUpdate(data)
    })
  }, [setUpdate, socket])
  const postData = {
    date_of_birth: userPrivateInfo.date_of_birth,
    status: userPrivateInfo.status,
    gender: userPrivateInfo.gender,
    phone_number: userPrivateInfo.phone_number,
    address: userPrivateInfo.address,
    city: userPrivateInfo.city,
    state: userPrivateInfo.state,
    zip_code: userPrivateInfo.zip_code,
    id: user.id,
    apiKey: process.env.REACT_APP_COMMON_APIKEY,
  };
  const { handlePostRequest } = usePostRequests();
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_COMMON_APIKEY,
    };
    handleGetRequest(url, data, type); return () => {
      setUpdate(null)
    }
  }, [handleGetRequest, setUpdate, type, update, url]);
  return (
    <>
      {loading ? (
        <PrivateInfoFormSkeleton />
      ) : (
        <>
          {message ? (
            <div className="w-full h-[50vh] flex justify-center items-center">
              {message}
            </div>
          ) : (
            <form onSubmit={(e) => handlePostRequest(e, postUrl, postData, postType)} className="text-sm">
              <div className="ml-10">
                <div className="p-2 lg:grid lg:grid-cols-12">
                  <div className="col-span-3">
                    Date of Birth:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="date"
                    name="date_of_birth"
                    value={userPrivateInfo.date_of_birth}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    className="col-span-9 outline-none bg-gray-50 pl-2 py-1 border border-yellow-300 w-full h-9"
                  />
                </div>
                <div className="md:grid md:grid-cols-12  ">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">
                    {errors.date_of_birth}
                  </div>
                </div>
                <div className="p-2 lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    Gender:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <select
                    name="gender"
                    value={userPrivateInfo.gender}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    className="col-span-9 outline-none bg-gray-50 pl-2 border border-yellow-300 w-full h-9">
                    <option value>Select Gender</option>
                    <option value="male">Male</option>
                    <option value="femail">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="md:grid md:grid-cols-12  ">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">
                    {errors.gender}
                  </div>
                </div>
                <div className="p-2 lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    Status:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <select
                    name="status"
                    value={userPrivateInfo.status}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    className="col-span-9 outline-none bg-gray-50 pl-2 border border-yellow-300 w-full h-9">
                    <option value>Select Status</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="complicated">Complicated</option>
                  </select>
                </div>
                <div className="md:grid md:grid-cols-12">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">
                    {errors.status}
                  </div>
                </div>
                <div className="p-2 lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    Phone:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="number"
                    name="phone_number"
                    value={userPrivateInfo.phone_number}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    placeholder="Enter Your Phone Number"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                <div className="md:grid md:grid-cols-12  ">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">
                    {errors.phone_number}
                  </div>
                </div>
                <div className="p-2 lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    Address:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="address"
                    name="address"
                    value={userPrivateInfo.address}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    placeholder="Enter Your Address"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                <div className="md:grid md:grid-cols-12  ">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">
                    {errors.address}
                  </div>
                </div>
                <div className="p-2 lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    City:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="city"
                    name="city"
                    value={userPrivateInfo.city}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    rows="2"
                    placeholder="Enter Your City"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                <div className="md:grid md:grid-cols-12  ">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">{errors.city}</div>
                </div>
                <div className="p-2 lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    State:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="state"
                    name="state"
                    value={userPrivateInfo.state}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    rows="4"
                    placeholder="Enter Your State"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                <div className="md:grid md:grid-cols-12  ">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">
                    {errors.state}
                  </div>
                </div>
                <div className="p-2 lg:grid lg:grid-cols-12 ">
                  <div className="col-span-3">
                    Zip:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="zip"
                    name="zip_code"
                    value={userPrivateInfo.zip_code}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    rows="6"
                    placeholder="Enter Your Zip Code"
                    className="col-span-9 outline-none  bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                <div className="md:grid md:grid-cols-12  ">
                  <div className="md:col-span-3"></div>
                  <div className="text-red-500 col-span-9">
                    {errors.zip_code}
                  </div>
                </div>
              </div>

              <div className="col-span-12 text-end mb-4 mx-3">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-gray-50 rounded-md px-2 py-1 cursor-pointer text-md ">
                  {userPrivateInfo.user_id ? "Update" : "Save"}
                </button>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
}
);

export default PrivateInfoForm;
