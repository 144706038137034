import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  logInfo: false,
  sidebarMenu: false,
  isVideo: false,
  isVideoErrors: false,
  isImages: false,
  isImagesErrors: false,
  postLoading: false,
};

const stateSlice = createSlice({
  name: "state",
  initialState: {
    logInfo: initialState.logInfo,
    sidebarMenu: initialState.sidebarMenu,
    isVideo: initialState.isVideo,
    isVideoErrors: initialState.isVideoErrors,
    isImages: initialState.isImages,
    isImagesErrors: initialState.isImagesErrors,
    postLoading: initialState.postLoading,
  },

  reducers: {
    storeLogInfo(state, action) {
      state.logInfo = action.payload;
    },
    storeSidebarMenu(state, action) {
      state.sidebarMenu = action.payload;
    },
    storeIsVideo(state, action) {
      state.isVideo = action.payload;
    },
    storeIsVideoErrors(state, action) {
      state.isVideoErrors = action.payload;
    },
    storeIsImages(state, action) {
      state.isImages = action.payload;
    },
    storeIsImagesErrors(state, action) {
      state.isImagesErrors = action.payload;
    },
    storePostLoading(state, action) {
      state.postLoading = action.payload;
    },
  },
});
export const {
  storeLogInfo,
  storeSidebarMenu,
  storeIsVideo,
  storeIsVideoErrors,
  storeIsImages,
  storeIsImagesErrors,
  storePostLoading,
} = stateSlice.actions;
export default stateSlice.reducer;
