import React from "react";
import resources from "../../../sources/data/resources";
import UserLogInfo from "./UserLogInfo";
import GuestLogInfo from "./GuestLogInfo";
import { useSelector } from "react-redux";
import useMenuToggle from "../../../sources/functions/useMenuToggle";

export default function LogInfo() {
  const { token, logMenus, loggedMenus, userName } = resources();
  const { logInfo } = useSelector((store) => store.state);
  const { logMenuRef } = useMenuToggle()
  return (
    <>
      <div ref={logMenuRef} >
        {token ? (
          <UserLogInfo logInfo={logInfo} loggedMenus={loggedMenus} userName={userName}
          />
        ) : (
          <GuestLogInfo logInfo={logInfo} logMenus={logMenus} />
        )}
      </div>
    </>
  );
}
