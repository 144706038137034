import React from "react";
import { Link } from "react-router-dom";
import usePostRequests from "./../../sources/hooks/requests/usePostRequests";
import { useSelector } from "react-redux";

import useHandleFormData from "../../sources/functions/useHandleFormData";

export default function Login({ url = "/login", type = "login" }) {
  const { loginData, errors } = useSelector((store) => store.data);
  const { handleInputFieldData, handleCheckBoxFieldData } = useHandleFormData()
  const { handlePostRequest } = usePostRequests();
  return (
    <>
      <div className="lg:h-authHeight lg:overflow-y-auto flex justify-center items-center">
        <div className="w-full lg:w-[650px] mx-auto border rounded-md bg-gray-50 shadow-sm px-2">
          <div className="text-lg mx-4 py-2">Login</div>
          <hr />
          <form onSubmit={(e) => handlePostRequest(e, url, loginData, type)}>
            <div className="m-6 mt-3">
              <div className="grid grid-cols-12 justify-between mx-4 pt-6 pb-1">
                <div className="col-span-4">
                  Email <span className="text-red-700 font-bold">*</span>
                </div>
                <input
                  type="email"
                  name="email"
                  value={loginData.email}
                  onChange={(e) => handleInputFieldData(e, 'loginInput')}
                  className="ring-1 pl-2 md:py-1 ring-gray-300 rounded-md outline-none col-span-8"
                  placeholder="Enter your email?"
                />
              </div>
              <div className="md:grid md:grid-cols-12 space-y-1  mx-4">
                <div className="md:col-span-4"></div>
                <div className="text-red-700 w-full md:col-span-8">
                  {errors.email}
                </div>
              </div>
              <div className="grid grid-cols-12 justify-between mx-4 pt-2 pb-1">
                <div className="col-span-4">
                  Password <span className="text-red-700 font-bold">*</span>
                </div>
                <input
                  type="password"
                  name="password"
                  value={loginData.password}
                  onChange={(e) => handleInputFieldData(e, 'loginInput')}
                  autoComplete="off"
                  className="ring-1 pl-2 md:py-1 ring-gray-300 rounded-md outline-none col-span-8"
                  placeholder="Enter your password?"
                />
              </div>
              <div className="md:grid md:grid-cols-12 space-y-1  mx-4">
                <div className="md:col-span-4"></div>
                <div className="text-red-700 w-full md:col-span-8">
                  {errors.password}
                </div>
              </div>
              <div className="grid grid-cols-3 space-x-1">
                <div className="col-span-1"></div>
                <div className="col-span-2 py-1 flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    name="remember"
                    value={loginData.remember}
                    onChange={(e) => handleCheckBoxFieldData(e, 'loginCheckbox')}
                    defaultChecked={loginData.remember ? true : false}
                  />
                  <div className="">Remember Me</div>
                </div>
              </div>
              <div className="grid grid-cols-3 space-x-1">
                <div className="col-span-1"></div>
                <div className="col-span-2 py-1">
                  <Link
                    to="/forget-password"
                    className=" text-blue-700 hover:text-blue-700/80 ">
                    Forgot Your Password?
                  </Link>
                </div>
              </div>
              <div className="m-6 mr-12 flex gap-6 justify-end items-center">
                <div className="text-blue-700 hover:text-blue-700/80 hidden md:inline-block">
                  Don't have any account? Please
                </div>
                <Link
                  className=" ring-1 ring-gray-300 rounded-md py-1 px-2 text-sm md:text-base text-white bg-blue-600 hover:bg-blue-400"
                  to="/register">
                  Register
                </Link>
                <button
                  className=" ring-1 ring-gray-300 rounded-md py-1 px-2 text-sm md:text-base text-white bg-blue-600 hover:bg-blue-400"
                  type="submit">
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
