import React, { memo } from "react";
import UserInfoSkeleton from "../../../../../common/skeleton/UserInfoSkeleton";
const UserInfo = memo(({ user, loading, message }) => {
  return (
    <>
      <div className="shadow-lg border p-4 flex flex-col rounded">
        {loading ? (
          <UserInfoSkeleton />
        ) : (
          <>
            {message ? (
              <div className="h-[335px] w-full flex items-center justify-center">
                {message}
              </div>
            ) : (
              <>
                <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
                  <div className="col-span-2">Name:</div>
                  <span className="bg-gray-100 border col-span-10  mr-6 pl-2 py-1 rounded w-full">
                    {user?.name}
                  </span>
                </div>
                <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
                  <div className="col-span-2">Email:</div>
                  <span className="bg-gray-100 border col-span-10  mr-6 pl-2 py-1 rounded w-full">
                    {user.email}
                  </span>
                </div>
                <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
                  <div className="col-span-2">User Name:</div>
                  <span className="bg-gray-100 border col-span-10  mr-6 pl-2 py-1 rounded w-full">
                    {user?.user_name}
                  </span>
                </div>

                {user.public && (
                  <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
                    <div className="col-span-2">Nick Name:</div>
                    <span className="bg-gray-100 border col-span-10  mr-6 pl-2 py-1 rounded w-full">
                      {user?.public?.nick_name}
                    </span>
                  </div>
                )}
                {user?.private && (
                  <>
                    <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
                      <div className="col-span-2">Mobile:</div>
                      <span className="bg-gray-100 border col-span-10  mr-6 pl-2 py-1 rounded w-full">
                        {user?.private?.phone_number}
                      </span>
                    </div>
                    <div className=" grid xl:grid-flow-col xl:grid-cols-12 items-center border-b pb-3 xl:mt-6">
                      <div className="col-span-2">Address:</div>
                      <div className="bg-gray-100 border col-span-10 space-x-1 mr-6 pl-2 py-1 rounded w-full">
                        <span>{user?.private?.address},</span>
                        <span>{user?.private?.city},</span>
                        <span>{user?.private?.state}-</span>
                        <span>{user?.private?.zip_code}.</span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
});

export default UserInfo;
