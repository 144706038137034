import React, { memo, useEffect, useRef, useState } from "react";
import { BsCamera } from "react-icons/bs";
import useGetRequests from "../../../../../../sources/hooks/requests/useGetRequests";
import { useSelector } from "react-redux";
import SkeletonBar from "../../../../../common/skeleton/SkeletonBar";
import usePostRequests from "../../../../../../sources/hooks/requests/usePostRequests";
import useSocket from "../../../../../../sources/socket/useSocket";
const CoverSection = memo(({ getUrl = "/show/cover", type = "coverPhoto", postUrl = "update/cover" }) => {
  const { errors } = useSelector((store) => store.data);
  const [coverPhoto, setCoverPhoto] = useState("");
  const [event, setEvent] = useState("");
  const coverRef = useRef(null);
  const { handleGetRequest, cover, loading } = useGetRequests();
  const { handlePostRequest } = usePostRequests();
  const { socket, update, setUpdate } = useSocket('coverPhoto')
  useEffect(() => {
    socket?.on('coverPhoto', (data) => {
      setUpdate(data)
    })
  }, [setUpdate, socket])
  useEffect(() => {
    const getData = {
      apiKey: process.env.REACT_APP_USER_COVER_PHOTO_APIKEY,
    };
    handleGetRequest(getUrl, getData, type);
    return () => { setUpdate(null) }
  }, [handleGetRequest, getUrl, type, update, setUpdate]);
  const handleUpdateCover = (e) => {
    setCoverPhoto(e.target.files[0])
    setEvent(e);
  }
  useEffect(() => {
    if (coverPhoto !== "" && event !== "") {
      const postData = {
        cover_photo: coverPhoto,
        apiKey: process.env.REACT_APP_USER_COVER_PHOTO_APIKEY,
      };
      handlePostRequest(event, postUrl, postData, type)
    }
    return () => {
      setCoverPhoto("");
      setEvent("");
    }
  }, [event, handlePostRequest, coverPhoto, postUrl, type])
  return (
    <>
      <div className="flex flex-col justify-center">
        {errors && (
          <div className="text-red-500 font-semibold w-full md:w-[80%] mx-auto text-end">
            {errors.cover_photo}
          </div>
        )}
        <div className="relative rounded border mx-auto w-full md:w-[80%]">
          <div className="w-full h-[450px]">
            {loading ? (
              <div>
                <SkeletonBar
                  parentClass={"h-[450px] w-full bg-gray-300/60"}
                  childClass={"h-full w-full bg-white/20 animate-slide"}
                />
              </div>
            ) : (
              <img src={cover} alt={cover} className="w-full h-full" />
            )}
          </div>
          <div
            onClick={() => coverRef.current.click()}
            className="absolute  top-0 right-0 md:pt-3  rounded-sm rounded-br-none rounded-tr-none w-28 h-10 md:h-14 opacity-80 bg-gray-300 hover:bg-gray-400 hover:opacity-90 duration-300">
            <label className="cursor-pointer text-white">
              <div className="flex flex-col items-center ">
                <BsCamera />
                <span className="text-sm">Update Cover</span>
              </div>
            </label>
          </div>
        </div>
        <input
          type="file"
          name="cover"
          onChange={(e) => handleUpdateCover(e)}
          ref={coverRef}
          accept=".jpg,.png"
          className="hidden"
        />
      </div>
    </>
  );
}
);

export default CoverSection;
