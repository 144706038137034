import React, { memo, useState } from "react";
import { CiSaveDown2 } from "react-icons/ci";
import { FaAngleRight } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { GiSelfLove } from "react-icons/gi";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { ImProfile } from "react-icons/im";
import { RiUserSettingsLine } from "react-icons/ri";
import { RxActivityLog } from "react-icons/rx";
import { VscHistory } from "react-icons/vsc";
import { Link } from "react-router-dom";

const SidebarUserMenus = memo(() => {
  const [subMenu1, setSubMenu1] = useState(true);
  const [subMenu2, setSubMenu2] = useState(true);
  return (
    <>
      <div className="h-[70vh] overflow-y-auto scrollbar-thumb-inherit scroll-smooth bg-white py-4 mx-2 mt-2 rounded-md">
        <div className="text-center p-2 text-black rounded-md space-y-2">
          <div className="bg-white p-1 border rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
            <div
              className="flex items-center justify-center space-x-2"
              onClick={() => setSubMenu1(!subMenu1)}
            >
              <HiOutlineUserCircle className="text-xl hover:text-white" />
              <span>User</span>
              <FaAngleRight className={` ${subMenu1 ? "" : "rotate-90"} `} />
            </div>
          </div>
          <div className={`${subMenu1 ? "hidden" : "inline-block"} space-y-2 `}>
            <div className="mx-2 md:mx-4 px-2 bg-white p-1 rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
              <Link to="/favourite">
                <div className="flex items-center justify-center space-x-2">
                  <GiSelfLove className="text-xl hover:text-white" />
                  <h1 className="">Favourite</h1>
                </div>
              </Link>
            </div>
            <div className="mx-2 md:mx-4 px-2 bg-white p-1 rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
              <Link to="/history">
                <div className="flex items-center justify-center space-x-2">
                  <VscHistory className="text-xl hover:text-white" />
                  <h1 className="">History</h1>
                </div>
              </Link>
            </div>
            <div className="mx-2 md:mx-4 px-2 bg-white p-1 rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
              <Link to="/save">
                <div className="flex items-center justify-center space-x-2">
                  <CiSaveDown2 className="text-xl hover:text-white" />
                  <h1 className="">Save</h1>
                </div>
              </Link>
            </div>
          </div>
          <div className="bg-white p-1 border rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
            <div
              className="flex items-center justify-center space-x-2"
              onClick={() => setSubMenu2(!subMenu2)}
            >
              <RiUserSettingsLine className="text-xl hover:text-white" />
              <span>Settings</span>
              <FaAngleRight className={` ${subMenu2 ? "" : "rotate-90"} `} />
            </div>
          </div>
          <div className={`${subMenu2 ? "hidden" : "inline-block"} space-y-2 `}>
            <div className="mx-2 md:mx-4 px-2 bg-white p-1 rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
              <Link to="/profile">
                <div className="flex items-center justify-center space-x-2">
                  <ImProfile className="text-xl hover:text-white" />
                  <h1 className="">Profile</h1>
                </div>
              </Link>
            </div>
            <div className="mx-2 md:mx-4 px-2 bg-white p-1 rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
              <Link to="/activity">
                <div className="flex items-center justify-center space-x-2">
                  <RxActivityLog className="text-xl hover:text-white" />
                  <h1 className="">Activity</h1>
                </div>
              </Link>
            </div>
            <div className="mx-2 md:mx-4 px-2 bg-white p-1 rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
              <Link to="/settings">
                <div className="flex items-center justify-center space-x-2">
                  <FiSettings className="text-xl hover:text-white" />
                  <h1 className="">Setting</h1>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SidebarUserMenus;
