import React, { memo } from "react";

const SkeletonBar = memo(({ parentClass, childClass }) => {
  return (
    <div className={`overflow-hidden flex items-center ${parentClass}`}>
      <div className={`${childClass}`}></div>
    </div>
  );
});

export default SkeletonBar;
