import React, { memo, useCallback } from "react";
import { FaShare } from "react-icons/fa";
import { ImEye } from "react-icons/im";
import moment from "moment";
import { BiSolidTimeFive } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeShareNewsId, storeNewsShareModal } from "../../../sources/store/slices/modalSlice";
import usePatchRequests from "../../../sources/hooks/requests/usePatchRequests";
import usePutRequests from "../../../sources/hooks/requests/usePutRequests";
import resources from "../../../sources/data/resources";
import useButtonToggle from "../../../sources/functions/useButtonToggle";
import FavouriteButton from "../bottons/FavouriteButton";
import SavesButton from "../bottons/SavesButton";
const NewsCard = memo(({ news, lastNewsElementRef, buttonData, setButtonData }) => {
  const dispatch = useDispatch()
  const { token } = resources();
  const { handlePatchRequest } = usePatchRequests()
  const { handlePutRequest } = usePutRequests()
  const { handleButtonToggle, threeDotClick, buttonRef } = useButtonToggle()
  const handleStoreHistory = useCallback((e, item, setButtonData) => {
    handlePutRequest("/update/news/views", { news_id: item.news_id }, "updateNewsView");
    handlePatchRequest(e, "/user/store/history", { news_id: item.news_id }, "storeHistory", setButtonData)
    window.open(item.url,'_blank')
  }, [handlePatchRequest, handlePutRequest])
  return (
    <>
      {news.length > 0 &&
        news.map((item, i) => (
          <div ref={news.length === i + 1 ? lastNewsElementRef : null} key={i} className="p-2 bg-gray-50  shadow-md rounded-md ">
            <div className="flex justify-between gap-6 pb-2">
              <div className="py-1 font-serif text-justify">{item.title}</div>
              <div ref={(el) => buttonRef.current[i] = el} className="relative m-2  ">
                <button
                  type="button"
                  className="bg-gray-300/80 hover:bg-gray-400 hover:text-white duration-300 px-2 py-1 rounded-sm"
                  onClick={() => handleButtonToggle(i)}
                >
                  <BsThreeDots className="text-lg" />
                </button>
                {threeDotClick.value && threeDotClick.index === i && (
                  <>
                    {token ? (
                      <div className="absolute top-6 right-8 flex items-center gap-2 bg-white p-4 border shadow-md rounded-md">
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(storeNewsShareModal(true))
                            dispatch(storeShareNewsId(item.news_id))
                          }}
                          className="flex items-center justify-center "
                        >
                          <FaShare
                            title="Share"
                            className="text-lg hover:text-red-500"
                          />
                        </button>
                        <FavouriteButton item={item} i={i} buttonData={buttonData} setButtonData={setButtonData} />
                        <SavesButton item={item} i={i} buttonData={buttonData} setButtonData={setButtonData} />
                      </div>
                    ) : (
                      <div className="absolute top-6 right-8 flex items-center gap-2 bg-white p-4 border shadow-md rounded-md">
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(storeNewsShareModal(true))
                            dispatch(storeShareNewsId(item.news_id))
                          }}
                          className="flex gap-1 items-center justify-center hover:font-semibold hover:text-red-500"
                        >
                          <FaShare title="Share" className="text-lg " />
                          <span className="mb-1">Share</span>
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex gap-1 justify-end items-center pb-2 px-2 h-5">
              <BiSolidTimeFive className="mt-px" />
              <small className="">
                {moment(item.published_at).format("Do, MMM YYYY")}
              </small>
            </div>
            <div onClick={(e) => handleStoreHistory(e,item,setButtonData)} className="px-2 cursor-pointer">
              <img
                src={item.image ? item.image : "/storage/default/news.png"}
                alt={item.image ? item.image : "news.png"}
                className="w-full h-[400px] rounded-md shadow-md"
              />
            </div>
            <div className="p-2 text-justify description">
              {item?.description !== null && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      item?.user_id !== null
                        ? item?.description.substr(0, 280)
                        : item?.description.split(" ").reverse().slice(2).reverse().join(" ").substr(0, 280)
                    ),
                  }}
                />
              )}
              <Link to={item.url} target="_blank" className="ml-1 text-blue-300 hover:text-blue-500"
              >
                <small className="underline">
                  Read more ....
                </small>
              </Link>
            </div>
            <div className="flex justify-between items-center h-14">
              <div className="flex items-center">
                <img
                  src={item?.profile ? "/storage/uploads/profile/" + item?.profile?.profile_photo : item?.image ? item?.image : "/storage/default/news.png"}
                  alt={item?.profile ? item?.profile?.profile_photo : item?.image ? item?.image : "news.png"}
                  className="h-10 w-10 rounded-full border mr-2"
                />
                <small>{item.author}</small>
              </div>
              <small className="flex items-center gap-1 p-4">
                <span>{item.views}</span>
                <ImEye />
                <span className="font-bold">,</span>
                <span>{item.share}</span>
                <FaShare />
              </small>
            </div>
          </div>
        ))}
    </>
  );
});

export default NewsCard;
