import { useCallback, useState } from "react";
import axios from "../../axios/Axios";
import { storeUserPrivateInfo, storeUserPublicInfo } from "../../store/slices/dataSlice";
import { useDispatch } from "react-redux";

export default function useGetRequests(cancel) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [breakingNews, setBreakingNews] = useState([]);
    const [buttonData, setButtonData] = useState([]);
    const [currentNews, setCurrentNews] = useState([]);
    const [topNews, setTopNews] = useState([]);
    const [selectType, setSelectType] = useState([]);
    const [selectSource, setSelectSource] = useState([]);
    const [selectCategory, setSelectCategory] = useState([]);
    const [selectLanguage, setSelectLanguage] = useState([]);
    const [searchNews, setSearchNews] = useState([]);
    const [apiNews, setApiNews] = useState([]);
    const [contents, setContents] = useState("");
    const [cover, setCover] = useState("");
    const [profile, setProfile] = useState("");
    const [userNews, setUserNews] = useState("");
    const [userSingleNews, setUserSingleNews] = useState("");
    const [favouriteNews, setFavouriteNews] = useState("");
    const [saveNews, setSaveNews] = useState("");
    const [activityNews, setActivityNews] = useState("");
    const [historyNews, setHistoryNews] = useState("");
    const [user, setUser] = useState("");

    const handleBreakingNewsResponse = useCallback((res) => {
        setBreakingNews(res.data.news);
    }, []);
    const handleCurrentNewsResponse = useCallback((res) => {
        setCurrentNews((prev) => [...new Set([...prev, ...res.data.news])]);
        setButtonData((prev) => [...prev, ...res.data.news.map((item) => Object.assign({ news_id: item.news_id, favourite: item?.favourite !== null ? true : false, saves: item?.saves !== null ? true : false }))])
    }, []);
    const handleTopNewsResponse = useCallback((res) => {
        setTopNews((prev) => [...new Set([...prev, ...res.data.news])]);
        setButtonData((prev) => [...prev, ...res.data.news.map((item) => Object.assign({ news_id: item.news_id, favourite: item?.favourite !== null ? true : false, saves: item?.saves !== null ? true : false }))])
    }, []);
    const handleSearchFiltersResponse = useCallback((res) => {
        setSelectType([...new Set(res.data.type)]);
        setSelectSource([...new Set(res.data.source)]);
        setSelectCategory([...new Set(res.data.category)]);
    }, []);
    const handleSearchNewsResponse = useCallback((res) => {
        setSearchNews((prev) => [...new Set([...prev, ...res.data.news])]);
        setButtonData((prev) => [...prev, ...res.data.news.map((item) => Object.assign({ news_id: item.news_id, favourite: item?.favourite !== null ? true : false, saves: item?.saves !== null ? true : false }))])
    }, []);
    const handleUserCoverPhotoResponse = useCallback((res) => {
        setCover(res.data.cover);
    }, []);
    const handleUserProfilePhotoResponse = useCallback((res) => {
        setProfile(res.data.profile);
    }, []);
    const handleUserNewsFormFiltersResponse = useCallback((res) => {
        setSelectType([...new Set(res.data.type)]);
        setSelectCategory([...new Set(res.data.category)]);
        setSelectLanguage([...new Set(res.data.language)]);
    }, []);
    const handleUserNewsResponse = useCallback((res) => {
        setUserNews(res.data.news);
    }, []);
    const handleApiNewsResponse = useCallback((res) => {
        setApiNews(res.data.news);
        setButtonData([{ news_id: res.data.news.news_id, favourite: res.data.news?.favourite !== null ? true : false, saves: res.data.news?.saves !== null ? true : false }])
    }, []);
    const handleUserFavouriteNewsResponse = useCallback((res) => {
        setFavouriteNews(res.data.favourites);
        setButtonData((prev) => [...prev, ...res.data.favourites.map((item) => Object.assign({ news_id: item.news_id, favourite: item?.favourite !== null ? true : false, saves: item?.saves !== null ? true : false }))])
    }, []);
    const handleUserSaveNewsResponse = useCallback((res) => {
        setSaveNews(res.data.saves);
        setButtonData((prev) => [...prev, ...res.data.saves.map((item) => Object.assign({ news_id: item.news_id, favourite: item?.favourite !== null ? true : false, saves: item?.saves !== null ? true : false }))])
    }, []);
    const handleUserHistoryNewsResponse = useCallback((res) => {
        setHistoryNews(res.data.histories);
    }, []);
    const handleUserActivityNewsResponse = useCallback((res) => {
        setActivityNews(res.data.activities);
    }, []);
    const handleGetNewsContentResponse = useCallback((res) => {
        setContents(res.data.contents);
    }, []);
    const handleUserResponse = useCallback(
        (res) => {
            setUser(res.data.user);
            dispatch(storeUserPublicInfo(res.data.user.public ? res.data.user.public : { nick_name: "", occupation: "", biography: "", description: "", user_id: "", }));
            dispatch(storeUserPrivateInfo(res.data.user.private ? res.data.user.private : { date_of_birth: "", status: "", gender: "", phone_number: "", address: "", city: "", state: "", zip_code: "", user_id: "", }));
        },
        [dispatch]
    );
    const handleUserSingleNewsResponse = useCallback((res) => {
        setUserSingleNews(res.data.news);
    }, []);
    const handleErrorResponse = useCallback((res) => {
        setMessage(res.data.message);
    }, []);
    const handleGetResponse = useCallback(
        (res, type) => {
            switch (res.data.status + type) {
                case 200 + "breakingNews":
                    handleBreakingNewsResponse(res);
                    break;
                case 200 + "currentNews":
                case 200 + "currentNewsScroll":
                    handleCurrentNewsResponse(res);
                    break;
                case 200 + "topNews":
                case 200 + "topNewsScroll":
                    handleTopNewsResponse(res);
                    break;
                case 200 + "searchFilters":
                    handleSearchFiltersResponse(res);
                    break;
                case 200 + "searchNews":
                case 200 + "searchNewsScroll":
                    handleSearchNewsResponse(res);
                    break;
                case 200 + "coverPhoto":
                case 404 + "coverPhoto":
                    handleUserCoverPhotoResponse(res);
                    break;
                case 200 + "profilePhoto":
                case 404 + "profilePhoto":
                    handleUserProfilePhotoResponse(res);
                    break;
                case 200 + "userNewsFormFilters":
                    handleUserNewsFormFiltersResponse(res);
                    break;
                case 200 + "userNews":
                    handleUserNewsResponse(res);
                    break;
                case 200 + "apiNews":
                    handleApiNewsResponse(res);
                    break;
                case 200 + "favouriteNews":
                    handleUserFavouriteNewsResponse(res);
                    break;
                case 200 + "saveNews":
                    handleUserSaveNewsResponse(res);
                    break;
                case 200 + "historyNews":
                    handleUserHistoryNewsResponse(res);
                    break;
                case 200 + "activityNews":
                    handleUserActivityNewsResponse(res);
                    break;
                case 200 + "user":
                    handleUserResponse(res);
                    break;
                case 200 + "userSingleNews":
                    handleUserSingleNewsResponse(res);
                    break;
                case 200 + "getNewsContent":
                    handleGetNewsContentResponse(res);
                    break;
                case 404 + "breakingNews":
                case 403 + "breakingNews":
                case 404 + "currentNews":
                case 403 + "currentNews":
                case 404 + "topNews":
                case 403 + "topNews":
                case 404 + "searchFilters":
                case 403 + "searchFilters":
                case 404 + "searchNews":
                case 403 + "searchNews":
                case 403 + "coverPhoto":
                case 403 + "profilePhoto":
                case 404 + "userNewsFormFilters":
                case 403 + "userNewsFormFilters":
                case 404 + "userNews":
                case 403 + "userNews":
                case 404 + "user":
                case 403 + "user":
                case 404 + "favouriteNews":
                case 403 + "favouriteNews":
                case 404 + "saveNews":
                case 403 + "saveNews":
                case 404 + "historyNews":
                case 403 + "historyNews":
                case 404 + "activityNews":
                case 403 + "activityNews":
                    handleErrorResponse(res);
                    break;
                default:
                    console.log(res);
                    break;
            }
        },
        [handleApiNewsResponse, handleBreakingNewsResponse, handleCurrentNewsResponse, handleErrorResponse, handleGetNewsContentResponse, handleSearchFiltersResponse, handleSearchNewsResponse, handleTopNewsResponse, handleUserActivityNewsResponse, handleUserCoverPhotoResponse, handleUserFavouriteNewsResponse, handleUserHistoryNewsResponse, handleUserNewsFormFiltersResponse, handleUserNewsResponse, handleUserProfilePhotoResponse, handleUserResponse, handleUserSaveNewsResponse, handleUserSingleNewsResponse]
    );
    const handleGetRequest = useCallback((url = '', data = {}, type = '') => {
        if (type === "currentNews") {
            setLoading(true);
            setCurrentNews([]);
            setButtonData([])
            window.scrollTo(0, 260)
        }
        if (type === "currentNewsScroll") {
            setLoading(true);
        }
        if (type === "topNews") {
            setLoading(true);
            setTopNews([]);
            setButtonData([])
            window.scrollTo(0, 260)
        }
        if (type === "topNewsScroll") {
            setLoading(true);
        }
        if (type === "searchFilters") {
            setLoading(true);
            setSelectType([]);
            setSelectSource([]);
            setSelectCategory([]);
        }
        if (type === "searchNews") {
            setLoading(true);
            setSearchNews([]);
            setButtonData([])
            window.scrollTo(0, 260)
        }
        if (type === "searchNewsScroll") {
            setLoading(true);
        }
        if (type === "coverPhoto") {
            setLoading(true);
            setCover([]);
        }
        if (type === "profilePhoto") {
            setLoading(true);
            setProfile([]);
        }
        if (type === "user") {
            setLoading(true);
            setUser([]);
        }
        if (type === "userNews") {
            setLoading(true);
            setUserNews([]);
        }
        if (type === "favouriteNews") {
            setLoading(true);
            setFavouriteNews([]);
            setButtonData([])
        }
        if (type === "saveNews") {
            setLoading(true);
            setSaveNews([]);
            setButtonData([])
        }
        if (type === "historyNews") {
            setLoading(true);
            setHistoryNews([]);
        }
        if (type === "activityNews") {
            setLoading(true);
            setActivityNews([]);
        }
        if (type === "getNewsContent") {
            setLoading(true);
            setContents([]);
        }
        if (type === "apiNews") {
            setLoading(true);
            setApiNews([]);
        }

        try {
            axios
                .get(url, { params: data })
                .then((res) => {
                    handleGetResponse(res, type);
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                })
                .catch((errors) => {
                    if (errors.response.status === 500) setMessage("Server not found! Network Error?");
                    if (errors.response.status === 401) setMessage("Unauthorized Access! Please authorize?");

                });
        } catch (error) {
            console.log(error);
        }
    }, [handleGetResponse]);
    const handleInfinityScroll = useCallback((observer, node, url, data, type) => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                handleGetRequest(url, data, type);
            }
        })
        if (node) observer.current.observe(node)
    }, [handleGetRequest, loading])
    return { handleGetRequest, handleInfinityScroll, loading, setLoading, buttonData, setButtonData, message, breakingNews, currentNews, topNews, selectType, selectSource, selectCategory, selectLanguage, searchNews, apiNews, cover, profile, userNews, user, favouriteNews, saveNews, activityNews, historyNews, userSingleNews, contents };
}
