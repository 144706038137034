import { useCallback, useContext } from "react";
import axios from "../../axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import { storeErrors, storeImages, storeVideo } from "../../store/slices/dataSlice";
import { storeIsImages, storeIsVideo } from "../../store/slices/stateSlice";
import toast from "react-hot-toast";
import resources from "../../data/resources";
import SweetAlert from './../../functions/SweetAlert';
import { SocketContext } from "../../context/SocketContext";

export default function useDeleteRequests() {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const { images, video } = useSelector((store) => store.data);
  const { localImages } = resources()
  const handleResetImagesField = useCallback(() => {
    localStorage.removeItem('images')
    dispatch(storeImages([]))
    dispatch(storeIsImages(false));
    dispatch(storeErrors(''));
  }, [dispatch])
  const handleDeleteImageResponse = useCallback((res) => {
    dispatch(storeImages(images.filter((images) => images?.name !== res?.data?.image)));
    localStorage.setItem('images', JSON.stringify({ images: [...localImages?.images.filter((image) => image !== res.data.image)] }))
    if (images?.length <= 1) handleResetImagesField()
    toast.success(res.data.message)
  }, [dispatch, handleResetImagesField, images, localImages?.images])
  const handleDeleteUserNewsResponse = useCallback((res) => {
    SweetAlert("Success", res.data.message, "success")
    socket?.emit('userNewsPost', 'userNewsPost')
  }, [socket])
  const handleResetVideoField = useCallback(() => {
    if (video?.length <= 1) {
      localStorage.removeItem('video');
      dispatch(storeVideo([]));
      dispatch(storeIsVideo(false));
      dispatch(storeErrors(""));
    }
  }, [dispatch, video?.length])
  const handleDeleteVideoResponse = useCallback((res) => {
    dispatch(storeVideo([video.filter((video) => video.name !== res.data.video)]));
    handleResetVideoField();
    toast.success(res.data.message);
  }, [dispatch, handleResetVideoField, video])
  const handleResetResponseField = useCallback((res, type) => {
    if (type === 'deleteVideo') handleResetVideoField();
    if (type === 'deleteImage') handleResetImagesField();
    console.log(res.data.message);
  }, [handleResetImagesField, handleResetVideoField])
  const handleUserPersonalizeDeleteResponse = useCallback((res, type) => {
    SweetAlert("Success", res.data.message, "success", 2000, "");
    if (type === 'userHistoryDelete') socket?.emit(type, type);
    if (type === 'userActivityDelete') socket?.emit(type, type);
  }, [socket])

  const handleDeleteResponse = useCallback((res, type) => {
    switch (res.data.status + type) {
      case 200 + 'deleteVideo':
        handleDeleteVideoResponse(res)
        break;
      case 200 + 'deleteImage':
        handleDeleteImageResponse(res)
        break;
      case 200 + 'deleteUserNews':
        handleDeleteUserNewsResponse(res)
        break;
      case 404 + 'deleteImage':
      case 404 + 'deleteVideo':
        handleResetResponseField(res, type)
        break;
      case 200 + 'userHistoryDelete':
      case 200 + 'userActivityDelete':
        handleUserPersonalizeDeleteResponse(res, type)
        break;
      case 200 + 'imagesVideoDelete':
        console.log(res.data.message);
        break;
      default:
        console.log(res);
        break;
    }
  }, [handleDeleteImageResponse, handleDeleteUserNewsResponse, handleDeleteVideoResponse, handleResetResponseField, handleUserPersonalizeDeleteResponse])

  const handleDeleteRequest = useCallback((url = "", data = {}, type = "") => {
    try {
      axios
        .delete(url, { params: data })
        .then((res) => {
          handleDeleteResponse(res, type)
        })
        .catch((errors) => {
          console.log(errors);
        });
    } catch (error) {
      console.log(error);
    }
  }, [handleDeleteResponse]);
  return { handleDeleteRequest };
}
