import React, { memo } from "react";
import MenuButton from "./../menu/MenuButton";
import Menus from "./../menu/Menus";
import Sidebar from "./../sidebar/Sidebar";
import useMenuToggle from "../../../sources/functions/useMenuToggle";
const MenuBar = memo(() => {
  const { sideMenuRef } = useMenuToggle()
  return (
    <>
      <div className="flex justify-center items-center border relative bg-cyan-400 py-3 px-4 gap-4">
        <div className="order-last w-full px-2 flex flex-wrap justify-evenly md:justify-around xl:justify-start gap-3 xl:gap-6 2xl:gap-20">
          <Menus />
        </div>
        <div ref={sideMenuRef} className="order-first">
          <MenuButton />
          <Sidebar />
        </div>
      </div>
    </>
  );
});

export default MenuBar;
