import React, { memo } from "react";
import { FaRegUser } from "react-icons/fa";
import GuestLogMenu from "./guest/GuestLogMenu";
import { storeLogInfo } from "../../../sources/store/slices/stateSlice";
import { useDispatch } from "react-redux";
const GuestLogInfo = memo(({ logInfo, logMenus }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="relative  h-10 w-10 ml-10"
      >
        <div onClick={() => dispatch(storeLogInfo(!logInfo))}
          className="h-full w-full flex items-center justify-center cursor-pointer bg-gray-300 rounded-full hover:bg-gray-600 hover:text-white duration-300 "
          title="User"
        >
          <FaRegUser />
        </div>
        {logInfo && (
          <div className="absolute z-50 top-11 right-5 gap-2 flex flex-col text-sm md:text-base  w-52 lg:w-60   p-4 border bg-white shadow-md rounded-md">
            {logMenus.map((logMenu, index) => (
              <GuestLogMenu key={index} logMenu={logMenu} />
            ))}
          </div>
        )}
      </div>
    </>
  );
});

export default GuestLogInfo;
