import { useCallback } from "react";
import axios from "../../axios/Axios";
import { toast } from 'react-hot-toast';
export default function usePutRequests() {
  const handlePutRequestResponse = useCallback((res, type) => {
    switch (res.data.status + type) {
      case 200 + "updateNewsView":
      case 200 + "userStoreActivity":
        console.log(res.data.message);
        break;
      case 200 + "newsShareUpdate":
        toast.success(res.data.message)
        break;
      default:
        console.log(res);
        break;
    }
  }, [])
  const handlePutRequest = useCallback(( url = '', data = {}, type = '') => {
    try {
      axios
        .put(url, data)
        .then((res) => {
          handlePutRequestResponse(res, type)
        })
        .catch((errors) => {
          console.log(errors);
        });
    } catch (error) {
      console.log(error);
    }
  }, [handlePutRequestResponse]);
  return { handlePutRequest };
}
