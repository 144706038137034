import React, { useEffect } from "react";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import SearchForm from "../search bar/SearchForm";
export default function SearchBar({ url = "/search/filters", type = "searchFilters" }) {

  const { handleGetRequest, loading, selectType, selectSource, selectCategory } = useGetRequests();
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_SEARCH_FILTERS_APIKEY,
    };
    handleGetRequest(url, data, type);
  }, [handleGetRequest, type, url]);
  return (
    <>
      <div className=" sm:top-[85px] md:top-12 lg:top-[48px] ">
        <div className=" bg-gray-200 py-3 px-4">
          <SearchForm
            loading={loading}
            selectType={selectType}
            selectSource={selectSource}
            selectCategory={selectCategory}
          />
        </div>
      </div>
    </>
  );
}
