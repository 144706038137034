import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import Title from "../../common/title/Title";
import VideoViewCard from "../../common/dropzone/views/VideoViewCard";
import VideoDropzone from "../../common/dropzone/VideoDropzone";
import ImagesDropzone from "../../common/dropzone/ImagesDropzone";
import ImagesViewCard from "../../common/dropzone/views/ImagesViewCard";
import ProgressBar from "../../common/progress/ProgressBar";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import JoditEditorConfig from "../../../sources/functions/JoditEditorConfig";
import { useDispatch, useSelector } from "react-redux";
import { storeImages, storeUserNewsFormData, storeVideo } from "../../../sources/store/slices/dataSlice";
import moment from "moment";
import useUserPostRequest from "../../../sources/hooks/requests/useUserPostRequest";
import { storeIsImages, storeIsVideo } from "../../../sources/store/slices/stateSlice";
import useHandleFormData from "../../../sources/functions/useHandleFormData";
const NewsForm = memo(
  ({ selectType, selectCategory, selectLanguage, newsUrl = "/user/single/news", newsType = "userSingleNews" }) => {
    const dispatch = useDispatch();
    const cancelUpload = useRef(null);
    const [videoRejected, setVideoRejected] = useState([]);
    const [imagesRejected, setImagesRejected] = useState([]);
    const { newsId } = useSelector((store) => store.modal)
    const { descriptionConfig, contentsConfig } = JoditEditorConfig();
    const { userNewsFormData, errors, images, video, progress } = useSelector((store) => store.data);
    const { isVideo, postLoading } = useSelector((store) => store.state);
    const postUrl = newsId === "" ? "/news/publish" : "/user/published/news/update"
    const postType = newsId === "" ? "userNewsPost" : "userNewsPostUpdate"
    const { handleGetRequest, userSingleNews, loading } = useGetRequests();
    useEffect(() => {
      handleGetRequest(newsUrl, { news_id: newsId }, newsType);
    }, [handleGetRequest, newsId, newsType, newsUrl]);
    const postData = Object.assign({ news_id: newsId !== "" ? newsId : "", ...userNewsFormData, images: [...images.map((image) => image.name)] ?? [], video: [...video.map((video) => video.name)] ?? [], published_at: moment() })
    const { handleUserPostRequest, handleResetForm } = useUserPostRequest(cancelUpload)
    const selects = [
      { name: "type", options: selectType },
      {
        name: "category",
        options: selectCategory,
      },
      { name: "language", options: selectLanguage },
    ];
    const { handleInputFieldData, handleCheckBoxFieldData } = useHandleFormData()
    useEffect(() => {
      if (userSingleNews?.images) {
        dispatch(storeImages([...userSingleNews?.images.map((image) => Object.assign({
          name: image.name,
          image_url: image.image_url,
        }))]));
        dispatch(storeIsImages(true));
      }
      if (userSingleNews?.video) {
        dispatch(storeVideo([Object.assign({
          name: userSingleNews?.video?.name,
          video_url: userSingleNews?.video?.video_url,
        })]))
        dispatch(storeIsVideo(true))
      }
      dispatch(storeUserNewsFormData({
        title: userSingleNews?.title ?? "",
        type: userSingleNews?.type ?? "",
        category: userSingleNews?.category ?? "",
        language: userSingleNews?.language ?? "",
        description: userSingleNews?.description ?? "",
        content: userSingleNews?.content?.content ?? "",
        agree: false,
      }))
    }, [dispatch, userSingleNews])

    useEffect(() => {
      window.addEventListener("load", () => {
        handleResetForm("reset")
      });
      return () => window.removeEventListener("load", () => { });
    }, [handleResetForm]);

    const cancelPost = useCallback((cancelUpload) => {
      if (cancelUpload.current) {
        cancelUpload.current("You has been cancel this upload.");
      }
    }, []);
    return (
      <>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleUserPostRequest(e, postUrl, postData, postType);
        }} >
          <div className="flex flex-col gap-2 ">
            <div className="mb-6">
              <Title title="Publish Your News" />
            </div>
            <div className="grid grid-cols-9 gap-2 items-center">
              <input
                type="text"
                name="title"
                value={userNewsFormData.title}
                onChange={(e) => handleInputFieldData(e, 'userNewsInput')}
                className="col-span-9 h-8 bg-gray-300/10 outline-none rounded-md pl-2 pb-1 w-full border border-cyan-300 placeholder:text-red-500 placeholder:text-sm"
                placeholder="Enter News Title? *"
              />
              <div className="col-span-9 text-red-600">{errors.title}</div>
            </div>
            <div className="grid grid-cols-9 gap-2 items-center">
              {selects.length > 0 &&
                selects.map((select, key) => (
                  <div key={key} className="col-span-3 w-full hidden lg:flex">
                    <select
                      name={select.name}
                      onChange={(e) => handleInputFieldData(e, 'userNewsInput')}
                      value={
                        select.name === 'type' ? userNewsFormData.type :
                          select.name === 'category' ? userNewsFormData.category :
                            select.name === 'language' && userNewsFormData.language
                      }
                      className="w-full h-7 text-sm px-1 text-red-500 rounded-md outline-none capitalize bg-gray-300/10 border border-cyan-300"
                    >
                      <option value="">{select.name} *</option>
                      {loading ? (
                        <option className="text-black">Loading . . .</option>
                      ) : (
                        <>
                          {select.options.length > 0 &&
                            select.options.map((option, index) => (
                              <option key={index} value={option} className="text-black">
                                {option}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                ))}
            </div>
            <div className="grid grid-cols-9 gap-2 items-center text-red-600">
              <div className="col-span-3">{errors.type}</div>
              <div className="col-span-3">{errors.category}</div>
              <div className="col-span-3">{errors.language}</div>
            </div>
            <div className="grid grid-cols-9 gap-2 items-center">
              <div className="col-span-9 flex flex-col gap-2">
                <div className="flex flex-col lg:flex-row gap-2">
                  <div className="h-full w-full  grid items-center rounded-md">
                    {isVideo ?
                      <VideoViewCard
                        videoRejected={videoRejected}
                        setVideoRejected={setVideoRejected}
                        cancelPost={cancelPost}
                        cancelUpload={cancelUpload}

                      /> :
                      <VideoDropzone setVideoRejected={setVideoRejected} cancelUpload={cancelUpload} />
                    }
                  </div>
                  <ImagesDropzone setImagesRejected={setImagesRejected} cancelUpload={cancelUpload} />
                </div>
                {(errors.images || errors.video) && (
                  <div className="col-span-9 flex justify-between">
                    {errors.video ? (
                      <div className=" text-red-600">{errors.video}</div>
                    ) : <div></div>}
                    {errors.images && (
                      <div className=" text-red-600">{errors.images}</div>
                    )}
                  </div>
                )}
                <ImagesViewCard
                  imagesRejected={imagesRejected}
                  setImagesRejected={setImagesRejected}
                  cancelPost={cancelPost}
                  cancelUpload={cancelUpload}
                />
              </div>
            </div>
            <div className="grid grid-cols-9 gap-2 items-center">
              <div className="col-span-9">
                <JoditEditor
                  value={userNewsFormData.description}
                  config={descriptionConfig}
                  tabIndex={1}
                  onBlur={(newDescription) => dispatch(storeUserNewsFormData({
                    ...userNewsFormData,
                    description: newDescription,
                  }))}
                />
              </div>
              {errors.description && (
                <div className="col-span-9 text-red-600">
                  {errors.description}
                </div>
              )}
            </div>
            <div className="grid grid-cols-9 gap-2 items-center">
              <div className="col-span-9 ">
                <JoditEditor
                  value={userNewsFormData.content}
                  config={contentsConfig}
                  tabIndex={1}
                  onBlur={(newContent) =>
                    dispatch(
                      storeUserNewsFormData({
                        ...userNewsFormData,
                        content: newContent,
                      })
                    )
                  }
                />
              </div>
              {errors.content && (
                <div className="col-span-9 text-red-600">{errors.content}</div>
              )}
            </div>
            <div className="grid grid-cols-9 gap-2 items-center">
              <div className="col-span-9 flex gap-2">
                <input
                  type="checkbox"
                  name="agree"
                  value={userNewsFormData.agree}
                  onChange={(e) => handleCheckBoxFieldData(e, 'userNewsCheckbox')}
                  checked={userNewsFormData.agree}
                />
                <div className="">I have agreed with terms and condition.</div>
              </div>
              {errors.agree && (
                <div className="col-span-9 text-red-600">{errors.agree}</div>
              )}
            </div>
            {progress > 0 && progress <= 100 &&
              <ProgressBar progress={progress} cancelPost={cancelPost} cancelUpload={cancelUpload} />
            }
            <div className=" text-sm flex gap-2 justify-end pb-3 pr-3">
              {postLoading ? (
                <>
                  <div
                    className="py-1 px-4 bg-gray-400 hover:bg-gray-600 text-white rounded-md cursor-pointer"
                  >
                    Reset
                  </div>
                  <div
                    className="py-1 px-4 bg-gray-400 hover:bg-gray-600 text-white rounded-md cursor-pointer"
                  >
                    Publish
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={() => { newsId === "" ? handleResetForm("reset") : handleResetForm("") }}
                    className="py-1 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md cursor-pointer"
                  >
                    Reset
                  </div>
                  <button
                    type="submit"
                    className="py-1 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
                  >
                    Publish
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
);

export default NewsForm;
