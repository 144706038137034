import React, { memo } from "react";
import { Link } from "react-router-dom";

const GuestLogMenu = memo(({ logMenu, className }) => {
  return (
    <>
      <Link to={logMenu.url} className={`hover:font-semibold ${className}`}>
        {logMenu.name}
      </Link>
    </>
  );
});

export default GuestLogMenu;
