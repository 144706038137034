import React, { useEffect } from "react";
import { BiDotsHorizontal } from "react-icons/bi";
import UserInfo from "./user details/UserInfo";
import UserContactDetails from "./user details/UserContactDetails";
import UserContributeStatus from "./user details/UserContributeStatus";
import UserConsumeStatus from "./user details/UserConsumeStatus";
import UserFollowMessage from "./user details/UserFollowMessage";
import useGetRequests from "../../../../../sources/hooks/requests/useGetRequests";
import ProfilePhoto from "../../../../common/profile/ProfilePhoto";
import SkeletonBar from "../../../../common/skeleton/SkeletonBar";
import UserFloatButtons from "./user details/UserFloatButtons";

export default function UserDetails({ url = "/user", type = "user" }) {
  const { handleGetRequest, user, message, loading } = useGetRequests();
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_COMMON_APIKEY,
    };
    handleGetRequest(url, data, type);
  }, [handleGetRequest, type, url]);
  return (
    <>
      <div className="col-span-12 md:col-span-9 md:py-2 lg:mx-10 text-sm">
        <div className="border-x-2 bg-white shadow-lg pb-12">
          <div className="border-y-2 py-1 ">
            <div className="flex justify-between items-center mx-4">
              {loading ? (
                <SkeletonBar
                  parentClass={"h-7 w-[10%] my-2 bg-gray-300/60 rounded"}
                  childClass={"h-full w-full bg-white/20 animate-slide"}
                />
              ) : (
                <span className="text-lg py-2">{user.name}</span>
              )}

              <BiDotsHorizontal className=" cursor-pointer" />
            </div>
          </div>
          <div className="m-6">
            <div className="grid grid-cols-12 gap-4">
              <div className=" col-span-12 lg:col-span-4 ">
                <div className="flex flex-col gap-4">
                  <div className="shadow-lg border px-2 py-4 flex flex-col items-center gap-2 rounded ">
                    <ProfilePhoto />
                    <UserFollowMessage />
                  </div>
                  <UserContactDetails />
                </div>
              </div>
              <div className=" col-span-12 lg:col-span-8  flex flex-col gap-2">
                <UserInfo user={user} message={message} loading={loading} />
                <div className="flex gap-4 ">
                  <UserConsumeStatus />
                  <UserContributeStatus />
                </div>
              </div>
            </div>
            <div className="float-right">
              <UserFloatButtons />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
