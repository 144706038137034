import React, { memo } from "react";
import Error404 from "../../components/common/errors/Error404";
import { Outlet, useLocation } from "react-router-dom";
import ProfileHeader from "../../components/user/personalize/profile/ProfileHeader";
import resources from "../../sources/data/resources";
import ProfileMenuBar from "../../components/user/personalize/profile/ProfileMenuBar";
import MenuBar from "../../components/home/navbar/MenuBar";
import BreakingNewsBar from "../../components/home/navbar/BreakingNewsBar";
import TopHeaderBar from "../../components/home/navbar/TopHeaderBar";
import SupportTitleLogbar from "../../components/home/navbar/SupportTitleLogbar";

const ProfileLayout = memo(() => {
  const { token } = resources();
  const { pathname } = useLocation();
  return (
    <>
      <TopHeaderBar />
      <div className="sticky top-0 left-0 right-0 z-40">
        <BreakingNewsBar />
      </div>
      <SupportTitleLogbar />
      <div className="sticky top-[38px] left-0 right-0 z-40">
        <MenuBar />
      </div>
      {token ? (
        <>
          {pathname === "/profile" && <ProfileHeader />}
          <div className=" sticky top-[70px] md:top-[74px] lg:top-[86px] z-20 bg-white ">
            <ProfileMenuBar />
          </div>
          <Outlet />
        </>
      ) : (
        <Error404 />
      )}
    </>
  );
});

export default ProfileLayout;
