import moment from "moment";
import React, { memo } from "react";
import { BiSolidTimeFive } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { FaEdit, FaEye, FaShare } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { ImEye } from "react-icons/im";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { storeNewsId, storeShareNewsId, storeNewsShareModal } from "../../../sources/store/slices/modalSlice";
import { useDispatch } from "react-redux";
import usePreAlertRequest from "../../../sources/hooks/requests/usePreAlertRequest";
import useButtonToggle from "../../../sources/functions/useButtonToggle";
const UserNewsCard = memo(({ lastNewsElementRef, news = [], deleteUrl = '/user/news/trashed', deleteType = 'deleteUserNews', message = 'News does not deleted?' }) => {
  const dispatch = useDispatch()
  const { handlePreAlertRequest } = usePreAlertRequest()
  const { handleButtonToggle, threeDotClick, buttonRef } = useButtonToggle()

  return (
    <>
      {news &&
        news.map((item, i) => (
          <div ref={news.length === i + 1 ? lastNewsElementRef : null} key={i} className="p-2 bg-gray-50  shadow-md rounded-md ">
            <div className="flex justify-between gap-2 pb-2">
              <div className="py-1 font-serif text-justify">{item.title}</div>
              <div ref={(el) => buttonRef.current[i] = el} className="relative mx-2  ">
                <button
                  type="button"
                  onClick={() => handleButtonToggle(i)}
                  className="w-full text-lg flex items-center gap-1 bg-gray-300/80 hover:bg-gray-400 hover:text-white duration-300 px-2 py-1 rounded-sm">
                  <BsThreeDots />
                </button>
                {threeDotClick.value && threeDotClick.index === i && (
                  <div className="absolute top-8 right-0 w-32 border shadow rounded-md bg-white px-4 py-2">
                    <Link
                      to={item.url}
                      className="flex items-center gap-2 hover:font-semibold duration-200">
                      <FaEye />
                      <span>View</span>
                    </Link>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(storeNewsId(item.news_id));
                      }}
                      className="flex items-center gap-2 hover:font-semibold duration-200">
                      <FaEdit />
                      <span>Edit</span>
                    </button>
                    <button
                      type="button"
                      onClick={(e) => handlePreAlertRequest(e, deleteUrl, { news_id: item.news_id }, deleteType, message)}
                      className="flex items-center gap-2 hover:font-semibold duration-200">
                      <FaDeleteLeft className="rotate-180" />
                      <span>Delete</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(storeNewsShareModal(true));
                        dispatch(storeShareNewsId(item.news_id));
                      }}
                      className="flex items-center gap-2 hover:font-semibold duration-200">
                      <FaShare />
                      <span>Share</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-1 justify-end items-center pb-2 px-2">
              <BiSolidTimeFive className="mt-px" />
              <small className="">
                {moment(item.published_at).format("Do, MMM YYYY")}
              </small>
            </div>
            <Link to={item.url} className="px-2 flex justify-center">
              <img
                src={item.image ? item.image : "storage/default/news.png"}
                alt={item.image ? item.image : "news.png"}
                className="w-[480px] h-[320px] rounded-md"
              />
            </Link>
            <div className="p-2 text-justify description">
              {item.description !== null && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.description.substr(0, 300)),
                  }}
                />
              )}
              <small className="ml-1 text-blue-300 hover:text-blue-500">
                <Link to={item.url} target="_blank" className="underline" >
                  Read more ....
                </Link>
              </small>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img
                  src={item?.profile ? "/storage/uploads/profile/" + item?.profile?.profile_photo : item?.image ? item?.image : "/storage/default/news.png"}
                  alt={item?.profile ? item?.profile?.profile_photo : item?.image ? item?.image : "news.png"}
                  className="h-10 w-10 rounded-full border mr-2"
                />
                <small>{item.author}</small>
              </div>
              <small className="flex items-center gap-1 p-4">
                <span>{item.views}</span>
                <ImEye />
                <span className="font-bold">,</span>
                <span>{item.share}</span>
                <FaShare />
              </small>
            </div>
          </div>
        ))}
    </>
  );
});

export default UserNewsCard;