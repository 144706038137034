import React, { memo } from 'react'
import usePatchRequests from '../../../sources/hooks/requests/usePatchRequests';
import { RiSave3Line } from 'react-icons/ri';
const SavesButton = memo(({ item, i, buttonData, setButtonData }) => {
    const { handlePatchRequest } = usePatchRequests()
    return (
        <>
            <button
                type="button"
                onClick={(e) =>
                    handlePatchRequest(e, "/user/update/saves", { news_id: item.news_id }, "updateSaves", setButtonData)
                }
                className="w-full flex justify-center items-center"
            >
                <RiSave3Line
                    title={`${buttonData[i]?.saves
                        ? "Remove to save list"
                        : "Add to save list"
                        }`}
                    className={`${buttonData[i]?.saves
                        ? "hover:text-black text-red-500"
                        : "hover:text-red-500 text-black"
                        } text-lg`}
                />
            </button>
        </>
    )
})

export default SavesButton