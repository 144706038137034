import React, { memo, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";

import Loading from "../loading/Loading";
import usePutRequests from "../../../sources/hooks/requests/usePutRequests";
import useGetRequests from './../../../sources/hooks/requests/useGetRequests';

const NewsCardFullView = memo(({ getUrl = '/get/news/content', getType = 'getNewsContent', viewUrl = '/update/news/views', viewType = 'updateNewsView' }) => {
  const { news_id } = useParams();
  const { handlePutRequest } = usePutRequests()
  const { handleGetRequest, loading, contents } = useGetRequests()
  useEffect(() => {
    handleGetRequest(getUrl, { news_id: news_id }, getType);
  }, [getType, getUrl, handleGetRequest, news_id]);
  useEffect(() => {
    handlePutRequest(viewUrl, { news_id: news_id }, viewType)
  }, [handlePutRequest, news_id, viewType, viewUrl])
  const news = useMemo(() => { return contents }, [contents])
  return (
    <>
      <div className="max-w-screen-lg mx-auto bg-gray-300 shadow-lg p-3 my-1">
        {loading ? (
          <div className="h-[50vh] flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <div className="bg-gray-100 p-6">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(news, { ALLOWED_TAGS: ["iframe", "img", "video", "p", "br", "div", "a", "strong", "h1", "h2", "h3", "h4", "h5", "h6", "em", "u", "ol", "ul", "li", "pre", "hr"] }),
              }}
            />
          </div>
        )}
      </div>
    </>
  );
});

export default NewsCardFullView;
