import { createContext } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();
const SocketContextProvider = ({ children }) => {
  const socket = io.connect(`${process.env.REACT_APP_SERVER_BASE_URL}`);
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketContextProvider };
