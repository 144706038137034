import React from "react";
import { BiDotsHorizontal } from "react-icons/bi";
import PublicInfoForm from "./account/PublicInfoForm";
import PrivateInfoForm from "./account/PrivateInfoForm";
import ProfilePhoto from "./../../../../common/profile/ProfilePhoto";

export default function Account() {
  return (
    <>
      <div className="col-span-12 md:col-span-9 md:py-2 lg:mx-10">
        <div className="border-x-2 bg-white shadow-lg mb-4">
          <div className="border-y-2 py-1 ">
            <div className="flex justify-between items-center mx-4">
              <span className="text-lg py-2">Public Info</span>
              <BiDotsHorizontal className=" cursor-pointer" />
            </div>
          </div>
          <div className="py-2 lg:grid lg:grid-cols-12">
            <div className="col-span-12 md:col-span-9 gap-2">
              <PublicInfoForm />
            </div>
            <div className="col-span-3 relative lg:flex h-full w-full items-center justify-center ">
              <ProfilePhoto />
            </div>
          </div>
        </div>
        <div className="border-x-2 bg-white shadow-lg">
          <div className="border-y-2 py-1 ">
            <div className="flex justify-between items-center mx-4">
              <span className="text-lg py-2">Private Info</span>
              <BiDotsHorizontal className=" cursor-pointer" />
            </div>
          </div>
          <div className="py-2 lg:grid lg:grid-cols-12">
            <div className="col-span-12 md:col-span-9 gap-2">
              <PrivateInfoForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
