import moment from "moment";
import React, { memo, useRef, useState } from "react";
import { AiOutlineFullscreen } from "react-icons/ai";
import { BsGrid } from "react-icons/bs";
// import { MdOutlineLanguage } from "react-icons/md";
import Modebar from "./../modebar/Modebar";
import NavBarFunstions from "../../../sources/functions/NavBarFunstions";
const TopHeaderBar = memo(() => {
  const modeMenuRef = useRef(null);
  const [modeMenu, setModeMenu] = useState(false);
  const { fullScreen } = NavBarFunstions();

  return (
    <>
      <div className="flex justify-between items-center px-4 py-1 md:py-2">
        <div className="md:ml-1">
          {moment().format("MMMM, Do YYYY, h:mm A")}
        </div>
        <div className="flex items-center gap-4 md:gap-10">
          {/* <div className="flex items-center">
            <MdOutlineLanguage title="Language" className="cursor-pointer" />
            <select name="" id="">
              <option value="">En</option>
              <option value="">De</option>
              <option value="">Bn</option>
              <option value="">In</option>
            </select>
          </div> */}
          <AiOutlineFullscreen
            title="Full Screen"
            className="cursor-pointer"
            onClick={fullScreen}
          />
          <div className="" ref={modeMenuRef}>
            <BsGrid
              title="Mode"
              className="cursor-pointer"
              onClick={() => setModeMenu(!modeMenu)}
            />
            {modeMenu && <Modebar />}
          </div>
        </div>
      </div>
    </>
  );
});

export default TopHeaderBar;
