import React, { memo } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { storeSidebarMenu } from "../../../sources/store/slices/stateSlice";
const MenuButton = memo(() => {
  const dispatch = useDispatch();
  const { sidebarMenu } = useSelector((store) => store.state);
  return (
    <>
      <div className="border-r-2 border-gray-600/50 pr-2" >
        <div onClick={() => dispatch(storeSidebarMenu(!sidebarMenu))} className="h-full flex items-center">
          <AiOutlineMenu
            className="cursor-pointer text-black text-lg font-bold "
            title="Menu"
          />
        </div>
      </div>
    </>
  );
});

export default MenuButton;
