import React, { memo, useEffect, useMemo, useState } from "react";
import { FaShare } from "react-icons/fa";
import { ImEye } from "react-icons/im";
import moment from "moment";
import { BiSolidTimeFive } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import DOMPurify from "dompurify";
import NewsCardSkeleton from "../skeleton/NewsCardSkeleton";
import useGetRequests from './../../../sources/hooks/requests/useGetRequests';
import resources from "../../../sources/data/resources";
import usePutRequests from "../../../sources/hooks/requests/usePutRequests";
import { useDispatch } from "react-redux";
import { storeNewsShareModal, storeShareNewsId } from "../../../sources/store/slices/modalSlice";
import FavouriteButton from "../bottons/FavouriteButton";
import SavesButton from "../bottons/SavesButton";
const ApiNewsCardView = memo(({ getUrl = "/api/news", getType = "apiNews" }) => {
  const dispatch = useDispatch();
  const { token } = resources();
  const { news_id } = useParams();
  const { handleGetRequest, apiNews, loading, buttonData, setButtonData } = useGetRequests()
  const { handlePutRequest } = usePutRequests()
  const news = useMemo(() => { return apiNews }, [apiNews])
  const id = useMemo(() => { return news_id }, [news_id])
  const [threeDotClick, setThreeDotClick] = useState({});
  useEffect(() => {
    const data = { news_id: id }
    handleGetRequest(getUrl, data, getType)
  }, [getType, getUrl, handleGetRequest, id]);
  return (
    <>
      {loading ? (
        <div className=" w-full 2xl:w-[650px] mx-auto space-y-4 mb-4 mt-2">
          {[1].map((index) => (
            <NewsCardSkeleton key={index} />
          ))}
        </div>
      ) : (
        <>
          {news && (
            <div className="p-2 bg-gray-50  shadow-md rounded-md w-[650px] mx-auto my-2">
              <div className="flex justify-between gap-6 pb-2">
                <div className="py-1 font-serif text-justify">{news.title}</div>
                <div className="relative m-2  ">
                  <button
                    type="button"
                    className="bg-gray-300/80 hover:bg-gray-400 hover:text-white duration-300 px-2 py-1 rounded-sm"
                    onClick={() =>
                      setThreeDotClick({
                        value: !threeDotClick.value,
                      })
                    }
                  >
                    <BsThreeDots className="text-lg" />
                  </button>
                  {threeDotClick.value && (
                    <>
                      {token ? (
                        <div className="absolute top-6 right-8 flex items-center gap-2 bg-white p-4 border shadow-md rounded-md">
                          <button
                            type="button"
                            onClick={() => {
                              dispatch(storeNewsShareModal(true))
                              dispatch(storeShareNewsId(news.news_id))
                            }}
                            className="flex items-center justify-center "
                          >
                            <FaShare
                              title="Share"
                              className="text-lg hover:text-red-500"
                            />
                          </button>
                          <FavouriteButton item={apiNews} i={0} buttonData={buttonData} setButtonData={setButtonData} />
                        <SavesButton item={apiNews} i={0} buttonData={buttonData} setButtonData={setButtonData} />
                        </div>
                      ) : (
                        <div className="absolute top-6 right-8 flex items-center gap-2 bg-white p-4 border shadow-md rounded-md">
                          <button
                            type="button"
                            onClick={() => {
                              dispatch(storeNewsShareModal(true))
                              dispatch(storeShareNewsId(news.news_id))
                            }}
                            className="flex gap-1 items-center justify-center hover:font-semibold hover:text-red-500"
                          >
                            <FaShare title="Share" className="text-lg " />
                            <span className="mb-1">Share</span>
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex gap-1 justify-end items-center pb-2 px-2">
                <BiSolidTimeFive className="mt-px" />
                <small className="">
                  {moment(news.published_at, "YYYYMMDD")
                    .startOf("hour")
                    .fromNow()}
                </small>
              </div>
              <div className="px-2">
                <img
                  src={news.image ? news.image : "/storage/default/news.png"}
                  alt={news.image ? news.image : "news.png"}
                  className="w-full h-[400px] rounded-md shadow-md"
                />
              </div>
              <div className="p-2 text-justify description">
                {news.description !== null && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        news.user_id
                          ? news.description.substr(0, 300)
                          : news.description
                            .split(" ")
                            .reverse()
                            .slice(2)
                            .reverse()
                            .join(" ")
                            .substr(0, 300)
                      ),
                    }}
                  />
                )}
                <small
                  onClick={() => {
                    handlePutRequest('/update/news/views', { news_id: news.news_id }, 'updateNewsView');
                  }}
                  className="ml-1 text-blue-300 hover:text-blue-500"
                >
                  <Link href={news.url} target="_blank" className="underline">
                    Read more ....
                  </Link>
                </small>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                     src={news?.profile ? "/storage/uploads/profile/" + news?.profile?.profile_photo : news?.image ? news?.image : "/storage/default/news.png"}
                     alt={news?.profile ? news?.profile?.profile_photo : news?.image ? news?.image : "news.png"}
                    className="h-10 w-10 rounded-full border mr-2"
                  />
                  <small>{news.author}</small>
                </div>
                <small className="flex items-center gap-1 p-4">
                  <span>{news.views}</span>
                  <ImEye />
                  <span className="font-bold">,</span>
                  <span>{news.share}</span>
                  <FaShare />
                </small>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
});

export default ApiNewsCardView;
