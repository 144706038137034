import React, { memo } from "react";
import TreandingLatestMenu from "../subMenu/TreandingLatestMenu";
import CurrentLatestMenu from "../subMenu/CurrentLatestMenu";
import { useLocation } from "react-router-dom";
import Title from "../../common/title/Title";
const SubMenuBar = memo(() => {
  const { pathname } = useLocation();
  return (
    <>
      <div className=" bg-gray-100 border-y-2 border-black text-black">
        <div className="px-4 grid grid-cols-12 justify-around items-center">
          <div className="col-span-3 text-center font-semibold hidden md:inline-block">
            Sponsored
          </div>
          {pathname !== "/search" ? (
            <div className="col-span-6 grid grid-cols-12 items-center text-xs md:text-base py-2">
              <div className="col-span-5 flex gap-1 items-center">
                <TreandingLatestMenu />
              </div>
              <div className="col-span-7 flex gap-10 items-center">
                <CurrentLatestMenu />
              </div>
            </div>
          ) : (
            <div className="col-span-6 w-3/4 mx-auto items-center text-xs md:text-base py-2">
              <Title title="Search Result" />
            </div>
          )}
          <div className="col-span-3 text-center font-semibold hidden md:inline-block">
            Sponsored
          </div>
        </div>
      </div>
    </>
  );
});

export default SubMenuBar;
