import { useCallback, useContext, useEffect } from "react";
import { storeErrors } from "../../store/slices/dataSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../axios/Axios";
import SweetAlert from "../../functions/SweetAlert";
import { SocketContext } from "../../context/SocketContext";
export default function usePostRequests() {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUserInfoResponse = useCallback((res, type) => {
    SweetAlert("Success", res.data.message, "success", 1000, "");
    if (type === 'publicInfo') socket?.emit(type, type)
    if (type === 'privateInfo') socket?.emit(type, type)
  }, [socket]);
  const handleUserPhotoResponse = useCallback((res, type) => {
    SweetAlert("Success", res.data.message, "success", 1000, "");
    if (type === 'coverPhoto') socket?.emit(type, type)
    if (type === 'profilePhoto') socket?.emit(type, type)
  }, [socket]);
  const handleAuthLoginUnauthorized = useCallback((res) => {
    SweetAlert("Warning", res.data.message, "warning", null, "errors-text-color");
  }, []);
  const handleVerifiedUnauthorized = useCallback(
    (res) => {
      SweetAlert("Warning", res.data.message, "warning", null, "errors-text-color");
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handleResetPassword = useCallback(
    (res) => {
      SweetAlert("Success", res.data.message, "success", null, "");
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handleResetPasswordSessionExpired = useCallback(
    (res) => {
      SweetAlert("Failed", res.data.message, "warning", null, "");
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handleAuthEmailNotVerified = useCallback(
    (res) => {
      localStorage.setItem("auth_token", res.data.token);
      localStorage.setItem("auth_name", res.data.name);
      localStorage.setItem("auth_email", res.data.email);
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handleAuthLogin = useCallback(
    (res) => {
      localStorage.setItem("auth_token", res.data.token);
      localStorage.setItem("auth_name", res.data.name);
      if (res.data.remember)
        localStorage.setItem("remember", JSON.stringify(res.data.remember));
      SweetAlert("Success", res.data.message, "success", 2000, "");
      navigate(res.data.redirect);
      window.location.reload();
    },
    [navigate]
  );
  const handleAuthRegister = useCallback(
    (res) => {
      localStorage.setItem("auth_token", res.data.token);
      localStorage.setItem("auth_name", res.data.name);
      localStorage.setItem("auth_email", res.data.email);
      SweetAlert("Success", res.data.message, "success", null, "");
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handlePasswordUpdateResponse = useCallback(
    (res) => {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_name");
      SweetAlert("Success", res.data.message, "success", null, "");
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handleAuthVerify = useCallback(
    (res) => {
      localStorage.setItem("auth_token", res.data.token);
      localStorage.setItem("auth_name", res.data.name);
      localStorage.setItem("auth_email", res.data.email);
      SweetAlert("Success", res.data.message, "success", null, "errors-text-color");
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handleAuthVerified = useCallback(
    (res) => {
      localStorage.setItem("auth_token", res.data.token);
      localStorage.setItem("auth_name", res.data.name);
      localStorage.removeItem("auth_email", res.data.email);
      SweetAlert("Success", res.data.message, "success", 2000, "");
      window.location.href = res.data.redirect;
    },
    []
  );
  const handleLogoutResponse = useCallback(
    (res) => {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_name");
      SweetAlert("Success", res.data.message, "success", 2000, "");
      navigate(res.data.redirect);
    },
    [navigate]
  );
  const handlePostResponse = useCallback(
    (res, type) => {
      switch (res.data.status + type) {
        case 200 + "login":
          handleAuthLogin(res);
          break;
        case 200 + "coverPhoto":
        case 200 + "profilePhoto":
          handleUserPhotoResponse(res, type);
          break;
        case 200 + "publicInfo":
        case 200 + "privateInfo":
          handleUserInfoResponse(res, type);
          break;
        case 200 + "passwordUpdate":
          handlePasswordUpdateResponse(res);
          break;
        case 200 + "register":
          handleAuthRegister(res);
          break;
        case 200 + "verify":
        case 200 + "emailUpdate":
          handleAuthVerify(res);
          break;
        case 200 + "verified":
          handleAuthVerified(res);
          break;
        case 200 + "forgetPassword":
          SweetAlert("Success", res.data.message, "success", null, "");
          break;
        case 200 + "resetPassword":
          handleResetPassword(res);
          break;
        case 200 + "logout":
          handleLogoutResponse(res);
          break;
        case 310 + "login":
          handleAuthEmailNotVerified(res);
          break;
        case 401 + "login":
          handleAuthLoginUnauthorized(res);
          break;
        case 401 + "verified":
          handleVerifiedUnauthorized(res);
          break;
        case 404 + "login":
        case 404 + "register":
        case 404 + "forgetPassword":
        case 404 + "resetPassword":
        case 403 + "logout":
        case 403 + "coverPhoto":
        case 403 + "profilePhoto":
          SweetAlert(
            "Failed",
            res.data.message,
            "warning",
            null,
            "errors-text-color"
          );
          break;
        case 422 + "login":
        case 422 + "register":
        case 422 + "forgetPassword":
        case 422 + "resetPassword":
        case 422 + "publicInfo":
        case 422 + "privateInfo":
        case 422 + "coverPhoto":
        case 422 + "profilePhoto":
        case 422 + "emailUpdate":
        case 422 + "passwordUpdate":
          dispatch(storeErrors(res.data.errors));
          break;
        case 440 + "resetPassword":
          handleResetPasswordSessionExpired(res);
          break;
        default:
          console.log(res);
          break;
      }
    },
    [
      dispatch,
      handleAuthEmailNotVerified,
      handleAuthLogin,
      handleAuthLoginUnauthorized,
      handleAuthRegister,
      handleAuthVerified,
      handleAuthVerify,
      handleLogoutResponse,
      handlePasswordUpdateResponse,
      handleResetPassword,
      handleResetPasswordSessionExpired,
      handleUserInfoResponse,
      handleUserPhotoResponse,
      handleVerifiedUnauthorized,
    ]
  );
  const handlePostRequest = useCallback(
    (e, url = "", data = {}, type = "") => {
      e.preventDefault();
      dispatch(storeErrors(""));
      try {
        axios
          .post(url, data)
          .then((res) => {
            handlePostResponse(res, type);
          })
          .catch((errors) => { });
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, handlePostResponse]
  );
  useEffect(() => {
    dispatch(storeErrors(""));
  }, [dispatch]);
  return { handlePostRequest };
}
