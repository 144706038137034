import React, { useEffect } from "react";
import Ads from "../../../common/ads/Ads";
import NewsForm from "../../news/NewsForm";
import UserNewsPosts from "../../news/UserNewsPosts";
import useGetRequests from "../../../../sources/hooks/requests/useGetRequests";
export default function Profile({ filterUrl = "/user/news/form/filters", filterType = "userNewsFormFilters" }) {
  const { handleGetRequest, selectType, selectCategory, selectLanguage } = useGetRequests();
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_USER_NEWS_FORM_FILTERS_APIKEY,
    };
    handleGetRequest(filterUrl, data, filterType)
  }, [filterType, filterUrl, handleGetRequest])

  return (
    <>
      <div className="md:grid md:grid-flow-col md:grid-cols-5 gap-2 mt-2 px-6">
        <div className="col-span-1 grid gap-4">
          {[1, 2, 3, 4, 5].map((data, i) => (
            <Ads key={i} />
          ))}
        </div>
        <div className="col-span-2 pb-2 ">
          <div className="md:h-newsFormHeight md:overflow-y-auto md:scrollbar-thin sticky top-36 z-10 bg-gray-100/20 shadow-lg px-4 py-3 border border-gray-200 rounded-md">
            <NewsForm selectType={selectType}
              selectCategory={selectCategory}
              selectLanguage={selectLanguage} />
          </div>
        </div>
        <div className="col-span-2 w-full">
          <UserNewsPosts />
        </div>
      </div>
    </>
  );
}
