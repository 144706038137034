import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import Title from "../../common/title/Title";
import NewsCard from "../../common/postCards/NewsCard";
import NewsCardSkeleton from "../../common/skeleton/NewsCardSkeleton";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import NotFoundMessage from "../../common/errors/NotFoundMessage";
const CurrentNewsPost = memo(({ category, type, url = "/current/news", reqType = "currentNews", title = "Current News" }) => {
  const { handleGetRequest, loading, message, currentNews, handleInfinityScroll, buttonData, setButtonData } = useGetRequests();
  const observer = useRef(null)
  const data = useMemo(() => {
    return {
      category: category,
      type: type,
      apiKey: process.env.REACT_APP_CURRENT_NEWS_APIKEY,
    }
  }, [category, type])
  const lastNewsElementRef = useCallback((node) => {
    handleInfinityScroll(observer, node, url, data, 'currentNewsScroll')
  }, [data, handleInfinityScroll, url])

  useEffect(() => {
    handleGetRequest(url, data, reqType);
  }, [category, data, handleGetRequest, reqType, type, url]);

  const news = useMemo(() => { return currentNews }, [currentNews])
  return (
    <>
      <div className="col-span-12 md:col-span-7">
        <div className="mb-4 mt-2 mx-2 ">
          <div className="w-full 2xl:w-[650px] mx-auto space-y-4">
            <Title title={title} />
            {message !== "" ? (
              <NotFoundMessage message={message} />
            ) : (
              <NewsCard lastNewsElementRef={lastNewsElementRef} news={news} buttonData={buttonData} setButtonData={setButtonData} />
            )}
          </div>
        </div>
        {loading && (
          <div className=" w-full 2xl:w-[650px] mx-auto space-y-4 mb-4 mt-2">
            {[1, 2, 3].map((index) => (
              <NewsCardSkeleton key={index} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
);

export default CurrentNewsPost;
