import React, { useEffect, useState } from "react";
import { BiDotsHorizontal } from "react-icons/bi";
import { useSelector } from "react-redux";
import usePostRequests from "../../../../../sources/hooks/requests/usePostRequests";
import EmailPasswordUpdateSkeleton from "../../../../common/skeleton/EmailPasswordUpdateSkeleton";
import useHandleFormData from "../../../../../sources/functions/useHandleFormData";
export default function Password({ postUrl = "/user/password/update", postType = "passwordUpdate" }) {
  const [loading, setLoading] = useState(false);
  const { updatePassword, errors } = useSelector((store) => store.data);
  const { handleInputFieldData } = useHandleFormData();
  const postData = {
    current_password: updatePassword.current_password,
    password: updatePassword.password,
    password_confirmation: updatePassword.password_confirmation,
    apiKey: process.env.REACT_APP_COMMON_APIKEY,
  };
  const { handlePostRequest, update } = usePostRequests();
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [update]);

  return (
    <>
      <div className="col-span-12 md:col-span-9 md:py-2 lg:mx-10">
        <div className="border-x-2 bg-white shadow-lg mb-4">
          <div className="border-y-2 py-1 ">
            <div className="flex justify-between items-center mx-4">
              <span className="text-lg py-2">Password</span>
              <BiDotsHorizontal className=" cursor-pointer" />
            </div>
          </div>
          {loading ? (
            <EmailPasswordUpdateSkeleton />
          ) : (
            <form onSubmit={(e) => handlePostRequest(e, postUrl, postData, postType)} className="text-sm">
              <div className=" flex flex-col gap-2 m-12">
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                  <div className="col-span-2">
                    Current Password:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="password"
                    name="current_password"
                    value={updatePassword.current_password}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    autoComplete="off"
                    placeholder="Enter your new email?"
                    className="col-span-10 outline-none bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                {errors && (
                  <div className="lg:grid lg:grid-cols-12 ">
                    <div className="col-span-2"></div>
                    <div className="text-red-700 w-full col-span-10">
                      {errors.current_password}
                    </div>
                  </div>
                )}
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                  <div className="col-span-2">
                    New Password:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="password"
                    name="password"
                    value={updatePassword.password}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    autoComplete="off"
                    placeholder="Re-Enter your new email?"
                    className="col-span-10 outline-none bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                {errors && (
                  <div className="md:grid md:grid-cols-12">
                    <div className="col-span-2"></div>
                    <div className="text-red-700 w-full col-span-10">
                      {errors.password}
                    </div>
                  </div>
                )}
                <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
                  <div className="col-span-2">
                    Verify Password:
                    <span className="text-red-500 font-bold"> *</span>
                  </div>
                  <input
                    type="password"
                    name="password_confirmation"
                    value={updatePassword.password_confirmation}
                    onChange={(e) => handleInputFieldData(e, postType)}
                    autoComplete="off"
                    placeholder="Re-Enter your new email?"
                    className="col-span-10 outline-none bg-gray-50 pl-2 border border-yellow-300 w-full h-9"
                  />
                </div>
                {errors && (
                  <div className="md:grid md:grid-cols-12">
                    <div className="col-span-2"></div>
                    <div className="text-red-700 w-full col-span-10">
                      {errors.password_confirmation}
                    </div>
                  </div>
                )}
                <div className="col-span-12 text-end mb-4 mx-3">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-gray-50 rounded-full px-4 py-1.5 cursor-pointer text-md ">
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
