import React, { memo, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import CopyToClipboard from "react-copy-to-clipboard";
import Loading from "../../loading/Loading";
import ProgressBar from "../../progress/ProgressBar";
import { storeIsVideo, storeIsVideoErrors } from "../../../../sources/store/slices/stateSlice";
import { storeErrors } from "../../../../sources/store/slices/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import useDeleteRequests from "../../../../sources/hooks/requests/useDeleteRequests";
const VideoViewCard = memo(({ videoRejected, setVideoRejected, cancelPost,cancelUpload, url = '/delete/video', type = 'deleteVideo' }) => {
  const dispatch = useDispatch();
  const { video, videoProgress } = useSelector((store) => store.data);
  const { isVideoErrors } = useSelector((store) => store.state);
  const [copy, setCopy] = useState(false);
  const { handleDeleteRequest } = useDeleteRequests()
  const handleRejectedVideoDelete = (rejectedVideoName) => {
    setVideoRejected((videoRejected) =>
      videoRejected.filter(({ file }) => file.name !== rejectedVideoName)
    );
    if (videoRejected?.length <= 1) {
      dispatch(storeIsVideoErrors(false));
      dispatch(storeIsVideo(false));
      dispatch(storeErrors(''));
    }
  };
  return (
    <>
      <div className="h-40  rounded-md  border border-cyan-300">
        <div className="h-full w-full flex items-center justify-center text-gray-300">
          {isVideoErrors ? (
            <div className="flex flex-col justify-center items-center mx-2 gap-1">
              {videoRejected.map(({ file, errors }) => (
                <div
                  className="relative flex flex-col gap-1 justify-between items-center mx-2"
                  key={file.name}
                >
                  {errors.map((videoError) => (
                    <div
                      className="flex flex-col gap-1 items-center"
                      key={videoError.code}
                    >
                      <span className="text-red-600/90">
                        {file.name}
                        <span className="text-red-600/90 lowercase ml-1">
                          {videoError.message}
                        </span>
                      </span>
                    </div>
                  ))}
                  <span
                    title="close"
                    className="absolute top-0 right-0 text-white hover:text-gray-300 bg-red-600/80 p-0.5 hover:bg-red-600  rounded-full font-bold cursor-pointer"
                    onClick={() => handleRejectedVideoDelete(file.name)}
                  >
                    <FaXmark />
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <>
              {videoProgress > 0 && videoProgress <= 100 ? (
                <div className="w-full">
                  <div className="flex justify-center items-center">
                    <Loading />
                  </div>
                  <ProgressBar progress={videoProgress} cancelPost={cancelPost} cancelUpload={cancelUpload}/>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center mx-2 gap-1">
                  {video.map((video, index) => (
                    <div
                      className="relative flex flex-col justify-center items-center mx-2"
                      key={video.name}
                    >
                      <video
                        src={video.video_url}
                        controls
                        className="w-full h-32 rounded-md py-1 px-2"
                      ></video>
                      <CopyToClipboard
                        text={`<iframe src="${video.video_url}" width="300" height="150" style="display: block; margin-left: auto; margin-right: auto;" title="The News App Video Player" allowfullscreen="" frameborder="0" sandbox=""></iframe>`}
                        onCopy={() => {
                          setCopy({ index: index, value: true });
                          setTimeout(() => {
                            setCopy(false);
                          }, 1000);
                        }}
                      >
                        <button
                          type="button"
                          className={`${copy.index === index && copy.value
                            ? "text-green-500"
                            : "text-red-300 hover:text-red-600 duration-200"
                            }`}
                        >
                          {copy.index === index && copy.value
                            ? "Copied"
                            : "Copy Embed Code"}
                        </button>
                      </CopyToClipboard>
                      <span
                        title="close"
                        className="absolute top-1 right-0 text-white hover:text-gray-300  bg-red-600/80 p-0.5 hover:bg-red-600  rounded-full font-bold cursor-pointer"
                        onClick={() => handleDeleteRequest(url, { video: video.name }, type)}
                      >
                        <FaXmark />
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default VideoViewCard;
