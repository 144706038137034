import React, { memo, useEffect } from "react";
import { NavLink } from "react-router-dom";
import NavBarFunstions from "../../../sources/functions/NavBarFunstions";
const CurrentLatestMenu = memo(() => {
  const { handleWindowsInitialLoad, handleNavigation, currentLatestMenus } = NavBarFunstions()
  useEffect(() => {
    window.addEventListener("load", handleWindowsInitialLoad);
    return () => window.removeEventListener("load", handleWindowsInitialLoad);
  }, [handleWindowsInitialLoad]);

  return (
    <>
      {currentLatestMenus.map((currentLatestMenus, index) => (
        <NavLink
          key={index}
          to={currentLatestMenus.url}
          className={({ isActive }) =>
            isActive
              ? "font-bold bg-blue-500/80 text-white px-2 py-1 rounded-md"
              : "underline"
          }
          onClick={() => handleNavigation(index)}
        >
          {currentLatestMenus.name}
        </NavLink>
      ))}
    </>
  );
});

export default CurrentLatestMenu;
