import React, { memo, useEffect } from "react";
import { BsCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import resources from "../../../sources/data/resources";
import useGetRequests from "../../../sources/hooks/requests/useGetRequests";
import Spining from "../../common/loading/Spining";

const SidebarUserInfo = memo(({ url = "/show/profile", type = "profilePhoto" }) => {
  const { userName } = resources();

  const { handleGetRequest, profile, loading } = useGetRequests();
  useEffect(() => {
    const data = {
      apiKey: process.env.REACT_APP_USER_PROFILE_PHOTO_APIKEY,
    };
    handleGetRequest(url, data, type);
  }, [handleGetRequest, type, url]);
  return (
    <>
      <div className="bg-white  text-black px-2 py-1 mx-1 border rounded-md shadow-lg cursor-pointer hover:bg-blue-700/80 hover:text-white">
        <Link
          to="/profile"
          className="flex items-center justify-center md:space-x-2">
          {loading ? (
            <div className="h-8 w-8 border rounded-full">
              <Spining className="h-8 w-8 mr-2" />
            </div>
          ) : (
            <img
              src={profile}
              alt={profile}
              loading="lazy"
              className="h-8 w-8 border rounded-full"
              title="Logo"
            />
          )}

          <span>{userName}</span>
          <BsCircle className=" shadow-lg  pb-2 text-xs text-green-500 bg-green-500 rounded-full" />
        </Link>
      </div>
    </>
  );
}
);

export default SidebarUserInfo;
