import React, { memo } from "react";

const Image = memo(({ profile, className }) => {
  return (
    <>
      <img src={profile} alt={profile} className={className} />
    </>
  );
});

export default Image;
