import React from "react";
import SkeletonBar from "./SkeletonBar";

export default function PublicInfoFormSkeleton() {
  return (
    <>
      <div className="ml-10">
        <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
          <div className="col-span-3">
            <SkeletonBar
              parentClass={"h-8 w-[40%]  bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
          <div className="col-span-9">
            <SkeletonBar
              parentClass={"h-8 w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
        <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
          <div className="col-span-3">
            <SkeletonBar
              parentClass={"h-8 w-[40%]  bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
          <div className="col-span-9">
            <SkeletonBar
              parentClass={"h-8 w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
        <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
          <div className="col-span-3">
            <SkeletonBar
              parentClass={"h-8 w-[40%]  bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
          <div className="col-span-9">
            <SkeletonBar
              parentClass={"h-8 w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
        <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
          <div className="col-span-3">
            <SkeletonBar
              parentClass={"h-8 w-[40%]  bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
          <div className="col-span-9">
            <SkeletonBar
              parentClass={"h-8 w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
        <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
          <div className="col-span-3">
            <SkeletonBar
              parentClass={"h-8 w-[40%]  bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
          <div className="col-span-9">
            <SkeletonBar
              parentClass={"h-8 w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
        <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
          <div className="col-span-3">
            <SkeletonBar
              parentClass={"h-8 w-[40%]  bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
          <div className="col-span-9">
            <SkeletonBar
              parentClass={"h-20 w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
        <div className="p-2 flex flex-col lg:grid lg:grid-cols-12">
          <div className="col-span-3">
            <SkeletonBar
              parentClass={"h-8 w-[40%]  bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
          <div className="col-span-9">
            <SkeletonBar
              parentClass={"h-32 w-full bg-gray-300/60"}
              childClass={"h-full w-full bg-white/20 animate-slide"}
            />
          </div>
        </div>
        <div className="col-span-12 flex justify-end mb-4 mx-3">
          <SkeletonBar
            parentClass={"h-8 w-[20%] bg-gray-300/60"}
            childClass={"h-full w-full bg-white/20 animate-slide"}
          />
        </div>
      </div>
    </>
  );
}
