import { createSlice } from "@reduxjs/toolkit";
import resources from "../../data/resources";
const { remember, navigator } = resources();
export const initialState = {
  navigator: navigator,
  errors: "",
  breakingNewsMessage: "",
  currentNewsMessage: "",
  topNewsMessage: "",
  searchNewsMessage: "",
  searchFiltersMessage: "",
  breakingNews: [],
  currentNews: [],
  topNews: [],
  searchNews: [],
  loginData:
    remember === null
      ? { email: "", password: "", remember: "" }
      : JSON.parse(remember),
  registerData: {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    agree: "",
  },
  forgetPassEmail: { email: "" },
  resetPasswordData: { password: "", confirm_password: "" },
  searchInputData: {
    type: "",
    source: "",
    category: "",
    from: "",
    to: "",
    search: "",
    apiKey: process.env.REACT_APP_SEARCH_NEWS_APIKEY,
  },
  newsSearchNavigation: 0,
  coverPhoto: "",
  profilePhoto: "",
  userPublicInfo: {
    nick_name: "",
    occupation: "",
    biography: "",
    description: "",
    user_id: "",
  },
  userPrivateInfo: {
    date_of_birth: "",
    status: "",
    gender: "",
    phone_number: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    user_id: "",
  },
  updateEmail: {
    new_email: "",
    verify_email: "",
  },
  updatePassword: {
    current_password: "",
    password: "",
    password_confirmation: "",
  },
  userNewsFormData: {
    title: "",
    type: "",
    category: "",
    language: "",
    description: "",
    content: "",
    agree: false,
  },
  selected: "",
  checked: "",
  images: [],
  video: [],
  progress: 0,
  imageProgress: 0,
  videoProgress: 0,
  buttonData: [],
};

const dataSlice = createSlice({
  name: "data",
  initialState: {
    navigator: initialState.navigator,
    errors: initialState.errors,
    breakingNewsMessage: initialState.breakingNewsMessage,
    currentNewsMessage: initialState.currentNewsMessage,
    topNewsMessage: initialState.topNewsMessage,
    searchNewsMessage: initialState.searchNewsMessage,
    breakingNews: initialState.breakingNews,
    currentNews: initialState.currentNews,
    topNews: initialState.topNews,
    searchNews: initialState.searchNews,
    searchFiltersMessage: initialState.searchFiltersMessage,
    loginData: initialState.loginData,
    registerData: initialState.registerData,
    forgetPassEmail: initialState.forgetPassEmail,
    resetPasswordData: initialState.resetPasswordData,
    searchInputData: initialState.searchInputData,
    newsSearchNavigation: initialState.newsSearchNavigation,
    coverPhoto: initialState.coverPhoto,
    profilePhoto: initialState.profilePhoto,
    userPublicInfo: initialState.userPublicInfo,
    userPrivateInfo: initialState.userPrivateInfo,
    updateEmail: initialState.updateEmail,
    updatePassword: initialState.updatePassword,
    userNewsFormData: initialState.userNewsFormData, selected: initialState.selected,
    checked: initialState.checked,
    images: initialState.images,
    video: initialState.video,
    progress: initialState.progress,
    imageProgress: initialState.imageProgress,
    videoProgress: initialState.videoProgress,
    buttonData: initialState.buttonData,
  },

  reducers: {
    storeNavigator(state, action) {
      state.navigator = action.payload;
    },
    storeErrors(state, action) {
      state.errors = action.payload;
    },
    storeBreakingNewsMessage(state, action) {
      state.breakingNewsMessage = action.payload;
    },
    storeCurrentNewsMessage(state, action) {
      state.currentNewsMessage = action.payload;
    },
    storeTopNewsMessage(state, action) {
      state.topNewsMessage = action.payload;
    },
    storeSearchNewsMessage(state, action) {
      state.searchNewsMessage = action.payload;
    },
    storeSearchFiltersMessage(state, action) {
      state.searchFiltersMessage = action.payload;
    },
    storeBreakingNews(state, action) {
      state.breakingNews = action.payload;
    },
    storeCurrentNews(state, action) {
      state.currentNews = action.payload;
    },
    storeTopNews(state, action) {
      state.topNews = action.payload;
    },
    storeSearchNews(state, action) {
      state.searchNews = action.payload;
    },
    storeUserLoginData(state, action) {
      state.loginData = action.payload;
    },
    storeUserRegisterData(state, action) {
      state.registerData = action.payload;
    },
    storeForgetPassEmail(state, action) {
      state.forgetPassEmail = action.payload;
    },
    storeResetPasswordData(state, action) {
      state.resetPasswordData = action.payload;
    },
    storeSearchInputData(state, action) {
      state.searchInputData = action.payload;
    },
    storeSelectType(state, action) {
      state.selectType = action.payload;
    },
    storeNewsSearchNavigation(state, action) {
      state.newsSearchNavigation = action.payload;
    },
    storeCoverPhoto(state, action) {
      state.coverPhoto = action.payload;
    },
    storeProfilePhoto(state, action) {
      state.profilePhoto = action.payload;
    },
    storeUserPublicInfo(state, action) {
      state.userPublicInfo = action.payload;
    },
    storeUserPrivateInfo(state, action) {
      state.userPrivateInfo = action.payload;
    },
    storeUpdateEmail(state, action) {
      state.updateEmail = action.payload;
    },
    storeUpdatePassword(state, action) {
      state.updatePassword = action.payload;
    },
    storeUserNewsFormData(state, action) {
      state.userNewsFormData = action.payload;
    }, storeSelected(state, action) {
      state.selected = action.payload;
    },
    storeChecked(state, action) {
      state.checked = action.payload;
    },
    storeImages(state, action) {
      state.images = action.payload;
    },
    storeVideo(state, action) {
      state.video = action.payload;
    },
    storeProgress(state, action) {
      state.progress = action.payload;
    },
    storeImageProgress(state, action) {
      state.imageProgress = action.payload;
    },
    storeVideoProgress(state, action) {
      state.videoProgress = action.payload;
    },
    storeButtonData(state, action) {
      state.buttonData = action.payload;
    },
  },
});

export const {
  storeErrors,
  storeBreakingNewsMessage,
  storeCurrentNewsMessage,
  storeTopNewsMessage,
  storeSearchNewsMessage,
  storeBreakingNews,
  storeCurrentNews,
  storeTopNews,
  storeSearchNews,
  storeSearchFiltersMessage,
  storeUserLoginData,
  storeUserRegisterData,
  storeForgetPassEmail,
  storeResetPasswordData,
  storeNavigator,
  storeSearchInputData,
  storeNewsSearchNavigation,
  storeCoverPhoto,
  storeProfilePhoto,
  storeUserPublicInfo,
  storeUserPrivateInfo,
  storeUpdateEmail,
  storeUpdatePassword,
  storeUserNewsFormData,
  storeSelected,
  storeChecked,
  storeImages,
  storeVideo,
  storeProgress,
  storeImageProgress,
  storeVideoProgress,
  storeButtonData,
} = dataSlice.actions;
export default dataSlice.reducer;
