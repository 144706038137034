import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import resources from "../../../sources/data/resources";
import { useSelector } from "react-redux";

const TreandingLatestMenu = memo(() => {
  const { treandingLatestMenus } = resources();
  const { navigator } = useSelector((store) => store.data);
  return (
    <>
      {treandingLatestMenus.map((treandingLatestMenu, index) => (
        <NavLink
          key={index}
          to={
            navigator === "false"
              ? treandingLatestMenu.currentUrl
              : treandingLatestMenu.topUrl
          }
          className={({ isActive }) =>
            isActive
              ? `${
                  index === 0 ? "border-r-2 pr-2" : "border-l-2 pl-2"
                } border-black  font-bold bg-gray-700/80 text-white px-2 py-1 rounded-md`
              : `${
                  index === 0 ? "border-r-2 pr-2" : "border-l-2 pl-2"
                } border-black  text-black  `
          }
        >
          {treandingLatestMenu.name}
        </NavLink>
      ))}
    </>
  );
});

export default TreandingLatestMenu;
