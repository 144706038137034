import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeLogInfo, storeSidebarMenu } from "../store/slices/stateSlice";

export default function useMenuToggle() {
    const { sidebarMenu, logInfo } = useSelector((store) => store.state);
    const dispatch = useDispatch();
    const logMenuRef = useRef(null);
    const sideMenuRef = useRef(null);
    const handleClickOutside = useCallback((e) => {
        if (sidebarMenu && sideMenuRef.current && !sideMenuRef.current.contains(e.target)) {
            dispatch(storeSidebarMenu(false))
        }
        if (logInfo && logMenuRef.current && !logMenuRef.current.contains(e.target)) {
            dispatch(storeLogInfo(false))
        }
    }, [dispatch, logInfo, sidebarMenu])
    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [handleClickOutside]);
    return { sideMenuRef, logMenuRef }
}
