import { useCallback, useContext } from 'react';
import axios, { CancelToken, isCancel } from '../../axios/Axios';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from '../../functions/SweetAlert';
import { storeIsImages, storeIsImagesErrors, storeIsVideo, storeIsVideoErrors, storePostLoading } from '../../store/slices/stateSlice';
import { storeErrors, storeImageProgress, storeImages, storeProgress, storeUserNewsFormData, storeVideo, storeVideoProgress } from '../../store/slices/dataSlice';
import toast from 'react-hot-toast';
import useDeleteRequests from './useDeleteRequests';
import usePatchRequests from './usePatchRequests';
import { storeNewsId, storeUserNewsEditModal } from '../../store/slices/modalSlice';
import resources from '../../data/resources';
import { SocketContext } from '../../context/SocketContext';
export default function useUserPostRequest(cancelUpload) {
    const socket = useContext(SocketContext)
    const { images, video } = useSelector((store) => store.data);
    const { localImages, localVideo } = resources()
    const dispatch = useDispatch();
    const { handleDeleteRequest } = useDeleteRequests()

    const { handlePatchRequest } = usePatchRequests()
    const handleResponseReset = useCallback(() => {
        dispatch(storeProgress(0))
        localStorage.removeItem("images");
        localStorage.removeItem("video");
        dispatch(storeImages([]))
        dispatch(storeVideo([]))
        dispatch(storePostLoading(false))
        dispatch(storeIsVideoErrors(false));
        dispatch(storeIsVideo(false));
        dispatch(storeErrors(''));
        dispatch(storeIsImages(false));
        dispatch(storeIsImagesErrors(false));
        dispatch(storeNewsId(""));
        dispatch(storeUserNewsFormData({
            title: "",
            type: "",
            category: "",
            language: "",
            description: "",
            content: "",
            agree: false,
        }));
    }, [dispatch])
    const handleResetForm = useCallback((type = "") => {
        if (type === 'reset') {
            const deleteData = {
                images: localImages?.images ?? [],
                video: localVideo?.video ?? []
            }
            const deleteUrl = '/delete/images/video/reset';
            const deleteType = 'imagesVideoDelete';
            if (deleteData?.images?.length > 0 || deleteData?.video?.length > 0) {
                handleDeleteRequest(deleteUrl, deleteData, deleteType)
            }
            handleResponseReset()
        } else {
            handleResponseReset()
        }
    }, [handleDeleteRequest, handleResponseReset, localImages?.images, localVideo?.video])
    const handleNewsPublishResponse = useCallback((res, e, type) => {
        SweetAlert('Success', res.data.message, 'success', 2000)
        socket?.emit(type, type)
        handleResetForm('publish')
        const message = "You has been published a new news."
        handlePatchRequest(e, '/user/store/activity', { news_id: res.data.news_id, message: message }, 'userActivity', '')
    }, [handlePatchRequest, handleResetForm, socket])
    const handleUpdateNewsPublishResponse = useCallback((res, e, type) => {
        SweetAlert('Success', res.data.message, 'success', 2000)
        socket?.emit(type, type)
        const message = "You has been updated your published news."
        handlePatchRequest(e, '/user/store/activity/', { news_id: res.data.news_id, message: message }, 'userActivity', '')
        dispatch(storeUserNewsEditModal(false));
        handleResetForm('publish')
    }, [dispatch, handlePatchRequest, handleResetForm, socket])
    const handleNewsPublishErrorResponse = useCallback((res) => {
        dispatch(storeProgress(0))
        dispatch(storeErrors(res.data.errors));
        dispatch(storePostLoading(false));
    }, [dispatch])
    const handleUploadImagesResponse = useCallback((res) => {
        dispatch(storeImageProgress(0))
        localStorage.setItem("images", JSON.stringify({ images: [...images.map((image) => image.name), ...res.data.images] }));
        dispatch(storeImages([...images, ...res.data.images.map((images) =>
            Object.assign({
                name: images,
                image_url: process.env.REACT_APP_STORAGE_PATH + "/news/images/" + images,
            })
        ),]))
    }, [dispatch, images])
    const handleUploadVideoResponse = useCallback((res) => {
        dispatch(storeVideoProgress(0))
        localStorage.setItem("video", JSON.stringify({ video: res.data.video }));
        dispatch(storeVideo([...video, ...res.data.video.map((video) =>
            Object.assign({
                name: video,
                video_url: process.env.REACT_APP_STORAGE_PATH + "/news/videos/" + video,
            })
        ),]))
    }, [dispatch, video])
    const handleUserPostResponse = useCallback((e, res, type) => {
        switch (res.data.status + type) {
            case 200 + 'userNewsPost':
                handleNewsPublishResponse(res, e, type)
                break;
            case 200 + 'userNewsPostUpdate':
                handleUpdateNewsPublishResponse(res, e, 'userNewsPost')
                break;
            case 200 + 'uploadImages':
                handleUploadImagesResponse(res)
                break;
            case 200 + 'uploadVideo':
                handleUploadVideoResponse(res)
                break;
            case 422 + 'userNewsPost':
            case 422 + 'userNewsPostUpdate':
                handleNewsPublishErrorResponse(res)
                break;
            default:
                console.log(res);
                break;
        }
    }, [handleNewsPublishErrorResponse, handleNewsPublishResponse, handleUpdateNewsPublishResponse, handleUploadImagesResponse, handleUploadVideoResponse])
    const handleProgressPercent = useCallback((type = '', percent = 0) => {
        if (type === 'userNewsPost' || type === 'userNewsPostUpdate') {
            dispatch(storePostLoading(false));
            dispatch(storeProgress(percent))
        }
        if (type === 'uploadImages') {
            dispatch(storeImageProgress(percent))
            if (percent > 0 && percent <= 100) {
                dispatch(storeIsImages(true));
                dispatch(storeErrors(''));
            }
            if (percent === 0) {
                dispatch(storeIsImagesErrors(false))
                dispatch(storeIsImages(false))
            }
        }
        if (type === 'uploadVideo') {
            dispatch(storeVideoProgress(percent))
            if (percent > 0) {
                dispatch(storeIsVideo(true));
                dispatch(storeErrors(''));
            }
            if (percent === 0) {
                dispatch(storeIsVideoErrors(false));
                dispatch(storeIsVideo(false))
            }
        }
    }, [dispatch])
    const handleCancelPostResponse = useCallback((error, type) => {
        if (isCancel(error)) {
            toast.success(error.message)
            handleProgressPercent(type, 0)
        }
    }, [handleProgressPercent])
    const handleUserPostRequest = (e, url = '', data = {}, type = '') => {
        const options = {
            onUploadProgress: (e) => {
                let percent = Math.round((e.loaded / e.total) * 100);
                if (percent <= 100) {
                    handleProgressPercent(type, percent)
                }
            },
            cancelToken: new CancelToken((cancel) => (cancelUpload.current = cancel)),
        };
        try {
            axios
                .post(url, data, options)
                .then((res) => {
                    handleUserPostResponse(e, res, type)
                })
                .catch((error) => {
                    handleCancelPostResponse(error, type)
                });
        } catch (error) {
            dispatch(storePostLoading(false))
            console.log(error);
        }
    };

    return {
        handleUserPostRequest,
        handleResetForm,
        handleResponseReset,
    };
}
