import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../context/SocketContext";

export default function useSocket(type) {
  const socket = useContext(SocketContext);
  const [update, setUpdate] = useState(null);
  useEffect(() => {
    socket?.on(type, (data) => {
      setUpdate(data)
    })
  }, [socket, type])
  return { socket, update, setUpdate }
}
