import React, { memo } from "react";

const Title = memo(({ title }) => {
  return (
    <>
      <div className="flex justify-center p-1 bg-gray-200">
        <span className="">{title}</span>
      </div>
    </>
  );
});

export default Title;
