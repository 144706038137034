import React, { memo } from "react";
import { useDropzone } from "react-dropzone";
import { MdVideoLibrary } from "react-icons/md";
import { useDispatch } from "react-redux";
import { storeIsVideo, storeIsVideoErrors } from "../../../sources/store/slices/stateSlice";
import useUserPostRequest from "../../../sources/hooks/requests/useUserPostRequest";
const VideoDropzone = memo(({ setVideoRejected, cancelUpload }) => {
  const { handleUserPostRequest } = useUserPostRequest(cancelUpload)
  const dispatch = useDispatch();
  const {
    getRootProps: getRootVideoProps,
    getInputProps: getInputVideoProps,
    isDragActive: isDragVideoActive,
  } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles, e) => {
      if (acceptedFiles?.length > 0) {
        handleUserPostRequest(e, '/upload/video', { video: acceptedFiles }, 'uploadVideo')
      }
      if (rejectedFiles?.length > 0) {
        setVideoRejected((prev) => [...prev, ...rejectedFiles]);
        dispatch(storeIsVideo(true))
        dispatch(storeIsVideoErrors(true));
      }
    },
    multiple: false,
    accept: {
      "video/mp4": [".mp4"],
    },
    maxSize: 2048 * 10000,
  });
  return (
    <>
      <div {...getRootVideoProps()}>
        <input {...getInputVideoProps()} />
        {isDragVideoActive ? (
          <div className="h-40  rounded-md  border border-cyan-300">
            <div className=" h-full grid items-center justify-center text-gray-300">
              <div className="flex flex-col justify-center items-center mx-2 gap-1">
                <div className="space-x-1">
                  <span>Paste video here</span>

                  <span className="text-red-600">*</span>
                </div>

                <div className="flex items-center">
                  <MdVideoLibrary />
                </div>
                <span className="text-red-600/20">
                  Accepts only MP4 video. Max 20MB
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-40  rounded-md  border border-cyan-300">
            <div className=" h-full grid items-center justify-center text-gray-400">
              <div className="flex flex-col justify-center items-center mx-2 gap-1">
                <div className="space-x-1">
                  <span>Drag and drop news video Or click here</span>
                </div>

                <div className="flex items-center">
                  <MdVideoLibrary />
                </div>
                <span className="text-red-400">
                  Accepts only MP4 video. Max 20MB
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
);

export default VideoDropzone;
