import React, { memo } from "react";
import Ads from "../../common/ads/Ads";
import SearchNewsPost from "./SearchNewsPost";

const SearchNews = memo(() => {
  return (
    <>
      <div className="grid grid-cols-12 ">
        <div className="lg:col-span-3">
          {[1, 2, 3, 4, 5].map((data, i) => (
            <Ads key={i} />
          ))}
        </div>
        <div className="col-span-12 lg:col-span-6 bg-gray-100 shadow-lg rounded-md mt-1 mx-1">
          <SearchNewsPost />
        </div>
        <div className="lg:col-span-3">
          {[1, 2, 3, 4, 5].map((data, i) => (
            <Ads key={i} />
          ))}
        </div>
      </div>
    </>
  );
});

export default SearchNews;
