import React from "react";
import CoverSection from "./profile header/cover photo/CoverSection";
import ProfileSection from "./profile header/profile photo/ProfileSection";
export default function ProfileHeader() {
  return (
    <>
      <div className="mx-2 mt-3 mb-36">
        <CoverSection />
        <ProfileSection />
      </div>
    </>
  );
}
