import React, { memo } from "react";

const UserFollowMessage = memo(() => {
  return (
    <>
      <div className="space-x-2 py-1">
        <button
          type="button"
          className="bg-blue-600/80 hover:bg-blue-600 text-white py-1 px-2 rounded-md">
          Follow
        </button>
        <button
          type="button"
          className="bg-blue-600/80 hover:bg-blue-600 text-white  py-1 px-2 rounded-md">
          Message
        </button>
      </div>
    </>
  );
});

export default UserFollowMessage;
