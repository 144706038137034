import React, { memo } from "react";
import { FaXmark } from "react-icons/fa6";
const ProgressBar = memo(({ progress, cancelPost, cancelUpload }) => {
  return (
    <>
      <div className="mx-6 mt-3 flex gap-2 items-center ">
        <div className="w-full bg-gray-400 h-4 rounded-full ">
          <div
            className="bg-blue-700 text-white h-4 p-2 text-xs  flex items-center justify-end rounded-full"
            style={{ width: progress + "%" }}
          >
            {progress + "%"}
          </div>
        </div>
        <div
          onClick={() => cancelPost(cancelUpload)}
          className="rounded-md bg-red-600 hover:bg-red-600/80 text-white hover:text-gray-300 py-[1px] px-1.5 cursor-pointer"
        >
          <FaXmark title="Cancel" />
        </div>
      </div>
    </>
  );
});

export default ProgressBar;
