import React, { memo } from "react";
import { useSelector } from "react-redux";
import GuestLogMenu from "../loginfo/guest/GuestLogMenu";
import resources from "../../../sources/data/resources";
import SidebarMenus from "./SidebarMenus";
const Sidebar = memo(() => {
  const { token, logMenus } = resources();
  const { sidebarMenu } = useSelector((store) => store.state);
  return (
    <>
      {sidebarMenu && (
        <div className="absolute top-12 left-0 z-50 w-[200px] h-sidebarHeight shadow bg-gray-100  ">
          <hr className="border-gray-400 mt-2" />
          <hr className="border-gray-400 m-3" />
          {token ? (
            <SidebarMenus />
          ) : (
            <div className=" bg-white py-20 px-2 rounded-md">
              {logMenus.length > 0 &&
                logMenus.map((logMenu, index) => (
                  <div key={index} className="text-center">
                    <GuestLogMenu logMenu={logMenu} className="underline" />
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default Sidebar;
