import { useCallback, useEffect, useRef, useState } from "react";

export default function useButtonToggle() {
    const buttonRef = useRef([])
    const [threeDotClick, setThreeDotClick] = useState({});
    const handleButtonToggle = useCallback((i) => {
        if (i === threeDotClick.index) {
            setThreeDotClick({
                value: !threeDotClick.value,
                index: i,
            })
        } else {
            setThreeDotClick({
                value: true,
                index: i,
            })
        }
    }, [threeDotClick])
    const handleThreeDotReset = useCallback((e) => {
        if (buttonRef.current[threeDotClick.index]) {
            if (!buttonRef?.current[threeDotClick.index]?.contains(e.target)) {
                setThreeDotClick({});
            }
        }
    }, [threeDotClick])
    useEffect(() => {
        document.addEventListener('click', handleThreeDotReset)
        return () => document.removeEventListener('click', handleThreeDotReset)
    }, [handleThreeDotReset])
    return { handleButtonToggle, threeDotClick, buttonRef }
}
