import React, { memo } from 'react'
import TopHeaderBar from '../../components/home/navbar/TopHeaderBar'
import BreakingNewsBar from '../../components/home/navbar/BreakingNewsBar'
import SupportTitleLogbar from '../../components/home/navbar/SupportTitleLogbar'
import MenuBar from '../../components/home/navbar/MenuBar'
import { Outlet } from 'react-router-dom'
import SearchBar from '../../components/home/navbar/SearchBar'
const ApiNewsLayout = memo(() => {
  return (
    <>
      <TopHeaderBar />
      <div className="sticky top-0 left-0 right-0 z-40">
        <BreakingNewsBar />
      </div>
      <SupportTitleLogbar />
      <div className="sticky top-[38px] right-0 left-0 z-40">
        <MenuBar />
        <SearchBar />
      </div>
      <Outlet />
    </>
  )
})

export default ApiNewsLayout